import axios from "axios";
import log from "common/Log";

import * as server from "config/server.config";

const API_URL = server.SERVER_POINT + "/api/bound/";

const get_class = (data:any) =>
{
  return axios.post(API_URL + "get_class", data).then((response) => {
    return response.data;
  });
};

export default {
  get_class
};
