import React from 'react';

import { Routes, Route } from "react-router-dom";

import { ProposalBid } from 'components/ProposalBid';
import { ProposalAsk } from 'components/ProposalAsk';
import { ProposalOverview } from 'components/ProposalOverview';
import { AskApproveRequest } from 'components/AskApproveRequest';

export const ProposalControl: React.FC<{}> = ({ children })=> {

   return (
    <div>
      <Routes>
        <Route path="/" element={<ProposalOverview/>}/>

        <Route path="bid/create" element={<ProposalBid isReadOnly={false}/>}/>

        <Route path="bid/show/:transactionId" element={<ProposalBid isReadOnly={true}/>}/>

        <Route path="askApproveRequest/:askId" element={<AskApproveRequest isReadOnly={false}/>}/>

        <Route path="ask/create/:borrowItemId" element={<ProposalAsk isReadOnly={false}/>}/>

        <Route path="ask/show/:askId" element={<AskApproveRequest isReadOnly={true}/>}/>
      </Routes>
    </div>
  );
}
