import React from 'react';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import BootstrapTable from 'react-bootstrap-table-next';  
import { Card, Modal, Button, Container, Form } from 'react-bootstrap'

import { useNavigate, useParams } from 'react-router-dom';
import InputMask from 'react-input-mask';

import log from "common/Log";
import texts from "common/Texts";
import validator from "common/Validator";

import { useControl, useCurrentUserState } from "models/CurrentUser";
import approveRequestTile from "components/Helpers/ApproveRequestTile";
import userTile from "components/Helpers/UserTile";
import itemTile from "components/Helpers/ItemTile";
import reports from "components/Helpers/Reports"
import meetTile from "components/Helpers/MeetTile";

import Middleware from "middlewares/Delivery";
import FlashMessage from "common/FlashMessage";
import customStyle from 'common/Style';

export const Delivery: React.FC<{mode:string}> = ({ mode, children })=> {
    
    const { deliveryId } = useParams();
    const currentUserState = useCurrentUserState()
    const { update } = useControl()

    const pageName = 'Doručení'

    const deleteMode = mode == 'delete';
    const isReadOnly = mode == 'delete';
    const [feedback, setFeedback] = React.useState<string|undefined>(undefined);
    const [delivery, setDelivery] = React.useState<any|undefined>();

    const [deleteReason, setDeleteReason] = React.useState("");

    const [codeParts, setCodeParts] = React.useState("");
    const [codeSucessfullyInserted, setCodeSucessfullyInserted] = React.useState(false);

    const [overridenMeets, setOverridenMeets] = React.useState<any[]|undefined>(undefined);

    const [modalShowMeet, setModalShowMeet] = React.useState<any|undefined>();
    const [modalAddMeetShow, setModalAddMeetShow] = React.useState<Boolean>(false);
    const [warningActivated, setWarningActivated] = React.useState<string>("");

    const maskCharValue = 'a';
    const navigate = useNavigate()

    function errorService(functionId:string, errorArg:any) :void {
      setFeedback(log.decodeError(pageName, functionId, errorArg)); 
    }

    function errorInField(fieldId:string, message:string, value:any) :void {
      log.errorOnUserInput("Doručení", fieldId, message, value);
      setFeedback(validator.inputErrorRedable(fieldId, message, value)); 
    }

    function okService() : void {
        setFeedback(""); 
    }

    const getIsSender = (deliveryLoc:any) => {
      return deliveryLoc.sender.user._id == currentUserState.user._id;
    }

    const getReadableMeet = (meet:any) => {
      return { timestampReadable: texts.readableDateTime(meet.time), locationName:meet.location.name, meet:meet, timestampKey:meet.timestampKey }
    }

    const getReadableMeets = (meets:any[]) => {
      return meets.map((meet:any) => { return getReadableMeet(meet) } )
    }
    const getCounterParty = () => {
      return getIsSender(delivery) ? delivery.receiver.user : delivery.sender.user
    }

    const getIsMyTurn = (deliveryLoc:any) => {
      return (deliveryLoc.turnSender != undefined) && (getIsSender(deliveryLoc) == deliveryLoc.turnSender)  
    }

    React.useEffect(() => {
      log.log("Go delivery", deliveryId)
      Middleware.get({'id': deliveryId, 'userId': currentUserState.user._id}).then(
        (response:any) => {
          setDelivery(response.delivery)

          if (response.delivery.sender.enteredCode.matching != undefined &&
              response.delivery.sender.enteredCode.matching)
          {
            setCodeSucessfullyInserted(true)
          }

          if (response.delivery.proposeMeetExchange.length == 0 && getIsMyTurn(response.delivery) )
            setOverridenMeets([])
        },
        (error:any) => { errorService('delivery/get', error) }
      );
    }, []);

    const readableMeet = (meet:any):React.ReactNode => {
      return (<span className='txt'>Schůzka na místě: <b>{meet.locationName}</b>, v {meet.timestampReadable}</span>)
    }

    const codePartEntered = (event:any):any => {
      setCodeParts(event.target.value)
    }

    const insertCode = () => {
      if (codeParts.indexOf(maskCharValue) != -1)
      {
        errorInField('Kódu příjemce', "Doplňte znaky '?' v kódu příjemce", codeParts)
        return;
      }

      Middleware.enter_sender_key({'deliveryId': delivery._id, 'enteredCode': codeParts, 'userId':currentUserState.user._id}).then(
        (response:any) => {
          setCodeSucessfullyInserted(true)
          navigate(-1);
        },
        (error:any) => { errorService('delivery/get', error) } )
    }

    const getSenderCodeEntry = ():React.ReactNode => {
      var codePartsMask = Array(delivery.sender.enteredCode.numberOfChars).fill(maskCharValue);

      for (var i = 0; i < delivery.sender.enteredCode.numberOfChars / 2; ++i){
        // pairs organized in [number, value] elements array
        log.log(delivery.sender.exchangeCodeParts[i*2], delivery.sender.exchangeCodeParts[i*2 + 1])
        codePartsMask[delivery.sender.exchangeCodeParts[i*2]] = delivery.sender.exchangeCodeParts[i*2 + 1]
      }

      var maskIndices:string = "";
      for(var i=0; i < codePartsMask.length;i++) {
        maskIndices += codePartsMask[i]
      }
      return (<InputMask mask={maskIndices} alwaysShowMask={true} onChange={codePartEntered} />)
    }

    if (delivery == null)
    {
      return ( <reports.LoadingTile feedback={feedback} setFeedback={setFeedback}/> )
    }

    const senderName = 'Předávající'
    const receiverName = 'Příjemce'
    const isSender = getIsSender(delivery);
    const partnerName = isSender ? receiverName : senderName

    const confirmMeets = (meets: any[], ignoredEmpty:any) => {

      if (!ignoredEmpty && meets.length == 0)
      {
         setWarningActivated("Není vložena žádná schůzka, termín a místo určí " + partnerName + " ?")
         return;
      }

      Middleware.submit_meets({isSender:isSender, deliveryId:delivery._id, meets:meets, userId:currentUserState.user._id}).then(
        () => {
          navigate(-1);
        },
        (error:any) => { errorService('meet/submit_meets', error) }
      );
    };

    const getColumns = (formater:any) => {
      return [
        { dataField: 'timestampReadable', text: 'Datum/čas', sort: true,
          headerStyle: { minWidth: '2rem', width: '2rem' }},
        { dataField: 'locationName', text: 'Místo', sort: true,
          headerStyle: { minWidth: '3rem', width: '10rem' }},
        {
          dataField: 'actions',
          text: 'Akce',
          csvExport: false,
          formatter: formater,
          headerStyle: { minWidth: '2rem', width: '2rem' }
        },
      ]
    }

    const onDeleteFn = (data:any): void =>
    {
        Middleware.del({deliveryId:deliveryId, userId:currentUserState.user._id, message:deleteReason}).then(
        (response)=>{okService();
          navigate(-1);} ,
          (errorArg:any) => {errorService('delivery/delete', errorArg)});
    }

    const isMyTurn = getIsMyTurn(delivery)

    const addMeet = (meet:any) => {
      if (overridenMeets != undefined)
      {
        log.log("j", overridenMeets)

        setOverridenMeets([...overridenMeets, meet])
      }
    };

    const acceptMeet = (meet: any) => {
      Middleware.accept_meet({isSender:isSender, deliveryId:delivery._id, meet:meet, userId:currentUserState.user._id}).then(
        () => {
          navigate(-1);
          okService()
        },
        (error:any) => { errorService('meet/accept_meet', error) }
      );
    };

    const removeMeet = (time:any) => {
      if (overridenMeets != undefined)
      {
        const cc:any = overridenMeets.filter((val, index) => {
          return val.timestampKey != time
        })

        setOverridenMeets(cc);
      }
    };

    interface MeetControl {
      acceptMeetC: (data:any) => void;
      addMeetC: (data:any) => void;
      removeMeetC: (data:any) => void;
    }
    const MeetContext = React.createContext<MeetControl | undefined>(undefined);

    const SpanOverrided = (props:any) => {
      const controlContext = React.useContext(MeetContext);
      if (controlContext === undefined) {
        throw new Error("MeetContext must be used within a Provider");
      }
      const { removeMeetC } = controlContext

      return ( <>
        <Button size="lg" disabled={isReadOnly} variant="warning" style={customStyle.buttonStyle} onClick={(e:any) => {
            e.stopPropagation();
            removeMeetC(props.row.meet.timestampKey)}
            }>Smaž</Button>
        </> );
    };

    const overridedMeetFormatter = (cell:any, row:any) =>
    { 
      const key1:any = row.meet.timestampKey + "-"
      return (<SpanOverrided row={row} key={key1} style={{width:'1rem'}}/>)
    }

    const SpanCurrent = (props:any) => {
      return ( <>
        {isMyTurn && <Button size="lg" disabled={isReadOnly} variant="success" style={customStyle.buttonStyle} onClick={
          (e:any) => {
            e.stopPropagation();
            acceptMeet(props.row.meet)}}>Akceptuj</Button> }
        </> )
    };

    const currentMeetFormatter = (cell:any, row:any) =>
    {
      const key1:any = row.meet.timestampKey + "-"
      return (<SpanCurrent row={row} key={key1}  style={{width:'1rem'}}/>)
    }

    const tableRowEvents = {
      onClick: (e:any, row:any, rowIndex:any) => {
        setModalShowMeet(row.meet)
      },
    }

    const meetFrame = ():React.ReactNode =>
    {
      if (delivery.selectedMeetExchange != undefined)
        return (
          <Card className="rootTile">
            <Card.Body>
              <h2>Schválená schůzka: {delivery.selectedMeetExchange.location.name} {texts.readableDateTime(delivery.selectedMeetExchange.time)}  </h2>
              <Button size="lg" disabled={isReadOnly} variant="secondary" style={customStyle.buttonSecondStyle} onClick={() => setModalShowMeet(delivery.selectedMeetExchange)}>Zobraz detail</Button>
            </Card.Body>
          </Card>  )

      if (overridenMeets == null)
        return (
          <>
            <Card className="rootTile" style={{marginRight:'0.3rem', padding:'0'}}>
              <Card.Header>
                {isMyTurn && <h2>Pokud vám některá ze schůzek vyhovuje vyberte ji</h2>}
                {!isMyTurn && <h2>Čeká se na akci protistrany: {partnerName}</h2>}
              </Card.Header>
              <Card.Body>
                {!isMyTurn && <Form.Label>Vaše současné návrhy</Form.Label>}
                <BootstrapTable
                      bootstrap4
                      striped={false}
                      keyField="timestampKey"
                      columns={getColumns(currentMeetFormatter)}
                      data= {getReadableMeets(delivery.proposeMeetExchange)}
                      rowEvents={ tableRowEvents }
                    />
              </Card.Body>
              {isMyTurn && 
              <Card.Footer>
                <Button size="lg" disabled={isReadOnly} variant="warning" onClick={(e:any)=> { setOverridenMeets([]) }}>Návrhy schůzky nevyhovují, zadám vlastní</Button>
              </Card.Footer> }
            </Card> 
          </> )

      return (
        <>
          <Card className="rootTile" style={{marginRight:'0.3rem', padding:'0'}}>
            <Card.Header>
              <h2>Mé návrhy schůzky</h2>
              <Button size="lg" disabled={isReadOnly} variant="success" style={customStyle.rigthButtonStyle} onClick={(e:any)=> { setModalAddMeetShow(true)}}>Nový návrh</Button>
            </Card.Header>
            <Card.Body>
              <BootstrapTable
                    bootstrap4
                    striped={false}
                    keyField="timestampKey"
                    columns={getColumns(overridedMeetFormatter)}
                    data= {getReadableMeets(overridenMeets)}
                    rowEvents={ tableRowEvents }
                  />
            </Card.Body>
            <Card.Footer>
            <Button size="lg" disabled={isReadOnly} variant="success" onClick={(e:any)=> {
                confirmMeets(overridenMeets, false)
                }}>Odešli návrhy schůzek</Button>
            </Card.Footer>
          </Card>
        </>
        )
    }

    const codeFrame = ():React.ReactNode =>
    {
      if (codeSucessfullyInserted)
      {
        return (
          <Card className="rootTile" style={{marginRight:'0.3rem', padding:'0'}}>
            <Card.Header>
              <FlashMessage theme={'success'}
                text={'Výměna kódu proběhla úspěšně.'}/>
            </Card.Header>
          </Card>
        )
      }

      if (getIsSender(delivery))
      {
        return (
            <Card className="rootTile" style={{marginRight:'0.3rem', padding:'0'}}>
              <Card.Header><h2>Vložte kód příjemce</h2></Card.Header>
              <Card.Body>
                {getSenderCodeEntry()}
                <Button size="lg" disabled={isReadOnly} variant="success" style={customStyle.rigthButtonStyle} onClick={() => insertCode()}>Vlož kód</Button>
              </Card.Body>
            </Card>
          )
      }
      return (
          <Card className="rootTile" style={{marginRight:'0.3rem', padding:'0'}}>
            <Card.Header><h2>Kód pro výměnu za věc</h2></Card.Header>
            <Card.Body>
              <Form.Control type="input" disabled={true}  value={delivery.receiver.exchangeCode} />
              <Form.Text className="text-muted">
                Kód předejte výměnou za věc při okamžiku přebírání věci.
              </Form.Text>
            </Card.Body>
          </Card>
        )
    }

    return ( <>
      <Container>
        <Container>
          <Row>
            <Col>
              <h1>{pageName}</h1>
            </Col>
          </Row>
        </Container>
        <hr/>

        <reports.ErrorModal feedback={feedback} setFeedback={setFeedback} />
        {overridenMeets != undefined && <reports.ReallyConfirm whatLabel={warningActivated} setWhatLabel={setWarningActivated} continueWorkflow={()=> { confirmMeets(overridenMeets, true)}}/> }

        {modalShowMeet != undefined &&
          <Modal dialogClassName="meet-show" show={true} size="lg" >
            <Modal.Body>
              <meetTile.Tile timeIn={modalShowMeet.time} addClick={undefined} closeClick={(e:any)=> { setModalShowMeet(undefined) }}
                okService={undefined} eligibleLocations={delivery.eligibleLocations} selectedLocationIdIn={modalShowMeet.location._id}/>
            </Modal.Body>
          </Modal> }

        {modalAddMeetShow &&
          <Modal dialogClassName="meet-show" show={true} size="lg">
            <Modal.Body>
              <meetTile.Tile timeIn={undefined} addClick={addMeet} closeClick={(e:any)=> { setModalAddMeetShow(false) }}
                okService={undefined} eligibleLocations={delivery.eligibleLocations} selectedLocationIdIn={undefined}/>
            </Modal.Body>
          </Modal> }

        <Container>
          <Row style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
            <Card className="rootTile" style={{marginRight:'0.3rem', padding:'0', width: '18rem'}}>
              <Card.Header><h2>Věc</h2></Card.Header>
              <Card.Body>
                {itemTile.Tile(delivery.item, undefined, undefined, undefined, undefined)}
              </Card.Body>
            </Card>
            <Card className="rootTile" style={{marginRight:'0.3rem', padding:'0', width: '18rem'}}>
            <Card.Header><h2>{partnerName}</h2></Card.Header>
              <Card.Body>
                {userTile.Tile(getCounterParty(), undefined, undefined, undefined)}
              </Card.Body>
            </Card>
          </Row>

          <Row>
            <MeetContext.Provider value={ {addMeetC:addMeet, acceptMeetC:acceptMeet, removeMeetC:removeMeet} }>
            {meetFrame()}

            {delivery.selectedMeetExchange != undefined &&
              codeFrame()}
            </MeetContext.Provider>
          </Row>

          { delivery.stamp != undefined &&
            <Row>
              <Card className="rootTile" style={{marginRight:'0.3rem', padding:'0'}}>
                <Card.Header><h2>Výsledek doručení</h2></Card.Header>
                <Card.Body>
                  <approveRequestTile.ShowState approveRequest={delivery.stamp} role="" user={delivery.receiver.user} />
                </Card.Body>
              </Card>
            </Row>
          }

        { deleteMode && 
          <Row>
            <Card className="rootTile" style={{marginRight:'0.3rem', padding:'0'}}>
              <Card.Body>
                <Form.Group controlId="message">
                  <Form.Label>Důvod smazání</Form.Label>
                  <Form.Control as="textarea" rows={3} onChange={(e:any) => setDeleteReason(e.target.value)} />
                </Form.Group>
              </Card.Body>
            </Card>
          </Row>  }

        <hr/>
        <Button size="lg" variant="secondary" style={customStyle.rigthSecondButtonStyle} onClick={(e) => {navigate(-1);} } >Zpět</Button>

        { deleteMode && <Button size="lg" variant="warning" style={customStyle.rigthButtonStyle} onClick={onDeleteFn}>Smazat</Button> }

        </Container>
      </Container>
      </>
    );
  }
