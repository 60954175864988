import React from 'react';

import { Card, Image } from 'react-bootstrap'
import 'components/Letter.css';
import log from "common/Log";
import QRCode from "react-qr-code";
import Middleware from "middlewares/Location";
import reports from "components/Helpers/Reports"
import s3 from "common/s3";

const BookmarkTile: React.FC<{locationId:string|undefined}> = ({ locationId, children })=> {

  function errorService(functionId:string, errorArg:any) :void {
    setFeedback(log.decodeError("Bookmark", functionId, errorArg)); 
  }

  function okService() : void {
      setFeedback(""); 
  }

  const [feedback, setFeedback] = React.useState<string|undefined>(undefined);
  const [location, setLocation] = React.useState<any|undefined>();

  React.useEffect(() => {
    Middleware.get({'id' :locationId}).then(
      (response) => {
        setLocation(response.location)
      },
      (error:any) => { errorService('location/get', error) }
    );
  }, []);

  if (location == undefined)
  {
    return ( <reports.LoadingTile feedback={feedback} setFeedback={setFeedback}/> )
  }

  const htmlRef:any = 'prohod.cz/location/show/' + location._id
  return (
    <div className='col' style={{width: '70mm', minHeight:'204mm', margin: '0', padding: '2mm'}}> 
      <Card style={{border: "0px solid gray", padding: '0mm', minHeight:'200mm'}}>
        <Card.Body style={{padding: '3mm' }}>  
          <Card.Img style={{ width: '16mm'}} src='https://prohodto-images.s3.eu-central-1.amazonaws.com/help/swap2.png' />
          <h1 style={{fontSize:'150%', textAlign: 'center'}}>   PROHOD.CZ</h1>
          <div style={{fontSize:'130%', textAlign: 'center', marginTop:'-3mm'}}>přináší</div>
          <p style={{fontSize:'95%', marginLeft: '3mm'}}>
            <li>Online půjčování: knih, nářadí, hraček, sportovního vybavení, ..<br/>
            mezi uživateli zdarma
            </li>
            <li>Seznámení s lidmi v okolí</li>
            <li>Tuto záložku zdarma &#128578;</li>
          </p>
          <div style={{textAlign: 'center', marginTop:'-1mm'}}>Blízko se nachází výdejní místo:</div>
          <Card style={{border: "2px solid gray", margin:'2mm', padding: '0rem'}}>
            <Card.Header style={{ fontSize:'90%', background: '#AAAAAA', padding: '1mm', textAlign: 'center'}}>
              {location.name}
            </Card.Header>
            <Card.Body style={{margin:'0mm', padding: '1mm', textAlign: 'center'}}>
              <Image style={{ height: '35mm', width: '35mm', margin:'0mm', padding: '0rem'}} src={s3.getUrlFromImageName(location.profileImage)} />
              <QRCode style={{ height: '35mm', width: '35mm', padding: '1mm'}} size={120} value={htmlRef} />
            </Card.Body>
          </Card>
          <div style={{fontSize:'90%', padding: '0rem', textAlign: 'center', margin:'0mm'}}>
          Rádi přivítáme nové uživatele
          </div>
          <div style={{fontSize:'140%', padding: '0rem', textAlign: 'center', margin:'0mm'}}>
            Registrace na:
          </div>
          <h1 style={{fontSize:'150%', textAlign: 'center'}}>PROHOD.CZ   </h1>
          <Card.Img style={{ width: '16mm'}} src='https://prohodto-images.s3.eu-central-1.amazonaws.com/help/swap2.png' />
        </Card.Body>
      </Card>
    </div>
  );
}

export const Bookmark: React.FC<{locationId:string|undefined}> = ({ locationId, children })=> {

  return (
    <>
      <style type="text/css">
        {`
        body {
          width: 210mm;
          height: 100%;
          margin: 0mm auto;
          padding: 0mm;
          font-size: 12pt;
          background: rgb(204,204,204); 
        }
        .main-page {
          width: 210mm;
          min-height: 297mm;
          margin: 0;       
          background: white;
          box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);
        }
        @page {
          size: A4;
          margin: 0;
        }
        @media print {
          body {
            width: 210mm;
            height: 297mm;        
          }
        } `}
      </style>
      <div className='main-page'>
        <div className='row' style={{width: '205mm', paddingLeft: '12mm', paddingTop: '5mm'}}  >
            <BookmarkTile locationId={locationId}/>
            <BookmarkTile locationId={locationId}/>
            <BookmarkTile locationId={locationId}/>
        </div>
      </div>
    </>
    )
}
