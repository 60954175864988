import React from 'react';

import { Container, Form } from 'react-bootstrap'
import { useMapEvents, MapContainer, TileLayer, Marker, ZoomControl, useMap } from 'react-leaflet'
import { LatLngExpression } from 'leaflet'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function ChangeMapView({ coords }:any) {
  const map = useMap();
  map.setView(coords, map.getZoom());
  return null;
}

export const LocationMap: React.FC<{positionParent:any, onUpdatePosition:any, isReadOnly:boolean}> = ({ positionParent, onUpdatePosition, isReadOnly, children })=> {
  
  const position = {lat:positionParent.latitude, lng:positionParent.longitude}
  const LocationMarker: React.FC<{positionParent:LatLngExpression, onUpdatePosition:any, isReadOnly:boolean}> = ({ positionParent, onUpdatePosition, isReadOnly, children })=> {

    const map = useMapEvents({
      click(event) {
        if (!isReadOnly)
        {
          const { lat, lng } = event.latlng;
          onUpdatePosition({latitude:lat, longitude:lng})
        }
        map.locate()
      },
      locationfound(location) {
      },
    })
  
    return (
      <>
      <Marker position={positionParent}>
      </Marker>
      </>
    )
  }

  return (
    <>
      <Row>
        <Col lg="2">
          <Form.Group controlId="latitude">
            <Form.Label>Latitude</Form.Label>
            <Form.Control type="input" disabled={true} value={positionParent.latitude} style={{ color: 'green' }}/>
          </Form.Group>
          <Form.Group controlId="longitude">
            <Form.Label>Longitude</Form.Label>
            <Form.Control type="input" disabled={true} value={positionParent.longitude} style={{ color: 'green' }}/>
          </Form.Group>
        </Col>
        <Col lg="10">
          <MapContainer center={position} id="mapId" style={{ height: 500 }} zoom={18} maxZoom={22}>
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              maxZoom={22}
              maxNativeZoom={19}
            />
            <LocationMarker positionParent={position} onUpdatePosition={onUpdatePosition} isReadOnly={isReadOnly}/>
            <ChangeMapView coords={position} />
          </MapContainer>  
        </Col>
      </Row>
    </>
  );
}
