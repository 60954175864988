import React from "react";
import log from "common/Log";
import book from "components/Templates/Kind/Book"

interface KindDecoder {
    components:any[];
    getName: (data:any) => string;
    getPrefix: (data:any) => string;
    getDescription: (data:any) => any;
    itemSelected: (value:string) => string;
}

const KindDecoderContext = React.createContext<KindDecoder | undefined>(undefined);

export const KindDecoderProvider: React.FC<{}> = ({children}) => {

  const components:any = [book.getHandle()]
  const componentsMapKey = new Map<string, any>([[book.getHandle().getStaticKey(), book.getHandle()]]);
  const componentsMapName = new Map<string, any>([[book.getHandle().getStaticName(), book.getHandle()]]);
  const componentsMapId = new Map<Number, any>([[book.getHandle().getStaticClassId(), book.getHandle()]]);

  const getName = (item:any) => {
    if (item.kind != undefined && item.kind.classId != undefined)
    {
      const cmp:any = componentsMapId.get(item.kind.classId) 
      if (cmp != undefined)
      {
        const name = cmp.getName(item.kind);
        if (name != undefined)
        {
          return name + ": " + item.name
        }
      }
    }
    return item.name
  }

  const getPrefix = (item:any) => {
    if (item.kind != undefined && item.kind.classId != undefined)
    {
      const cmp:any = componentsMapId.get(item.kind.classId) 
      if (cmp != undefined)
      {
        const name = cmp.getName(item.kind);
        if (name != undefined)
        {
          return name
        }
      }
    }
    return ""
  }

  const itemSelected = (value:any):any => {
    return componentsMapName.get(value)
  }

  const getDescription = (item:any) => {
    if (item.description == undefined)
        return ""

    const descNoNLines = item.description.replace(/[\r\n]+/g," ")
    var desc = descNoNLines.substring(0, 200);
    if (descNoNLines.length != desc.length)
       desc = desc + "..."

    if (item.kind != undefined && item.kind.classId != undefined)
    {
      const cmp:any = componentsMapId.get(item.kind.classId) 
      if (cmp != undefined)
      {
        const head = cmp.getDescriptionHead(item.kind);
        if (head != undefined)
        {
          return { 'head':head, 'plain': desc }
        }
      }
    }
    return { 'plain': desc }
  }

  return (
    <KindDecoderContext.Provider value={ { components, getName, getPrefix, getDescription, itemSelected } }>
      {children}
    </KindDecoderContext.Provider>
  );
};

export const useKindDecoder = () => {
  const ctx = React.useContext(KindDecoderContext);
  if (ctx === undefined) {
    throw new Error("useKindDecoder must be used within a UserProvider");
  }
  return ctx as KindDecoder;
};

