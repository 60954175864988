import React from 'react';

import { Button, Card, Container } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';

import customStyle from 'common/Style';

export const Faq: React.FC<{}> = ({ children })=> {
  const pageName = 'Dotazy - odpovědi'
  const navigate = useNavigate()

  return (
    <Container>
      <Card className="rootTile">
        <Card.Header><h1>{pageName}</h1></Card.Header>
          <Card.Body style={{fontSize:'110%'}}>
          <h4>Úplně na začátku potřebuji půjčit někomu svoji věc, abych mohl(a) získat věc, kterou potřebuji?</h4>
          <p style={{marginLeft: '1rem'}}>
            <li>Nemusíte, každý uživatel si může půjčit jednu věc, aniž by uvedl zástavu.</li>
            <li>Vlastník věci má ale vždy právo vaši žádost odmítnout.</li>
          </p>
          <h4>Co je zástava?</h4>
          <p style={{marginLeft: '1rem'}}>
            <li>Věc, kterou jste nabídli ostatním, následně o ní někdo projevil zájem a je fyzicky zapůjčená u jiného uživatele.</li>
            <li>Vlastně virtuální předmět, který používáte při žádosti o půjčení.</li>
          </p>
          <h4>Jak mě omezuje zástava věci a co mi nabízí?</h4>
          <p style={{marginLeft: '1rem'}}>
            <li>Jako zástavu můžete použít pouze věc, kterou jste nabídli ostatním, následně o ní někdo projevil zájem a je fyzicky zapůjčená.</li>
            <li>Zástavu můžete použít při půjčení jiné věci, jejíž majitel pak získá právo na to: Pokud věc nedokážete vrátit nebo předat dalšímu zástava se vám nemůže vrátit.</li>
            <li>Použijete-li tedy zástavu při půjčení věci, zástavu můžete zpět získat až když věc vrátíte, nebo předáte dalšímu uživateli.</li>
          </p>
          <h4>Jak zajistit aby mé nabídky půjčení věcí ostatním byly dostatečně atraktivní?</h4>
          <p style={{marginLeft: '1rem'}}>
            <li>Nabízet věc dlouho, nelze spoléhat že okamžitě po zveřejnění si věc někdo půjčí a vy budete moci věc použít jako zástavu.</li>
            <li>Nabízet atraktivní věci, které ostatní ve vašem okolí dobře využijí.</li>
          </p>
          <h4>Jak zajistit aby mé poptávky po vypůjčení věci byly dostatečně atraktivní?</h4>
          <p style={{marginLeft: '1rem'}}>
            <li>Nabídnout alespoň jednu věc v přibližně stejné nebo vyšší hodnotě jako zástavu.</li>
            <li>Půjčovat Vaše věci dostatečně spolehlivému uživateli.</li>
            <li>Vracejte věci po uplynutí dohodnuté doby, nebo si zápůjčku prodlužte novou nabídkou případně se dohodněte s majitele na likvidaci věci, bude-li to třeba.</li>
          </p>
          <h4>Co se má stát když uplyne výpujční doba?</h4>
          <p style={{marginLeft: '1rem'}}>
            <li>Pokud vlastník nestáhl nabídku předmět zůstavá zapůjčený, dokud o jeho zapůjčení neprojeví zájem někdo jiný.</li>
            <li>Pokud nabídka byla stažena majitele, předmět se vrací majiteli.</li>
          </p>
          <h4>Jak zajistit  ochranu věcí proti zcizení nebo poškození?</h4>
          <p style={{marginLeft: '1rem'}}>
            <li>Věc neoznačovat 'Pro Vás věc bezvýznamné ceny', takováto věc nepodléhá schválení majitele při zapůjčení.</li>
            <li>Půjčit Vaší věc dostatečně spolehlivému uživateli, můžete využit zobrazené statistiky nebo vašich předchozích zkušeností s konkrétním uživatelem.</li>
            <li>Přijmout zástavu v podobné hodnotě i vyšší hodnotě zapůjčované věci.</li>
            <li>Nepůjčovat cenné věci uživatelům, kteří nemají příliš věcí označených k vypůjčení, nedávno založený profil nebo reportováné předměty nevrácené včas.</li>
          </p>
          <h4>Co dělat když půjčenou věc poškodím?</h4>
          <p style={{marginLeft: '1rem'}}>
            <li>Pokud je poškození opravitelné tak buď sám opravím, nebo použiju servisu opravny.</li>
            <li>Pokud je věc nevratně poškozena, postupuji jako při ztrátě.</li>
          </p>
          <h4>Co dělat když půjčenou věc ztratím, nebo si ji prostě chci nechat?</h4>
          <p style={{marginLeft: '1rem'}}>
            <li>Vždy je třeba kontaktovat majitele, napsat zprávu a poskytnout adekvátní peněžní/nepeněžní vyrovnání.</li>
            <li>V úvahu lze vzít množství výpůjček i stav věci, před poslední zápůjčkou.</li>
            <li>Majitel věci po obdržení vyrovnání má právo věc smazat ze systému, tím se ruší závazky a uvolňuje zástava.</li>
          </p>
          <Button size="lg" variant="secondary" style={customStyle.rigthSecondButtonStyle} onClick={(e) => {navigate(-1);} } >Zpět</Button>
        </Card.Body>
      </Card>
    </Container>
  );
}
