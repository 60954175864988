import axios from "axios";
import log from "common/Log";
import security from "common/Security";

import * as server from "config/server.config";

const API_URL = server.SERVER_POINT + "/api/item/";

const add = (data:any) =>
{
  return axios.post(API_URL + "add", data, { headers: security.authHeader()});
};

const update = (data:any) =>
{
  return axios.post(API_URL + "update", data, { headers: security.authHeader()});
};

const get = (data:any) => {
  return axios
    .post(API_URL + "get", data)
    .then((response) => {
      return response.data;
    });
};


const get_many = (data:any) => {
  return axios
    .post(API_URL + "get_many", data)
    .then((response) => {
      return response.data;
    });
};

const get_borrow_candidates = (data:any) => {
  return axios
    .post(API_URL + "get_borrow_candidates", data)
    .then((response) => {
      return response.data;
    });
};

const get_borrow_item = (data:any) => {
  return axios
    .post(API_URL + "get_borrow_item", data)
    .then((response) => {
      return response.data;
    });
};

const get_lend_candidates = (data:any) => {
  return axios
    .post(API_URL + "get_lend_candidates", data)
    .then((response) => {
      return response.data;
    });
};

const get_mortage_candidates = (data:any) => {
  return axios
    .post(API_URL + "get_mortage_candidates", data)
    .then((response) => {
      return response.data;
    });
};

const del = (data:any) => {
  log.logN("axios.post(API_URL + ")
  return axios.post(API_URL + "del", data, { headers: security.authHeader()})
};

export default {
  add,
  get,
  get_borrow_candidates,
  get_borrow_item,
  get_mortage_candidates,
  get_lend_candidates,
  get_many,
  update,
  del
};
