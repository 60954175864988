import React from 'react';

import { Button, Container } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';  

import log from "common/Log";

import { useCurrentUserState } from "models/CurrentUser";
import Middleware from "middlewares/Delivery";
import reports from "components/Helpers/Reports"

import texts from "common/Texts";
import customStyle from 'common/Style';

export const MeetOverview: React.FC = ({ children })=> {
    const currentUserState = useCurrentUserState()

    const pageName = 'Schůzky'

    const [feedback, setFeedback] = React.useState<string|undefined>(undefined);
    const [meets, setMeets] = React.useState([])

    const navigate = useNavigate()

    function errorService(functionId:string, errorArg:any) :void {
      setFeedback(log.decodeError(pageName, functionId, errorArg)); 
    }
  
    function okService() : void {
        setFeedback(""); 
    }

    React.useEffect(() => {
      Middleware.get_many({'id' :currentUserState.user._id}).then(
        (response:any) => {
          setMeets(response.deliveries.filter((del:any) => del.selectedMeetExchange != undefined && del.stamp == undefined).map((del:any) => 
            {
              const isSender = del.sender.user._id == currentUserState.user._id;
              return {
                deliveryId:del._id,
                time: texts.readableDateTime(del.selectedMeetExchange.time),
                itemName:del.item.name,
                action:isSender ? "Předám" : "Vyzvednu",
                userName:(isSender ? del.receiver.user.name : del.sender.user.name)} }))
        },
        (error:any) => { errorService('delivery/get_many', error) }
      );
      
    }, []);

    const columns = [
      { dataField: 'time', text: 'Kdy', sort: true, style: { width: '20%'}},
      { dataField: 'action', text: 'Akce', sort: true, style: { width: '10%'} },
      { dataField: 'itemName', text: 'Věc', sort: true, style: { width: '35%'} },
      { dataField: 'userName', text: 'Účastník schůzky', sort: true, style: { width: '35%'} },
    ]

    const tableRowEvents = {
      onClick: (e:any, row:any, rowIndex:any) => {
        const ref = "/delivery/show/" + row.deliveryId;
        window.location.assign(ref);
      },
    }

    return (
      <Container>
        <reports.ErrorModal feedback={feedback} setFeedback={setFeedback} />

        <h1>{pageName}</h1>
        <hr/>
        <BootstrapTable
          striped
          bootstrap4
          hover
          keyField="deliveryId"
          columns={columns}
          data= {meets}
          rowEvents={ tableRowEvents }
        />

        <Button size="lg" variant="secondary" style={customStyle.rigthSecondButtonStyle} onClick={(e) => {navigate(-1);} } >Zpět</Button>
      </Container>
    );
  }
