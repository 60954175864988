import React from 'react';

import { Button, Form, Modal } from 'react-bootstrap'

import FlashMessage from "common/FlashMessage";
import customStyle from 'common/Style';

import { useCurrentUserState } from "models/CurrentUser";
import MiddMessage from "middlewares/Message";


export const SendMessageTile: React.FC<{label:any, userId:any, initialText:any, onErrorService:any}> = ({ label, userId, initialText, onErrorService, children })=> {
    const currentUserState = useCurrentUserState()

    const [message, setMessage] = React.useState(initialText)
    const [sent, setSent] = React.useState(false)
    const [showHide, setShowHide] = React.useState(false);

    const sendMessage = () => {
      MiddMessage.add({'receiver': userId, 'sender': currentUserState.user._id, 'message':message}).then(
        (response:any) => {
          setSent(true)
          setShowHide(false)
        },
        (error:any) => { onErrorService('message/add', error) }
      );
    }   

    return (
      <>
      {!showHide && 
        <Button size="lg" variant="success" style={customStyle.buttonStyle} onClick={(e:any)=> { setShowHide(true) }}>{label}</Button> }

      <Modal show={showHide}  size="lg" >
        <Modal.Header>
          <Modal.Title>{label}</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <Form.Control as="textarea" className="txt" rows={6} style={{ marginBottom:'.3rem'}} value={message} onChange={(e:any) => setMessage(e.target.value)}/>
            {sent && <FlashMessage theme='success' text='Odesláno'/> }

          <Button size="lg" variant="secondary" style={customStyle.buttonSecondStyle} onClick={()=>{ setMessage(message + " můj email: " + currentUserState.user.email)}}>Přípoj email</Button>
          <Button size="lg" variant="secondary" style={customStyle.buttonSecondStyle} onClick={()=>{ setMessage(message + " můj telefon: " + currentUserState.user.phoneNumber)}}>Připoj telefon</Button>
        </Modal.Body>
        <Modal.Footer>
          <Button size="lg" variant="success" style={customStyle.buttonStyle} onClick={(e) => {sendMessage()} } >Odešli</Button>
          <Button size="lg" variant="secondary" style={customStyle.buttonSecondStyle}  onClick={(e:any)=> {
            setShowHide(false)
            }}>
              Zpět
          </Button>
        </Modal.Footer>
      </Modal>
      </>
    );
  }
