import axios from "axios";
import log from "common/Log";

import * as server from "config/server.config";

const API_URL = server.SERVER_POINT + "/api/category/";

const get_all = () =>
{
  return axios.post(API_URL + "get_all", {}).then((response) => {
    return response.data;
  });
};


export default {
  get_all
};
