import React from 'react';

import { Card, Button, Form, Container, Image } from 'react-bootstrap'
import { useNavigate, useParams, Link } from 'react-router-dom';

import FlashMessage from "common/FlashMessage";
import log from "common/Log";

import { useCurrentUserState } from "models/CurrentUser";
import Middleware from "middlewares/Message";
import userTile from "components/Helpers/UserTile";
import { SendMessageTile } from "components/Helpers/SendMessageTile";
import texts from "common/Texts";
import reports from "components/Helpers/Reports"
import customStyle from 'common/Style';
import AttachFile from '@material-ui/icons/AttachFile';

export const Message: React.FC = ({ children })=> {
    const currentUserState = useCurrentUserState()

    const pageName = 'Detaily zprávy'

    const [feedback, setFeedback] = React.useState<string|undefined>(undefined);
    const [message, setMessage] = React.useState<any|undefined>()
    
    const { messageId } = useParams();
    const navigate = useNavigate()

    function errorService(functionId:string, errorArg:any) :void {
      setFeedback(log.decodeError(pageName, functionId, errorArg)); 
    }
  
    function okService() : void {
        setFeedback(""); 
    }

    React.useEffect(() => {
      Middleware.get({'id' :messageId}).then(
        (response:any) => {
          setMessage(response.message)
        },
        (error:any) => { errorService('location/get', error) }
      );
      
    }, []);

    if (message == undefined)
      return (<>{(feedback != undefined) &&
        <FlashMessage theme={'danger'}
            text={feedback}
        /> }</>)

    var askLink = undefined
    var deliveryLink = undefined

    if (message.content != undefined)
    {
      if (message.content.ask != undefined)
      {
        askLink = "/proposal/ask/show/" + message.content.ask._id
      }
      if (message.content.delivery != undefined)
      {
        deliveryLink = "/delivery/show/" + message.content.delivery._id
      }
    }

    var initialResponse = "\nRe: " + message.message

    return (
      <Container>
        {feedback == "" &&
            <FlashMessage theme='success'
                text='Proběhlo úspěšně.'/> }

        <reports.ErrorModal feedback={feedback} setFeedback={setFeedback} />
      
        <h1>{pageName}</h1>
        <p className="txt" style={{ float: "right"}}>Odeslána: {texts.readableDateTime(message.createTimestamp)}</p>
        <hr/>

        <Card className="rootTile">
          <Card.Header><h2>Text</h2>
          </Card.Header>

          <Card.Body>
            <Form.Group>
              <Form.Control as="textarea" className="txt" disabled={true} rows={6} value={message.message}/>
            </Form.Group>
          </Card.Body>
        </Card>

        <Card className="rootTile">
          <Card.Header><h2>Odesilatel</h2>
          </Card.Header>
            {userTile.Tile(message.sender, undefined, undefined, undefined)}
          <Card.Body>
            
          </Card.Body>
        </Card>

        <Card className="rootTile">
          <Card.Header>
            <h2>Připojené ve zprávě</h2>
            <AttachFile fontSize="large" style={{float: 'right'}} />
          </Card.Header>

          <Card.Body>
            { askLink != undefined && 
              <Link to={askLink}>
                <Button size="lg" variant="secondary" style={customStyle.rigthSecondButtonStyle}>Poptávka</Button>
              </Link>
            }

            { deliveryLink != undefined && 
                <Link to={deliveryLink}>
                  <Button size="lg" variant="secondary" style={customStyle.buttonSecondStyle}>Zobraz doručení</Button>
                </Link>
            }
          </Card.Body>
        </Card>
        <br/>
        <Button size="lg" variant="secondary" style={customStyle.rigthSecondButtonStyle} onClick={(e) => {navigate(-1);} } >Zpět</Button>
        {message.sender.name !== 'system' &&
          <>
            <SendMessageTile label='Odpovědět odesilateli' initialText={initialResponse} userId={message.sender._id} onErrorService={errorService}/>
          </>
        }
      </Container>
    );
  }
