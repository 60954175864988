import axios from "axios";

import * as server from "config/server.config";

const API_URL = server.SERVER_POINT + "/api/setting/";

const update = (data:any) =>
{
  return axios.post(API_URL + "update", data)
    .then((response) => {
      return response.data;
    });
};

const get = (data:any) => {
  return axios.post(API_URL + "get", data)
    .then((response) => {
      return response.data;
    });
};

export default {
  get,
  update,
};
