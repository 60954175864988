import axios from "axios";
import log from "common/Log";
import security from "common/Security";

import * as server from "config/server.config";

const API_URL = server.SERVER_POINT + "/api/ask/";

const add = (data:any) =>
{
  return axios.post(API_URL + "add", data, { headers: security.authHeader()} );
};

const get = (data:any) => {
  return axios
    .post(API_URL + "get", data)
    .then((response) => {
      return response.data;
    });
};

const get_own = (data:any) => {
  return axios
    .post(API_URL + "get_own", data)
    .then((response) => {
      return response.data;
    });
};

const get_pending_holder = (data:any) => {
  return axios
    .post(API_URL + "get_pending_holder", data)
    .then((response) => {
      return response.data;
    });
};

const get_pending_owner = (data:any) => {
  return axios
    .post(API_URL + "get_pending_owner", data)
    .then((response) => {
      return response.data;
    });
};

const del = (data:any) => {
  return axios.post(API_URL + "del", data, { headers: security.authHeader()} )
};

const stamp = (data:any) =>
{
  return axios.post(API_URL + "stamp", data, { headers: security.authHeader()}).then((response) => {
    return response.data;
  });
};

export default {
  add,
  get,
  get_own,
  get_pending_holder,
  get_pending_owner,
  stamp,
  del
};
