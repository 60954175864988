import React from 'react';

import { Card } from 'react-bootstrap'
import 'components/Letter.css';
import log from "common/Log";
import s3 from "common/s3";

const BookmarkTile: React.FC = ({ children })=> {

  function getImageIdx() : any[] {
    var arr:any[] = []
    while (arr.length < 3)
    {
      const num = Math.floor((Math.random() * 56) + 1)
      if (!arr.includes(num))
        arr.push(num)
    }
    return arr
  }
  
  return (
    <div className='col' style={{width: '70mm', minHeight:'208mm', padding: '1mm', background: '#c8b8a3'}}> 
      <Card style={{border: "0px solid gray", padding: '0mm', margin: '0mm', minHeight:'200mm', background: '#c8b8a3' }}>
        {getImageIdx().map( (iName:any, index:number) => {
          const path = s3.getUrlFromImageName("../bookmark/" + iName) + ".JPEG";
          const key = iName
            return (
                <Card.Img key={key} style={{ width: '60mm', marginTop: '2mm', marginLeft: '1mm', border: "1px solid gray"}} src={path} />)
        })}
      </Card>
    </div>
  );
}

export const BookmarkBack: React.FC = ({ children })=> {

  return (
    <>
      <style type="text/css">
        {`
        body {
          width: 210mm;
          height: 100%;
          margin: 0mm auto;
          padding: 0mm;
          font-size: 12pt;
          background: rgb(204,204,204); 
        }
        .main-page {
          width: 210mm;
          min-height: 297mm;
          margin: 0;       
          background: white;
          box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);
        }
        @page {
          size: A4;
          margin: 0;
        }
        @media print {
          body {
            width: 210mm;
            height: 297mm;
            -webkit-print-color-adjust: exact !important;
          }
        } `}
      </style>
      <div className='main-page'>
        <div className='row' style={{width: '205mm', paddingLeft: '12mm', paddingTop: '5mm'}}  >
            <BookmarkTile/>
            <BookmarkTile/>
            <BookmarkTile/>
        </div>
      </div>
    </>
    )
}
