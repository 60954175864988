import React from 'react';

import { Button, Badge, Card, Container, Tooltip, OverlayTrigger, Modal, Nav } from 'react-bootstrap'
import { Link} from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import ShortTextIcon from '@material-ui/icons/ShortText';
import customStyle from 'common/Style';

const itemInsert = ():React.ReactNode =>
{
  return (
    <>
      <h2 id="create_item">Vložení věci</h2>
      <Link to="/item/create">
        <OverlayTrigger placement="top"
          overlay={
            <Tooltip id="button-tooltip">
              <strong>Vlož věc</strong>
            </Tooltip>
          }
        >
          <ShortTextIcon/>
        </OverlayTrigger>
      </Link><br/>

      Pro věci, které zpřístupníte k zapůjčením by mělo platit<br/>
      <p style={{marginLeft: '1rem'}}>
        <li>Věc není vážně poškozená s ohledem na funkci.</li>
        <li>Pokud je použití složitější, je přiložen manuál nebo je použití popsáno online.</li>
        <li>Pokud je nezbytné doplňující zboží pro použití (třeba sponky do sponkovačky), je přiloženo k věci a uživateli je průběžně doplňováno.</li>
        <li>Pokud je věc vysloveně spotřebního charakteru, prostě se postupně ostatními uživateli spotřebuje (například plechovka s barvou).</li>
      </p>
      Vhodné jsou zejména předměty<br/>
      <p style={{marginLeft: '1rem'}}>
        <li>S dostatečnou životností, opotřebení není překážkou</li>
        <li>Vlastní výroby</li>
        <li>Věci, které potřebujete sporadicky a je výhodnější je v případě potřeby půjčit, než dlouhodobě skladovat.</li>
      </p>
      Pro založení je dále třeba<br/>
      <p style={{marginLeft: '1rem'}}>
        <li>Pořídit jednu fotku, nebo jednoduchou kresbu pro avatar věci</li>
        <li>Pořídit několik fotek (max 5) aby bylo zřejmé v jakém je věc stavu a věc mohla být dobře identifikovatelná</li>
        <li>Výplnit zda-li se jedná o pro Vás <b>Věc bezvýznamné ceny</b>. Pokud ano, věc se automaticky půjčuje prvnímu kdo projeví zájem o půjčení.</li>
        <li>Popsat věc, zmínit k čemu může být užitečná, základní parametry, u knihy vypnit autora.</li>
        <li>Věc zaregistrovat pod výstižným jménem a vložit do systému.</li>
      </p>
    </> )
}

const lendItem = ():React.ReactNode =>
{
  return (
    <>
      <h2 id="echange_item">Půjčení věcí</h2>
        <Link to="/proposal">
          <OverlayTrigger placement="top"
            overlay={
              <Tooltip id="button-tooltip">
                <strong>Prohodit</strong>
              </Tooltip>
            }
          >
            <ShortTextIcon/>
          </OverlayTrigger>
        </Link>
        
        <p style={{marginLeft: '1rem'}}>
          <li>Nejprve pro věc musí být majitelem založena nabídka.</li>
          <li>Na nabídku reaguje poptávkou uživatel, který si chce věc půjčit.</li>
          <li>Následuje potvrzení / odmítnutí nabídky majitelem.</li>
          <li>Pokud vše bylo potvrzeno, budete vyzván k doručení.</li>
        </p>
    </> )
}

const deliverItem = ():React.ReactNode =>
{
  return (
    <>
      <hr/>
      <h2 id="delivery_item">Doručení věcí</h2>
      <Link to="/delivery">
        <OverlayTrigger placement="top"
          overlay={
            <Tooltip id="button-tooltip">
              <strong>Doručit</strong>
            </Tooltip>
          }
        >
          <ShortTextIcon/>
        </OverlayTrigger>
      </Link><br/>

      <Card.Img variant="top" style={{ float: "right", objectFit: 'cover', width: '20rem'}} src='https://prohodto-images.s3.eu-central-1.amazonaws.com/help/delivery.jpg' />

      Doručením se rozumí předání věci odesilatelem příjemci a vzniká tak fyzické zapůjčení.
      Aby v systému bylo dokazatelné předání věci, současně s věcí se vymění kód příjemce.
      <p style={{marginLeft: '1rem'}}>
        <li>Vkládají se návrhy schůzek první odesilatel dále příjemce dokud protistrana termín neschválí.</li>
        <li>Odesilatel předá smluvenou věc výměnou za kód příjemce, pokud uzná příjemce za vhodnou osobu vzhledem k povaze zboží.</li>
        <li>Příjemce zkontroluje, zda zboží odpovídá popisu, není fyzicky poškozené a za zboží předá předem zjištěný kód příjemce.</li>
        <li>Odesilatel má možnost ověřit, že kód příjemce je správný podle známých částí kódu.</li>
        <li>Odesilatel zadá kód příjemce do systému, tím se bere zboží za doručené.</li>
      </p>
    </> )
}

const showPlace = ():React.ReactNode =>
{
  return (
    <>
      <h2 id="attach_place">Připojení místa</h2>
      <Link to="/location/attach">
        <OverlayTrigger placement="top"
          overlay={
            <Tooltip id="button-tooltip">
              <strong>Připoj místo</strong>
            </Tooltip>
          }
        >
          <ShortTextIcon/>
        </OverlayTrigger>
      </Link><br/>
      Rozhodněte se, kde můžete věci v případě potřeby vyzvedávat, předávat věci (u místa bydliště, oblíbené nákupní centrum, chata, ..).<br/>
      Nejdříve se pokuste najít už existující místa <a href="/location/attach">na mapě</a> a ty pouze připojit.<br/>
      Pokud vhodné místo není v systému <a href="#create_place">založte nové</a> a automaticky tak zpřístupníte místo i ostatním.<br/>

      <hr/>
      <h2 id="create_place">Založení místa</h2>
      <Link to="/location/create">
        <OverlayTrigger placement="top"
          overlay={
            <Tooltip id="button-tooltip">
              <strong>Založ místo</strong>
            </Tooltip>
          }
        >
          <ShortTextIcon/>
        </OverlayTrigger>
      </Link><br/>

      <Card.Img variant="top" style={{ float: "right", objectFit: 'cover', width: '20rem'}} src='https://prohodto-images.s3.eu-central-1.amazonaws.com/help/sculpture.jpg' />
      Vyberte místo, které Vám vyhovuje a je pro Vás dobře dostupné při splnění následující podmínek:<br/>
      Místo by <b>mělo být</b> ideálně<br/>
      <p style={{marginLeft: '1rem'}}>
        <li>Veřejně dostupné</li>
        <li>Něčím nápadné, ideálně s nějakým centrálním bodem (dopravní značka, strom, socha, kontejnery na tříděný odpad, ...)</li>
        <li>Blízko MHD, parkoviště nebo místo které lidé normálně navštěvují za jiným účelem</li>
      </p>
      Místo by <b>nemělo být</b><br/>
      <p style={{marginLeft: '1rem'}}>
        <li>Příliš frekventováné (těžko pak budete hledat osobou se kterou si přejete věci vyměnit)</li>
        <li>Na místě kde to může někomu zásadně vadit, zejména majiteli okolních pozemků (předzahrádka restaurace) </li>
        <li>Příliš blízko jiného už založeného místa (alespoň dále než 150 metrů)</li>
      </p>
      Pro založení je dále třeba<br/>
      <p style={{marginLeft: '1rem'}}>
        <li>Pořídit kresbu nebo fotku pro avatar místa</li>
        <li>Pořídit několik fotek ideálně ze směru odkud se obvykle přichází</li>
        <li>Označit místo na mapě</li>
        <li>Místo jednoznačně popsat, doplnit adresou</li>
        <li>Místo zaregistrovat pod zajímavým unikátním jménem a vložit do systému.</li>
      </p>
  </> )
}

const Help: React.FC<{}> = ({ children })=> {
  const pageName = 'Nápověda'
  const navigate = useNavigate()
  const [modalShow, setModalShow] = React.useState<any|undefined>();

  const handleKeyPress = (target:any) => {
    if (target.key === 'Escape') {
      setModalShow(undefined)
    }
  };

  React.useEffect(() => {
    window.addEventListener('keydown', handleKeyPress)
  }, []);

  return (
    <Container>
      <Card className="rootTile">
        <Card.Header><h1>{pageName}</h1></Card.Header>
        <Card.Body style={{fontSize:'110%'}}>
          <h2>Úvod</h2><br/>
          Zajišťujeme půjčování věcí mezi uživateli.<br/>
          Naším cílem je zajistit maximální možnou dostupnost širokého okruhu věcí, bez nutnosti jejich dlouhodobého skladování.<br/>
          Pokud si chcete věci půjčovat, je vhodné jiné věci podobné hodnoty současně půjčovat ostatním.<br/>
          Prozatím je spuštěn testovací provoz, nečekejte velkou ochranu a opravdu cenné věci raději nevkládejte.<br/>
          <br/>
          <b>Ukázkový příklad</b><br/>
          <i>Majitel vrtačky Novák půjčí vrtačku dalšímu uživateli Aloisovi.<br/>
          Vrtačku si předají na místě 'Kotěhůlky park', na kterém se dříve dohodli.<br/>
          Dokud si nezažádá další uživatel o zapůjčení vrtačky, zůstává vrtačka u Aloise.<br/>
          <br/>
          Majitel Novák si pak půjčí štafle od uživatele Vonáska a ručit bude již zapůjčenou vrtačkou. (ručí se vlastní věcí, která ale musí být někomu půjčená).<br/>
          Dokud bude mít uživatel Novák půjčené štafle, nemůže získat zpět vrtačku.
          Vrtačka stále zůstává u někoho zapůjčená, dokud se štafle nevrátí a nebo se nepůjčí někomu jinému než Novákovi.<br/></i>
          <br/><hr/>
          <h2>Pro používání je třeba</h2>
          <p style={{marginLeft: '1rem'}}>
            <li>Nastavit dostupná místa pro vyzvednutí věcí.
              <Button style={{paddingTop: '0.3rem',}} size="lg" variant="link" onClick={(e:any) => setModalShow(showPlace)}>Nastavení míst</Button> </li>
            <li>Založit v systému věci, které později plánujete umožnit sdílet.
              <Button style={{paddingTop: '0.3rem',}} size="lg" variant="link"  onClick={(e:any) => setModalShow(itemInsert)}>Vložení věci</Button> </li>
            <li>Pak si můžete cizí věci půjčovat, pokud vlastníci věcí schválí Vaši poptávku.
              <Button style={{paddingTop: '0.3rem',}} size="lg" variant="link"  onClick={(e:any) => setModalShow(lendItem)}>Půjčení věcí</Button> </li>
            <li>Také můžete vlastní věci půjčovat ostatním, pokud ostatní uživatelé projeví zájem o Vaši nabídku.
              <Button style={{paddingTop: '0.3rem',}} size="lg" variant="link"  onClick={(e:any) => setModalShow(lendItem)}>Půjčení věcí</Button> </li>
            <li>Pro skutečné půjčení věcí je nutné jejich
              <Button style={{paddingTop: '0.3rem',}} size="lg" variant="link"  onClick={(e:any) => setModalShow(deliverItem)}>Doručení</Button> </li>
          </p>
          
          {modalShow != undefined &&
            <Modal dialogClassName="meet-show" show={true} size="lg">
              <Modal.Body>
                {modalShow}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" size="lg" onClick={(e:any)=> {
                    setModalShow(undefined)
                    }}>
                      Zpět
                </Button>
              </Modal.Footer>
            </Modal> }

          <hr/>
          <a href="/user/conditions">Podmínky používání</a>
          <br/>
          <a href="/faq">Dotazy / odpovědi</a><br/>
          <a href="/intro">Úvodní prezentace</a>
          <hr/>
          <h2>Podpora autorů / požadovaná rozšíření</h2><br/>
          Pokud si přejete podpořit náš vývoj, zde je účet pro příspěvky <b>670100-2207234700/6210</b>.<br/>
          Při odeslání částky připište jaké jméno má být zobrazeno v hale sponzorů.
          <hr/>
          <h2>Hlášení chyb a náměty na rozšíření</h2><br/>
          Kontakt <a href="mailto:prohodto@gmail.com">prohodto@gmail.com</a><br/>
          <Button size="lg" variant="secondary" style={customStyle.rigthSecondButtonStyle} onClick={(e) => {navigate(-1);} } >Zpět</Button>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default  { Help, itemInsert, lendItem, deliverItem, showPlace }