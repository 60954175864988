import React from 'react';
import { Types } from "mongoose";
import genericTile from "./GenericTile";

const Tile = (user:any, legend:String|undefined, ref:string|undefined, buttonInPlace:((id: Types.ObjectId) => React.ReactNode)|undefined) => {
  const refSure:string = ref == undefined ? "/user/show/" + user._id : ref

  const mainActionOnClick = () => {
    window.location.assign(refSure);
  }

  return (
    <a key={refSure} style={{ cursor: 'pointer' }} onClick={mainActionOnClick} >
      {genericTile.Tile(user, legend, 'user', undefined, buttonInPlace)}
    </a>
  )
}

export default  { Tile }