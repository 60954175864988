export const initialPosition = { latitude:50.075539, longitude:14.437800 }

export interface IPosition {
  latitude: Number,
  longitude: Number,
}

export let geoOptions = {  
  enableHighAccuracy:false,  
  timeOut: 20000, //20 second
  maximumAge: Infinity
}; 
