
import React from 'react';

import log from "common/Log";
import { Form } from 'react-bootstrap'

const Manual: React.FC<{enhancement:any, isReadOnly:any, dataCallbackFromDown:any}> = ({ enhancement, isReadOnly, dataCallbackFromDown, children })=> {
  const manualInitSafe:any = enhancement[getStaticKey()] != undefined ? enhancement[getStaticKey()] : ""

  return (
    <>
      <Form.Group controlId="mans">
        <Form.Control as="textarea" rows={35} defaultValue={manualInitSafe}  disabled={isReadOnly} onChange={(e:any) => { dataCallbackFromDown(e.target.value) }} />
      </Form.Group>
    </>
  );
}

const getStaticName = () => 
{
  return "Manuál"
}

const getStaticKey = () => 
{
  return "manual"
}

const initInstance = (enhancement:any, enabled:boolean) => 
{
  var cc = enhancement;
  cc[getStaticKey()] = enabled ? "" : undefined
  return cc
}

const decoder = (enhancement:any, isReadOnly:any, dataCallbackFromDown:any) =>
{
  return (
    <>
      <Manual enhancement={enhancement} isReadOnly={isReadOnly} dataCallbackFromDown={dataCallbackFromDown}/>
    </> )
}

const isContained = (enhancement:any):boolean => {
  return enhancement[getStaticKey()] != undefined
}

const getHandle = () => 
{
  return { isContained,
           getStaticName,
           getStaticKey,
           initInstance,
           decoder }
}

export default  { getHandle };
