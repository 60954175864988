import axios from "axios";
import log from "common/Log";

import * as server from "config/server.config";

const API_URL = server.SERVER_POINT + "/api/clowd/";

const config = {
  onUploadProgress: function(progressEvent:any) {
    var percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    console.log(percentCompleted);
  }
};

const image_is_valid = (file:any, classId:any) =>
{
  if (classId == 0)
  {
    if (file.size > 524288)
    {
      return {error: "Nahrávaný obrázek překročil limit velikosti 0.5MB a nebude nahrán, současná velikost:" + file.size};
    }
  }

  if (classId == 1)
  {
    if (file.size > 1048576)
    {
      return {error: "Nahrávaný obrázek překročil limit velikosti 1MB a nebude nahrán, současná velikost:" + file.size};
    }
  }

  return {}
}

const blob_to_file = (theBlob: Blob, fileName:string): File => {
  var b: any = theBlob;
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  b.lastModifiedDate = new Date();
  b.name = fileName;

  //Cast to a File() type
  return b;
}

const upload_image = async (file:any, userId:any, classId:any) => {
  log.log('file', file)
  const validationResult:any = image_is_valid(file, classId)
  log.log('result', validationResult)

  if (validationResult.error != undefined)
    return validationResult;

  try
  {
    const response:any = await axios.post(API_URL + "create_image_slot", {userId:userId})
    
    log.log('create_image_slot response', response)

    const res = await axios.put(response.data.url, file, config)
    log.log("Upload Completed", res); 
    return {imageName:response.data.imageName}
  }
  catch(err:any)
  {
    return {error: err};
  }
}



export default {
  upload_image,
  blob_to_file
};
