import React from 'react';

import { Routes, Route } from "react-router-dom";
import log from "common/Log";

import { MessageOverview } from 'components/MessageOverview';
import { Message } from 'components/Message';

export const MessageControl: React.FC<{}> = ({ children })=> {

  return (
    <>
      <Routes>
        <Route path="/" element={<MessageOverview/>}/>

        <Route path="show/:messageId" element={<Message/>}/>
      </Routes>
    </>
  );
}
