import React from 'react';

import { Link } from 'react-router-dom';
import { Container, Button, Badge, Card, Form } from 'react-bootstrap'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import AuthService from "middlewares/User";

import texts from "common/Texts";
import log from "common/Log";
import customStyle from 'common/Style';
import validator from "common/Validator";

import reports from "components/Helpers/Reports"
import { ProfileImage } from "components/ProfileImage";
import { useControl, useCurrentUserState } from "models/CurrentUser";
import { useNavigate } from 'react-router-dom';

export const User: React.FC<{isAddMode:boolean}> = ({ isAddMode, children })=> {
    const currentUserState = useCurrentUserState()
    const { update } = useControl()

    const addMode = !currentUserState.isLogged || isAddMode;
    const editMode = !addMode;
    const pageName = texts.pageNamePrefix(addMode) + 'uživatele'
    const navigate = useNavigate()

    const [user, setUser] = React.useState<any|undefined>(currentUserState.user);
    const [feedback, setFeedback] = React.useState<string|undefined>(undefined);
    const [createdDone, setCreatedDone] = React.useState<boolean>(false);

    const [userName,    setUserName]  = React.useState("");
    const [password,    setPassword]  = React.useState("");
    const [intro,       setIntro]     = React.useState("");
    const [firstName,   setFirstName] = React.useState("");
    const [sureName,    setSureName]  = React.useState("");
    const [phoneNumber, setPhoneNumber] = React.useState("");
    const [birthYear,   setBirthYear] = React.useState("");
    const [email,       setEmail]     = React.useState("");

    const [imageName, setImageName] = React.useState(addMode ? `king.png` : currentUserState.user.profileImage)

    function errorService(functionId:string, errorArg:any) :void {
      setFeedback(log.decodeError(pageName, functionId, errorArg)); 
    }
  
    function okService() : void {
        setFeedback(""); 
    }

    function errorInField(fieldId:string, message:string, value:any) :void {
      log.errorOnUserInput("User", fieldId, message, value);
      setFeedback(validator.inputErrorRedable(fieldId, message, value)); 
    }
    React.useEffect(() => {
      if (user != undefined)
      {
        setUserName(user.name)
        setPassword(addMode ? "" : "******")
        setIntro(user.intro)
        setFirstName(user.firstName)
        setSureName(user.sureName)
        setPhoneNumber(user.phoneNumber)
        setBirthYear(user.birthYear)
        setEmail(user.email)
      }
    }, []);

    const onSubmit = (): void => {
      if (userName == "")
      {
        errorInField('Uživatelské jméno', 'prázdná hodnota', '')
        return
      }

      if (password == "" || password.length < 6)
      {
        errorInField('Heslo', 'délka alespoň 6 znaků', '')
        return
      }

      if (firstName == "" || firstName == undefined)
      {
        errorInField('Jméno', 'Nevyplněno', '')
        return
      }
      if (sureName == "" || sureName == undefined)
      {
        errorInField('Příjmení', 'Nevyplněno', '')
        return
      }
      if (birthYear == "" || birthYear == undefined)
      {
        errorInField('Rok narození', 'Nevyplněno', '')
        return
      }
      const birthYearInt = parseInt(birthYear)
      if (isNaN(birthYearInt) || birthYearInt < 1900 || birthYearInt > 2018)
      {
        return errorInField('Rok narození', 'hodnoty 1900-2018', birthYearInt)
      }
      if (email == "")
      {
        errorInField('Email', 'Nevyplněno', '')
        return
      }
      if (!email.includes("@"))
      {
        errorInField('Email', 'Neplatný email, neobsahuje znak "@"', '')
        return
      }

      var data:any = {}
      data.profileImage = imageName;
      data.name = userName;
      if (addMode)
      {
        data.password = password
      }
      else
      {
        data._id = user._id
      }
      data.intro = intro
      data.firstName = firstName
      data.sureName = sureName
      data.phoneNumber = phoneNumber
      data.birthYear = birthYear
      data.email = email

      if (addMode)
      {
          AuthService.register(data).then((response)=>{okService(); setCreatedDone(true)} , (errorArg:any) => {errorService('user/register', errorArg)});
      }
      else
      { // edit
          AuthService.update(data).then((response:any)=>{
            log.log("response", response)
            update(response.user)
            okService()
            navigate(-1);
          } , (errorArg:any) => {errorService('user/update', errorArg)});
      }
    }

    if (createdDone)
    {
      return (
        <Container>
          <Card className="rootTile">
            <Card.Header><h2>Uživatel byl vytvořen.</h2><br/>
            Za moment bude zaslán aktivační odkaz na zadaný email.<br/>
            Po aktivaci bude váš účet plně funkční.</Card.Header>
          </Card>
        </Container> )
    }

    return (
      <Container>
        <h1>{pageName}</h1>
        <hr/>

        <Container>
          <Row>
            <Col style={{float: "right"}} >
              <ProfileImage imageParent={imageName} onUpdateParent={ (imageBodyArg:any) => { setImageName(imageBodyArg); okService()} } onError={errorService} userId={undefined} isReadOnly={false}/>
            </Col>
          </Row>
        </Container>
        <hr/>
        <reports.ErrorModal feedback={feedback} setFeedback={setFeedback} />

        <Card style={{color: 'DarkGreen', background: '#b6e4b6' }} className="mb-2">
          <Card.Header><h2>Veřejné</h2></Card.Header>
          <Card.Body>
            <Form.Group controlId="userNameX">
              <Form.Label>Uživatelské jméno</Form.Label><br/>
              <input type="text" style={{borderRadius: '3px', width: '30rem'}} name="username" id="username" value={userName} onChange={(e:any) => { setUserName(e.target.value); }}/>
            </Form.Group>

            <Form.Group controlId="introX">
              <Form.Label>O uživateli</Form.Label>
              <Form.Control as="textarea" rows={5} value={intro} onChange={(e:any) =>    { setIntro(e.target.value); } } />
            </Form.Group>

            <Form.Group controlId="birthYearX">
              <Form.Label>Rok narození</Form.Label>
              <Form.Control type="input" value={birthYear} onChange={(e:any) => { setBirthYear(e.target.value); } } />
              <Form.Text className="text-muted">
                  Ostatní uživatelé uvidí pouze interval, ve kterém se rok nachází
              </Form.Text>
            </Form.Group>
          </Card.Body>
        </Card>

        <Form.Group controlId="firstNameX">
           <Form.Label>Jméno</Form.Label>
           <Form.Control type="input" value={firstName} onChange={(e:any) => { setFirstName(e.target.value); } } />
        </Form.Group>

        <Form.Group controlId="sureNameX">
          <Form.Label>Příjmení</Form.Label>
          <Form.Control type="input" value={sureName} onChange={(e:any) => { setSureName(e.target.value); } } />
        </Form.Group>

        {addMode &&
        <Form.Group controlId="passwordX">
          <Form.Label>Heslo</Form.Label><br/>
          <input type="password" style={{borderRadius: '3px', width: '30rem'}} name="password" id="password" value={password} onChange={(e:any) => { setPassword(e.target.value); } }/>
        </Form.Group> }

        <Form.Group controlId="phoneNumberX">
          <Form.Label>Telefonní číslo</Form.Label>
          <Form.Control type="input" value={phoneNumber} onChange={(e:any) => { setPhoneNumber(e.target.value); } } />
        </Form.Group>

        <Form.Group controlId="emailX">
          <Form.Label>Email</Form.Label>
          <Form.Control type="input" value={email} onChange={(e:any) => { setEmail(e.target.value); } } />
        </Form.Group>
                   
        {editMode &&
          <>
          <hr/>
          <Link to="/user/change_password">
            <Button size="lg" variant="secondary" style={customStyle.buttonSecondStyle} >Změna hesla</Button>
          </Link>
          </>}

        <hr/>
        <Button size="lg" variant="secondary" style={customStyle.rigthSecondButtonStyle} onClick={(e) => {navigate(-1);} } >Zpět</Button>         
        <Button size="lg" variant="success" style={customStyle.rigthButtonStyle}  onClick={onSubmit}>{texts.actionName(addMode)}</Button>
      </Container>
    );
  }
