import React from 'react';
import { Types } from "mongoose";

import genericTile from "./GenericTile";

const ButtonEdit = (id:any) =>
{
  return genericTile.ButtonEdit("/item/edit/" + id, 'Uprav')
}

const ButtonDelete = (id:any) =>
{
  return genericTile.ButtonDelete("/item/delete/" + id, 'Smaž')
}

const OnClickActionDefault = (item:any) => {
  const refSure:string = "/item/show/" + item._id
  window.location.assign(refSure);
}

const Tile = (item:any, legend:string|undefined, onClickAction:any|undefined, suffix:any|undefined, buttonInPlace:((id: Types.ObjectId) => React.ReactNode)|undefined) => {
  const nameKey:string = "item.show." + item._id
  return (
    <a key={nameKey} style={{ cursor: 'pointer' }} onClick={(e:any) => {
        if (onClickAction != undefined)
          onClickAction(e)
        else
          OnClickActionDefault(item)
        } } >
      {genericTile.Tile(item, legend, 'item', suffix, buttonInPlace)}
    </a>
  )
}

export default  { Tile, ButtonEdit, ButtonDelete, OnClickActionDefault }