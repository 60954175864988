import React from 'react';

import { Button, Form, Card, Container } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';

import log from "common/Log";
import texts from "common/Texts";
import customStyle from 'common/Style';

import { useCurrentUserState } from "models/CurrentUser";

import Midd from "middlewares/Setting";

import reports from "components/Helpers/Reports"

export const Setting: React.FC = ({ children })=> {
  const pageName = 'Nastavení'

  const currentUserState = useCurrentUserState()

  const [feedback, setFeedback] = React.useState<string|undefined>(undefined);
  const navigate = useNavigate()

  const [resendMessages, setResendMessages] = React.useState(true);

  function errorService(functionId:string, errorArg:any) :void {
    setFeedback(log.decodeError(pageName, functionId, errorArg)); 
  }

  function okService() : void {
    setFeedback("");
  }

  React.useEffect(() => {
    Midd.get({'_id' :currentUserState.user.setting}).then(
      (response:any) => {
        setResendMessages(response.setting.resendMessages)
      },
      (error:any) => { errorService('setting/get', error) })
    }, []);

  const onSubmit = (data:any): void => {
    const result = {'id' :currentUserState.user.setting, 'resendMessages': resendMessages }
    Midd.update(result).then(
      (response)=>{ navigate(-1); },
      (errorArg:any) => { errorService('setting/update', errorArg) }
    );
  }

  return (
    <Container>
      <reports.ErrorModal feedback={feedback} setFeedback={setFeedback} />

      <Card className="rootTile">
        <Card.Header><h2>{pageName}</h2></Card.Header>
        <Card.Body>
          <Form.Group controlId="repeated">
            <Form.Check type="checkbox" checked={resendMessages} name="formHorizontalRadios" label="Přeposílat zprávy na osobní email" onClick={(e:any) => { setResendMessages(e.target.checked); } } />
          </Form.Group>
        </Card.Body>
      </Card>
      <br/>

      <Button size="lg" variant="secondary" style={customStyle.rigthSecondButtonStyle} onClick={(e) => {navigate(-1);} } >Zpět</Button>
      <Button size="lg" variant="success" style={customStyle.rigthButtonStyle} onClick={onSubmit}>{texts.actionName(false)}</Button>
    </Container>
  );
}
