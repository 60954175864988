import React from 'react';

import { Button, Form, Card, Container } from 'react-bootstrap'
import { Link} from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import customStyle from 'common/Style';

export const Conditions: React.FC<{agreement:any}> = ({ agreement, children })=> {
  const navigate = useNavigate()

  return (
    <Container>
      <Card className="rootTile">
        <Card.Header><h2>Podmínky používání této platformy</h2></Card.Header>
        <Card.Body style={{fontSize:'120%'}}>
          Souhlasíte se zpracováním vyžádaných osobních údajů pro potřeby této platformy.<br/>
          <br/>
          Následující text se týká pouze předmětů / akcí / chování v souvislosti s používáním této platformy.<br/>
          <br/>
          Jste si vědomi, že provozovatel platformy nenese zodpovědnost za to, že půjčené věci mohou být poškozeny, ukradeny, či jinak znehodnoceny.<br/>
          Zejména z uvedených důvodů doporučujeme nesdílet předměty velké hodnoty, dokud tato platfroma neposkytne dokonalejší zabezpečení.<br/>
          <br/>
          Jste si vědomi, že provozovatel platformy nenese zodpovědnost za to, pokud následkem zapůjčením věcí dojde k úrazu, či jinému zneužití.<br/>
          <br/>
          <h2>Následující věci jsou pro sdílení trvale zakázany</h2><br/>
          <p style={{marginLeft: '1rem'}}>
            <li>Zbraně umožňující vážné zranění (palné, sečné,..).</li>
            <li>Drogy, halucinační látky</li>
            <li>Nebezpečné chemické látky vyžadující odbornou manipulaci</li>
            <li>Nebezpečné přístroje vyžadující odbornou manipulaci</li>
            <li>Věci nebezpečné důsledkem poškození</li>
            <li>Věci jejichž půjčení je protiprávní</li>
          </p>
          <br/>
          <h2>Následujícím uživatelům může být přístup k platformě dočasně pozastaven, nebo zrušen v případě že</h2><br/>
          <p style={{marginLeft: '1rem'}}>
            <li>Nedostojí svým závazkům</li>
            <li>Dopustí se klamání o zpřístupněném obsahu</li>
            <li>Dopustí se podvodu či jiné trestné činosti vázané na tuto platformu</li>
          </p>
          <br/>
          <h2>Následující uživatelé jsou z užívání platfromy vyloučeni po dobu uvedených skutečností</h2><br/>
          <p style={{marginLeft: '1rem'}}>
            <li>Pokud mají doma štěnice nebo šváby</li>
            <li>Pokud trpí infekční chorobou</li>
          </p>
          <br/>
          <h2>Každý uživatel platformy je povinnen zajišťovat, aby</h2><br/>
          <p style={{marginLeft: '1rem'}}>
            <li>Podmínky, aby výše uvedené podmínky nebyly porušeny</li>
            <li>Aby se děti či mladší osoby nedostaly k věcem vedoucím k ohrožení mravní výchovy, nebo jim nebezpečným.</li>
            <li>Aby se k osobám nedůvěryhodným nedostaly věci vedoucí k ohrožení života, majetku.</li>
            <li>Zapůjčené předměty byly předávány vždy ve funkčním stavu, čisté a připravené k použití.</li>
            <li>Uživatel se vypořádal s oprávněným majitelem, pokud dojde ke ztrátě či poškození jím zapůjčených věcí.</li>
          </p>
          <br/>
        </Card.Body>
          {agreement && <Card.Footer> 
            <Button size="lg" variant="secondary" style={customStyle.rigthSecondButtonStyle} onClick={(e) => {navigate(-1);} } >Odmítám</Button>
            <Link to="/user/create" style={{float: 'right'}}>
              <Button size="lg" variant="success" style={customStyle.rigthButtonStyle}>Souhlasím</Button>
            </Link>
          </Card.Footer>}
        {!agreement && <Card.Footer> 
          <Button size="lg" variant="secondary" style={customStyle.rigthSecondButtonStyle}  onClick={(e) => {navigate(-1);} } >Zpět</Button>
        </Card.Footer>}
      </Card>
    </Container>
  );
}
