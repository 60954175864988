import React from 'react';
import { Button, Container, Form } from 'react-bootstrap/';
import { useNavigate } from 'react-router-dom';

import validator from "common/Validator";
import log from "common/Log";
import FlashMessage from "common/FlashMessage";
import customStyle from 'common/Style';

import reports from "components/Helpers/Reports"

import AuthService from "middlewares/User";

export const ResetPassword: React.FC<{}> = ({ children })=> {

  const pageName = 'Resetování hesla'

  const [feedback, setFeedback] = React.useState<string|undefined>(undefined);
  const navigate = useNavigate()

  const [userName,    setUserName]  = React.useState("");

  function errorService(functionId:string, errorArg:any) :void {
    setFeedback(log.decodeError(pageName, functionId, errorArg)); 
  }

  function errorInField(fieldId:string, message:string, value:any) :void {
    log.errorOnUserInput("ResetPassword", fieldId, message, value);
    setFeedback(validator.inputErrorRedable(fieldId, message, value)); 
  }

  function okService() : void {
    setFeedback(""); 
}
  function onSubmit() {
    if (userName == "")
    {
      errorInField('Jméno uživatele', 'nevyplněno', '')
      return
    }

    var data:any = {}
    data.name = userName
    AuthService.reset_password(data).then((response:any)=>{ okService() } , (errorArg:any) => {errorService('user/reset_password', errorArg)});
  }

  return (
    <Container>
      {(feedback == "") &&
        <>
          <FlashMessage theme='success' text='Resetování hesla proběhlo úspěšně.'/>
          <Button size="lg" variant="secondary" style={customStyle.rigthSecondButtonStyle}  onClick={(e) => {navigate(-1);} } >Zpět</Button>
        </>
        }

      <reports.ErrorModal feedback={feedback} setFeedback={setFeedback} />

      {feedback === undefined && <>
        <h2>Opravdu si přejete resetovat heslo pro zadaného uživatele ?</h2><br/>
        <br/>
        <Form.Group controlId="userNameX">
          <Form.Label>Uživatelské jméno</Form.Label>
          <Form.Control type="input" onChange={(e:any) => { setUserName(e.target.value); } } />
        </Form.Group>
          Nové heslo Vám přijde emailem uvedeným u uživatele.<br/>

          <Button size="lg" variant="secondary" style={customStyle.rigthSecondButtonStyle} onClick={(e) => {navigate(-1);} } >Zpět</Button>
          <Button size="lg" variant="success" style={customStyle.rigthButtonStyle}  onClick={onSubmit}>Resetuj heslo</Button>
        </>}

    </Container>
  );
}
