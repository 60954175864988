import React from 'react';

import { Routes, Route } from "react-router-dom";
import log from "common/Log";

import { DeliveryOverview } from 'components/DeliveryOverview';
import { Delivery } from 'components/Delivery';

export const DeliveryControl: React.FC<{}> = ({ children })=> {

   return (
    <div>
      <Routes>
        <Route path="/" element={<DeliveryOverview/>} />

        <Route path="show/:deliveryId" element={<Delivery mode='edit'/>} />

        <Route path="delete/:deliveryId" element={<Delivery mode='delete'/>} />

      </Routes>
    </div>
  );
}
