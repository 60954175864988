
const getImageUrl = () => {
  return `https://prohodto-images.s3.eu-central-1.amazonaws.com/images/`
}
const getUrlFromImageName = (imageName:string):string => {
  return getImageUrl() + imageName;
};

const getImageNameFromUrl = (url:string):string => {
  return url.replace(getImageUrl(), "");
};

export default { getUrlFromImageName, getImageNameFromUrl }

