
import React from 'react';

import { Form, Card } from 'react-bootstrap'

import log from "common/Log";
import customStyle from 'common/Style';
import validator from "common/Validator";

const Book: React.FC<{initKind:any, isReadOnly:any, dataCallback:any}> = ({ initKind, isReadOnly, dataCallback, children })=> {

  const initKindSafe:any = initKind != undefined ? initKind : {}
  const initBookSafe:any = initKindSafe[getStaticKey()] != undefined ? initKindSafe[getStaticKey()] : {}

  const author = initBookSafe.author != undefined ? initBookSafe.author : "";
  const pagesCount = initBookSafe.pagesCount != undefined ? initBookSafe.pagesCount : "";
  const releaseYear = initBookSafe.releaseYear != undefined ? initBookSafe.releaseYear : "";
  const isbn = initBookSafe.isbn != undefined ? initBookSafe.isbn : "";

  return (
    <>
      <Form.Group controlId="author1">
        <Form.Label>Jméno autora</Form.Label>
        <Form.Control type="input" disabled={isReadOnly}  defaultValue={author} onChange={(e:any) => { dataCallback('author', e.target.value); } } />
      </Form.Group>  

      <Form.Group controlId="pagesCount1">
        <Form.Label>Počet stránek</Form.Label>
        <Form.Control type="input" disabled={isReadOnly}  defaultValue={pagesCount} onChange={(e:any) => { dataCallback('pagesCount', e.target.value); }} />
      </Form.Group>

      <Form.Group controlId="isbn1">
        <Form.Label>ISBN</Form.Label>
        <Form.Control type="input" disabled={isReadOnly}  defaultValue={isbn} onChange={(e:any) => dataCallback('isbn', e.target.value)} />
      </Form.Group>

      <Form.Group controlId="releaseYear1">
        <Form.Label>Rok vydání</Form.Label>
        <Form.Control type="input" disabled={isReadOnly}  defaultValue={releaseYear} onChange={(e:any) => dataCallback('releaseYear', e.target.value)} />
      </Form.Group>  
    </>
  );
}

function errorInField(fieldId:string, message:string, value:any) :any {
  log.errorOnUserInput('Autor', fieldId, message, value);
  return { 'error': validator.inputErrorRedable(fieldId, message, value) };
}

// handleClick is the same function object
const getData = (inputKind:any) => {
  var result:any = {}
  const input = inputKind[getStaticKey()]
  if (input.author == "")
    result.author = undefined
  else
    result.author = input.author

  if (input.pagesCount != "" && input.pagesCount != undefined)
  {
    const pagesCountInt = parseInt(input.pagesCount)
    if (isNaN(pagesCountInt) || pagesCountInt < 1 )
    {
      return errorInField('Počet stran', 'alespoň 1', input['pagesCount'])
    }
    result.pagesCount = pagesCountInt
  }
  
  if (input.releaseYear != "" && input.releaseYear != undefined)
  {
    const releaseYearInt = parseInt(input.releaseYear)
    if (isNaN(releaseYearInt) || releaseYearInt < 1400 || releaseYearInt > 2040)
    {
      return errorInField('Rok vydání', 'hodnoty 1400-2040', input.releaseYear)
    }
    result.releaseYear = releaseYearInt
  }

  if (input.isbn == "")
    result.isbn = undefined
  else
    result.isbn = input.isbn

  return { 'book' : result }
}

const getStaticName = ():string => 
{
  return "Kniha"
}

const getStaticKey = ():string => 
{
  return "book"
}

const getInitInstance = () => 
{
  return { "book" : { 'exist_marker' : "*"}}
}

const decoder = (kind:any, isReadOnly:any, dataCallback:any) =>
{
  return (
    <>
      <Book initKind={kind} isReadOnly={isReadOnly} dataCallback={dataCallback}/>
    </> )
}

const getName = (kind:any):any => { 
  var pick = kind[getStaticKey()];
  if (pick != undefined)
    return getStaticName()

  return undefined
}

const getHandleIfMatching = (kind:any):any => {
  var pick = kind[getStaticKey()];
  if (pick != undefined)
  {   
    return getHandle()
  }

  return undefined
}

const getDescriptionHead = (kind:any) => {
  var result:string = ""
  var pick = kind[getStaticKey()];
  if (pick != undefined)
  {
    var prev = false;
    if (pick.author != undefined)
    {
      result += "Autor: " + pick.author
      prev = true
    }
    if (pick.pagesCount != undefined)
    {
      if (prev)
        result += ", "
      prev = true

      result += "počet stran: " + pick.pagesCount.toString()
    }
    if (pick.releaseYear != undefined)
    {
      if (prev)
        result += ", "

      result += "rok vydání: " + pick.releaseYear.toString()
    }
    return result;
  }

  return undefined
}

const getStaticClassId = ():Number =>
{
  return 1; // ## #classId#
}

const getHandle = () => 
{
  return { getName:getName,
           getData:getData,
           getStaticName:getStaticName,
           getStaticClassId:getStaticClassId,
           getStaticKey:getStaticKey,
           getInitInstance:getInitInstance,
           getDescriptionHead:getDescriptionHead,
           getHandleIfMatching:getHandleIfMatching,
           decoder:decoder }
}

export default  { getHandle };
