
import React from 'react';

import { Card, Button, Form, Container } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom';
import { Types } from "mongoose";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import validator from "common/Validator";
import log from "common/Log";
import { RenderedCounter } from 'common/Perf';
import customStyle from 'common/Style';

import { useCurrentUserState } from "models/CurrentUser";

import MiddItem from "middlewares/Item";
import MiddAsk from "middlewares/Ask";

import itemTile from "components/Helpers/ItemTile";
import locationTile from "components/Helpers/LocationTile";
import reports from "components/Helpers/Reports"
import locationSelector from "components/Helpers/LocationSubsetSelector";
import { ItemSubsetSelector } from "components/Helpers/ItemSubsetSelector";
import userTile from "components/Helpers/UserTile";
import genericTile from "components/Helpers/GenericTile";

export const ProposalAsk: React.FC<{isReadOnly:boolean}> = ({ isReadOnly, children })=> {
  const pageName = 'Žádost o zapůjčení'

  const { askId, borrowItemId } = useParams();
  const addMode = askId == undefined;
  const navigate = useNavigate()

  const currentUserState = useCurrentUserState()
  const [selectedToBorrow, setSelectedToBorrow] = React.useState([] as any);
  const [selectedToMortage, setSelectedToMortage] = React.useState([] as any);
  const [eligibleToMortage, setEligibleToMortage] = React.useState([] as any);
  const [eligibleLocations, setEligibleLocations] = React.useState([] as any);
  const [requestingUser, setRequestingUser] = React.useState<any|undefined>();
  const [selectedLocationsIds, setSelectedLocationsIds] = React.useState([] as any);
  const [period, setPeriod] = React.useState("");
  const [minPeriod, setMinPeriod] = React.useState(1);
  const [maxPeriod, setMaxPeriod] = React.useState<any|undefined>();

  const [feedback, setFeedback] = React.useState<string|undefined>(undefined);

  function errorService(functionId:string, errorArg:any) :void {
    setFeedback(log.decodeError(pageName, functionId, errorArg)); 
  }

  React.useEffect(() => {
    if (askId == undefined )
    {
      MiddItem.get_borrow_item({'id' :borrowItemId}).then(
        (response:any) => {
          setSelectedToBorrow([response.item])
          setEligibleLocations(response.locations)
          if (response.item.bid != undefined)
          {
            setMinPeriod(response.item.bid.minPeriod)
            setMaxPeriod(response.item.bid.maxPeriod)
          }
        },
        (error:any) => { errorService('item/get', error) }
      );
            
      MiddItem.get_mortage_candidates({'userId' :currentUserState.user._id}).then(
        (response:any) => {
          setEligibleToMortage(response.items)
        },
        (error:any) => { errorService('item/get_mortage_candidates', error) })
    }
    else
    {
      MiddAsk.get({'id' :askId}).then(
        (response:any) => {
          setSelectedToBorrow([response.ask.item])
          if (response.ask.mortageItem != undefined)
            setSelectedToMortage([response.ask.mortageItem])

          if (response.ask.item.bid != undefined)
          {
            setMinPeriod(response.ask.item.bid.minPeriod)
            setMaxPeriod(response.ask.item.bid.maxPeriod)
          }

          if (response.ask.owner._id != currentUserState.user._id)
            setRequestingUser(response.ask.owner)

          setEligibleLocations(response.ask.locations)
          setPeriod(response.ask.period)
          setSelectedLocationsIds(response.ask.locations.map((loc:any) => loc._id))          
        },
        (error:any) => { errorService('ask/get', error) }
      );
    }
  }, []);


  function errorInField(fieldId:string, message:string, value:any) :void {
    log.errorOnUserInput("Item find", fieldId, message, value);
    setFeedback(validator.inputErrorRedable(fieldId, message, value)); 
  }

  const onSubmit = (data:any): void => {
    if (selectedToBorrow.length == 0)
    {
      errorInField('Věc na vypůjčení', 'není vybraná', '')
      return
    }
    if (selectedLocationsIds.length == 0)
    {
      errorInField('Místo k vyzvednutí', 'není vybraná ani jedna', '')
      return
    }
   
    if (period == undefined || period == "")
    {
      errorInField('Na jak dlouho chcete vypůjčit', 'prázdná hodnota', '')
      return
    }

    const periodInt = parseInt(period)
    if (isNaN(periodInt) || periodInt < 1 )
    {
      errorInField('Na jak dlouho chcete vypůjčit', 'alespoň 1', period)
      return
    }

    const result = {'owner': currentUserState.user._id, 
      ...(selectedToMortage.length > 0 && {'mortageItem': selectedToMortage[0]._id}),
      'locations' : selectedLocationsIds,
      'item' : selectedToBorrow[0]._id,
      'period': periodInt }

      log.log('result', result)
      log.log('selectedToMortage', selectedToMortage)
      MiddAsk.add(result).then((response)=>{navigate(-1)} , (errorArg:any) => {errorService('ask/add', errorArg)});
  }

  const locationSelect = (ids:Types.ObjectId) => {
    setSelectedLocationsIds(selectedLocationsIds.concat(ids))  }

  const removeLocation = (idx:number) => {
    const temp = [...selectedLocationsIds];
    temp.splice(idx, 1);
    setSelectedLocationsIds(temp)
  };
   
  return (
    <Container>
      <reports.ErrorModal feedback={feedback} setFeedback={setFeedback} />

      <h1>{pageName}</h1>
      <hr/>

      <Row>
        <Col>
          <Card className="rootTile">
            <Card.Header><h2>Věci</h2></Card.Header>
            <Card.Body>
              <div className="card-group" style={customStyle.panelWithTiles}>
                { selectedToBorrow.map( (item:any) => {
                    return itemTile.Tile(item, undefined, undefined, undefined, undefined)
                  })
                }
              </div>
            </Card.Body>
          </Card>
        </Col>
        {requestingUser != undefined &&
          <Col>
            <Card className="rootTile">
              <Card.Header><h2>Pro uživatele</h2>
              </Card.Header>
              <Card.Body>
              {userTile.Tile(requestingUser, undefined, undefined, undefined)}                
              </Card.Body>
            </Card>
          </Col>
        }
        <Col>
          <Card className="rootTile">
            <Card.Header><h2>Nabídka do zástavy</h2>
              <ItemSubsetSelector items={eligibleToMortage} isReadOnly={isReadOnly} itemSelector={(item:any) => { return setSelectedToMortage([item]) }} >/</ItemSubsetSelector>
            </Card.Header>
            <Card.Body>
              <div className='row'>
                { selectedToMortage.map( (item:any) => {
                    return itemTile.Tile(item, undefined, undefined, undefined, undefined)
                  })
                }
                { selectedToMortage.length == 0 &&
                  <Form.Label>Zatím nic (to rozhodně nemusí stačit)</Form.Label> }
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Card className="rootTile">
        <Card.Header ><h2>Místa pro vyzvednutí</h2>
          <locationSelector.SubsetSelectorModal locations={eligibleLocations} isReadOnly={isReadOnly} locationsSelector={locationSelect} selectedIds={selectedLocationsIds}>/</locationSelector.SubsetSelectorModal>
        </Card.Header>
        <Card.Body>
        
          <div className="card-group" style={customStyle.panelWithTiles}>
            {eligibleLocations.filter((loc:any) => {return selectedLocationsIds.includes(loc._id)} ).map( (location:any) => {
              return locationTile.Tile(location, undefined, undefined,
                addMode ? (id:any) =>
                {
                  return genericTile.ButtonDetachClick('Odeber', (e:any) => { e.stopPropagation(); removeLocation(location._id); } )
                } : undefined)
              })
            }
          </div>
        </Card.Body>
      </Card>
      
      <Card className="rootTile">
        <Card.Header><h2>Majitel je ochoten zapůjčit</h2></Card.Header>
        <Card.Body>
          <Form.Label>Nejméně na {minPeriod} dnů</Form.Label>
          {maxPeriod != undefined && <Form.Label>, nejvýše na {maxPeriod} dnů</Form.Label> }
        </Card.Body>
      </Card>

      <Card className="rootTile">
        <Card.Header><h2>Doba vypůjčení</h2></Card.Header>
        <Card.Body>
          <Form.Group controlId="howLong">
            <Form.Label>Počet dnů</Form.Label>
            <Form.Control type="input" disabled={isReadOnly}  value={period} onChange={(e:any) => setPeriod(e.target.value)} />
          </Form.Group>  
        </Card.Body>
      </Card>
      <br/>

      <Button size="lg" variant="secondary" style={customStyle.rigthSecondButtonStyle}  onClick={(e) => {navigate(-1);} } >Zpět</Button>
      {!isReadOnly && <Button size="lg" variant="success" style={customStyle.rigthButtonStyle} onClick={onSubmit}>Objednej</Button> }
      <RenderedCounter/>
    </Container>
  );
}
