import React from 'react';
import { Button, Form} from 'react-bootstrap';

import log from "common/Log";
import texts from "common/Texts";
import customStyle from 'common/Style';
import reports from "components/Helpers/Reports"

import { useCurrentUserState } from "models/CurrentUser";

import Mid from 'middlewares/Ask';

const ShowState: React.FC<{approveRequest:any, role:any, user:any}> = ({ approveRequest, role, user, children })=> {
  const currentUserState = useCurrentUserState()

  const [feedback, setFeedback] = React.useState<string|undefined>(undefined);
  const isSelf = (user._id == currentUserState.user._id)

  var title = ""
  if (!isSelf)
  {
    if (role != undefined && role !== "")
      title = " | " + role + ": "
    else
      title += " | od: "

    title += user.name;
  }
  title += " | v: "
  
  return (
    <>
      {approveRequest.approved && <span className='txt' style={{color:'green'}}>Schváleno </span>}
      {!approveRequest.approved && <span className='txt' style={{color:'red'}}>Odmítnuto </span>}
      <span className='txt'>{title} {texts.readableDateTime(approveRequest.timestamp)}</span>

      <Form.Group controlId="message">
        <Form.Label>Zpráva</Form.Label>
        <Form.Control as="textarea" rows={3} disabled={true}  value={approveRequest.message}/>
      </Form.Group>
    </>
  );
}

const Tile: React.FC<{approveRequestItitial:any, askId:any, role:any, user:any}> = ({ approveRequestItitial, role, askId, user, children })=> {
  const currentUserState = useCurrentUserState()
  const [approveRequest, setApproveRequest] = React.useState<any|undefined>(approveRequestItitial)

  const [message, setMessage] = React.useState<any>(approveRequestItitial != undefined ? approveRequestItitial.message : "");
  const [feedback, setFeedback] = React.useState<string|undefined>(undefined);
  const isSelf = (user._id == currentUserState.user._id)

  function errorService(functionId:string, errorArg:any) :void {
    setFeedback(log.decodeError('Approve', functionId, errorArg)); 
  }

  function okService() : void {
      setFeedback(""); 
  }

   const approveMessage = (approved:any) => {
    Mid.stamp({'userId': currentUserState.user._id, 'askId': askId, 'message':message, approved:approved}).then(
      (response:any) => {
        setApproveRequest(response.stamp)
        okService()
      },
      (error:any) => { errorService('ApproveRequest/stamp', error) }
    );
  };
  
  if (approveRequest != undefined)
     return (<ShowState approveRequest={approveRequest} role="" user={user} /> )

  if (!isSelf)
     return ( <b>Čeká na schválení</b> )

  return (
    <>
      <reports.ErrorModal feedback={feedback} setFeedback={setFeedback} />

      <Form.Group controlId="message">
        <Form.Label>Zpráva</Form.Label>
        <Form.Control as="textarea" rows={3} value={message} onChange={(e:any) => setMessage(e.target.value)} />
      </Form.Group>
      <br/>
      <Button size="lg" variant="secondary" style={customStyle.rigthSecondButtonStyle} onClick={(e)=> {approveMessage(false)}} >Omítnout</Button>
      <Button size="lg" variant="success" style={customStyle.rigthButtonStyle} onClick={(e)=> {approveMessage(true)}} >Schválit</Button>
    </>
  );
}

export default  { Tile, ShowState }