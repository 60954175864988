import React from 'react';

import { Button, Form, Card, Container } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom';

import log from "common/Log";
import { RenderedCounter } from 'common/Perf';
import validator from "common/Validator";
import customStyle from 'common/Style';

import { useCurrentUserState } from "models/CurrentUser";

import MiddItem from "middlewares/Item";
import MiddBid from "middlewares/Bid";

import itemTile from "components/Helpers/ItemTile";
import { ItemSubsetSelector } from "components/Helpers/ItemSubsetSelector";
import reports from "components/Helpers/Reports"

export const ProposalBid: React.FC<{isReadOnly:boolean}> = ({ isReadOnly, children })=> {
  const pageName = 'Nabízím ostatním'

  const { transactionId } = useParams();

  const currentUserState = useCurrentUserState()
  const addMode = transactionId == undefined;

  const [feedback, setFeedback] = React.useState<string|undefined>(undefined);
  const navigate = useNavigate()
  const isReadOnlyController = isReadOnly ? 'disabled' : ''

  const [eligibleToLend, setEligibleToLend] = React.useState([] as any);
  const [selectedToLend, setSelectedToLend] = React.useState([] as any);
  const [createTimestamp, setCreateTimestamp] = React.useState("");
  const [maxPeriod, setMaxPeriod] = React.useState<any|undefined>(undefined);
  const [minPeriod, setMinPeriod] = React.useState<any|undefined>(undefined);

  const functionLabel = addMode ? '' : (isReadOnly ? 'Zobrazení' : 'Editace')

  function errorService(functionId:string, errorArg:any) :void {
    setFeedback(log.decodeError(pageName, functionId, errorArg)); 
  }

  function okService() : void {
      setFeedback("");
  }

  function errorInField(fieldId:string, message:string, value:any) :void {
    log.errorOnUserInput("Item find", fieldId, message, value);
    setFeedback(validator.inputErrorRedable(fieldId, message, value)); 
  }

  React.useEffect(() => {
    if (addMode)
    {
      MiddItem.get_lend_candidates({'userId' :currentUserState.user._id}).then(
        (response:any) => {

          setEligibleToLend(response.items)  
        },
        (error:any) => { errorService('item/get_lend_candidates', error) })
    }
    else
    {
      MiddBid.get({'id' :transactionId}).then(
        (response:any) => {
          setMinPeriod(response.bid.minPeriod)
          setMaxPeriod(response.bid.maxPeriod)
          setCreateTimestamp(response.bid.createTimestamp)
          
          setSelectedToLend([response.item])
        },
        (error:any) => { errorService('bid/get', error) })
    }
  }, []);

  const onSubmit = (data:any): void => {
    if (selectedToLend.length == 0)
    {
      errorInField('Věc na půjčení', 'není vybraná', '')
      return
    }
   
    var minPeriodInt = 0
    var maxPeriodInt = 365

    if (minPeriod != undefined && minPeriod !== "")
    {
      minPeriodInt = parseInt(minPeriod)
      if (isNaN(minPeriodInt) || minPeriodInt < 1 )
      {
        errorInField('Na jak dlouho chcete minimálně půjčit', 'alespoň 1', minPeriodInt)
        return
      }
    }

    if (maxPeriod != undefined && maxPeriod !== "")
    {
      maxPeriodInt = parseInt(maxPeriod)
      if (isNaN(maxPeriodInt) || maxPeriodInt < 1 )
      {
        errorInField('Na jak dlouho chcete maximálně půjčit', 'alespoň 1', maxPeriodInt)
        return
      }
    }
    if (maxPeriodInt < minPeriodInt)
    {
      errorInField('Na jak dlouho chcete půjčit', 'max musí být větší než min', 'min=' + minPeriodInt + ', max=' + maxPeriodInt)
      return
    }

    // 'bid': selectedToBorrow
    // period
    const lendId = selectedToLend[0]._id

    const result = {'owner': currentUserState.user._id, 
      'item': lendId,
      'minPeriod' : minPeriod,
      'maxPeriod' : maxPeriod,
     }
     MiddBid.add(result).then((response)=>{
       okService()
       navigate(-1);
      },
      (errorArg:any) => {
        errorService('bid/add', errorArg) 
     });
  }

  return (
    <Container>
      <reports.ErrorModal feedback={feedback} setFeedback={setFeedback} />

      <h1>{pageName}</h1>
      <hr/>

      <Card className="rootTile">
        <Card.Header><h2>Nabízená věc</h2></Card.Header>
        <Card.Body>
          <ItemSubsetSelector items={eligibleToLend} isReadOnly={isReadOnly} itemSelector={(item:any) => { return setSelectedToLend([item]) }} >/</ItemSubsetSelector>

          { selectedToLend.map( (item:any) => {
              return itemTile.Tile(item, undefined, undefined, undefined, undefined )
            })
          }
        </Card.Body>
      </Card>

      <Card className="rootTile">
        <Card.Header><h2>Doba vypůjčení</h2></Card.Header>
        <Card.Body>
          <Form.Group>
            <Form.Label>Na jak dlouho minimálně</Form.Label>
            <Form.Control type="input" disabled={isReadOnly} value={minPeriod} onChange={(e:any) => setMinPeriod(e.target.value)}/>
            <Form.Text className="text-muted">
              Počet dnů
            </Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label>Na jak dlouho maximálně</Form.Label>
            <Form.Control type="input" disabled={isReadOnly} value={maxPeriod} onChange={(e:any) => setMaxPeriod(e.target.value)}/>
            <Form.Text className="text-muted">
              Počet dnů (doba může být překročena)
            </Form.Text>
          </Form.Group>
        </Card.Body>
      </Card>

      {isReadOnly &&
      <Card className="rootTile">
        <Card.Body>
          <Form.Group>
            <Form.Label>Datum/čas vytvoření</Form.Label>
            <Form.Control type="input" disabled={true} value={createTimestamp}/>
          </Form.Group>
        </Card.Body>
      </Card> }
      <br/>

      <Button size="lg" variant="secondary" style={customStyle.rigthSecondButtonStyle}  onClick={(e) => { navigate(-1); } } >Zpět</Button>
      {!isReadOnly && <Button size="lg" variant="success" style={customStyle.rigthButtonStyle}  onClick={onSubmit}>Přidat nabídku</Button> }
      <RenderedCounter/>
    </Container>
  );
}
