import React from 'react';

import { Button, Modal, OverlayTrigger, Tooltip, Card, Container, Alert } from 'react-bootstrap'
import { Link} from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import 'react-slideshow-image/dist/styles.css';

export const IntroJump: React.FC<{}> = ({ children })=> {
  const navigate = useNavigate()

  React.useEffect(() => {
      setTimeout(function() {
        window.location.replace('intro');
      }, 8000);
    }, []);

  return (
    <div>
      <Container>
        <Card className="rootTile">
          <Alert style={{fontSize:'150%'}} variant={'danger'}>Funkce je k dispozici pouze přihlášeným uživatelům!</Alert>
          <Card.Body>
            <h2>Zaregistrujte se prosím.</h2><br/>
            <br/>
            <h2>Budete přesměrování na úvodní obrazovku.</h2><br/>
            <br/>
            <Button variant="success" size="lg" active href="intro">Úvod</Button>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}
