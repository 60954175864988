const tokenInLocalName = ():string => {
  return "accessToken"
}

const authHeader = ():any => {
  var user = null;
  const accessToken = localStorage.getItem(tokenInLocalName())

  if (accessToken && accessToken !== "") {
    // for Node.js Express back-end
    return { 'x-access-token': JSON.parse(accessToken) };
  } else {
    return {};
  }
}


export default { authHeader, tokenInLocalName }

