import React from 'react';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Card, Button, Container, Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";

import DatePicker, { registerLocale }  from 'react-datepicker';

import log from "common/Log";
import validator from "common/Validator";
import customStyle from 'common/Style';

import locationTile from "components/Helpers/LocationTile";
import genericTile from "components/Helpers/GenericTile";
import locationSelectorTile from "components/Helpers/LocationSubsetSelector";
import cs from "date-fns/locale/cs";
import texts from "common/Texts";
import location from 'components/Location';
import reports from "components/Helpers/Reports"

export const Tile: React.FC<{timeIn:any|undefined, addClick:any|undefined, closeClick:any, okService:any|undefined, eligibleLocations:any, selectedLocationIdIn:any|undefined}> = ({ addClick, timeIn, eligibleLocations, selectedLocationIdIn, closeClick, children })=> {

    const [feedback, setFeedback] = React.useState<string|undefined>(undefined);

    const [time, setTime] = React.useState<Date|undefined>(undefined);
    const [selectedLocationId, setSelectedLocationId] = React.useState(selectedLocationIdIn);
    const [daysRemaining, setDaysRemaining]  = React.useState<any|undefined>();
    const [modalLocation, setModalLocation] = React.useState<any|undefined>();

    const isReadOnly = timeIn != undefined;

    function getMethods(obj:any)
    {
        var res:any[] = [];
        for(var m in obj) {
            if(typeof obj[m] == "function") {
              log.log("obj[m]" + m, obj[m])
            }
        }
        return res;
    }


    React.useEffect(() => {
      log.log("eligibleLocations", eligibleLocations)
      var nowTime = new Date();
      if (time != undefined)
      {
        var diff = time.getTime() - nowTime.getTime();
        var diffDays = Math.ceil(diff / (1000 * 3600 * 24)); 
        setDaysRemaining(diffDays)
      }
    }, [time]);

    React.useEffect(() => {
      registerLocale("cs", cs);

      var resTime:any;
      if (timeIn == undefined)
      {
        var tomorrow = new Date();
        tomorrow.setDate(new Date().getDate() + 1);

        resTime = tomorrow;
      }
      else
      {
        resTime = new Date(Date.parse(timeIn))
      }
      setTime(resTime)
    }, []);

    function errorInField(fieldId:string, message:string, value:any) :void {
      log.errorOnUserInput("Item find", fieldId, message, value);
      setFeedback(validator.inputErrorRedable(fieldId, message, value)); 
    }

    const locationSelect = (ids:any) => {
      setSelectedLocationId(ids)  }
  
    const removeLocation = (idx:number) => {
      setSelectedLocationId(undefined)
    };

    const onSubmit = (data:any): void => {
      if (selectedLocationId == undefined)
      {
        errorInField('Místo k vyzvednutí', 'musí být vybráno', '')
        return
      }
  
      var now = new Date();
      if (time == undefined || time < now)
      {
        errorInField('Čas schůzky', 'nelze naplánovat schůzku v minulosti', '')
        return
      }

      var entireLocation:any = eligibleLocations.find((loc:any) => { return loc._id == selectedLocationId })
      if (entireLocation == undefined)
      {
        errorInField('Místo k vyzvednutí', 'musí být vybráno ?', '')
        return
      }

      addClick({'location' : entireLocation,
        'time' : time, 'timestampKey': now.getTime()})
      closeClick()
    }

    var picker:any = {
      selected: time,
      onChange: (date:any) => setTime(date),
      locale: "cs",
      showTimeSelect: true,
      timeFormat: "p",
      timeIntervals: 15,
      dateFormat: "Pp",
    }

    if (isReadOnly)
      picker.readOnly = true

    return (
      <>
        <reports.ErrorModal feedback={feedback} setFeedback={setFeedback} />

        {modalLocation != undefined &&
          <Modal dialogClassName="item-find" show={true} >
            <Modal.Body>
              <location.Location locationId={modalLocation} isReadOnly={true} showModal={true}/>
            </Modal.Body>
            <Modal.Footer>
              <Button size="lg" variant="secondary" style={customStyle.buttonSecondStyle} onClick={(e:any)=> {
                  setModalLocation(undefined)
                  }}>
                    Zpět
              </Button>
            </Modal.Footer>
          </Modal> }

        <Card className="rootTile">
          <Card.Header><h2>Vyberte datum/čas</h2>
          </Card.Header>
          
          <Card.Body>
            <Container>
              <Row>
                <Col>
                  <DatePicker
                      {...picker}
                    />
                </Col>
                <Col>
                  <span className='txt' style={{color:'green'}}>Schůzka za {daysRemaining} dnů.</span>
                </Col>
              </Row>
            </Container>
          </Card.Body>
        </Card>

        {!isReadOnly && 
        <Card className="rootTile">
          <Card.Header><h2>Vyberte z možných míst k předání</h2></Card.Header>
          <Card.Body>
            <locationSelectorTile.SubsetSelector locations={eligibleLocations} locationsSelector={locationSelect} selectedIds={selectedLocationId != undefined ? [selectedLocationId] : []} setModalLocation={setModalLocation}>/</locationSelectorTile.SubsetSelector>
          </Card.Body>
        </Card> }

        <Card border="primary"  className="rootTile">
          <Card.Header><h2>Vybrané místo</h2></Card.Header>
          <Card.Body>
            <div className="card-group" style={customStyle.panelWithTiles}>
              {eligibleLocations.filter((loc:any) => {return selectedLocationId != undefined && selectedLocationId == loc._id} ).map( (location:any) => {
                log.log("location",location)
                return locationTile.Tile(location, undefined, 
                    () => {
                      setModalLocation(location._id)
                    },
                    (id:any) =>
                    {
                      return (
                        <>
                          {genericTile.ButtonSourceClick('Detaily', (e:any) => {
                              e.stopPropagation();
                              setModalLocation(location._id)
                            } )}

                          {!isReadOnly && genericTile.ButtonDetachClick('Zruš', (e:any) => { e.stopPropagation(); removeLocation(location._id); } )}
                        </>
                      )

                    })
                })
              }
            </div>
          </Card.Body>
        </Card>
        <br/>
        <Button size="lg" variant="secondary" style={customStyle.rigthSecondButtonStyle} onClick={closeClick}>Zpět</Button>
        {!isReadOnly && <Button size="lg" variant="success" style={customStyle.rigthButtonStyle}  onClick={onSubmit}>{texts.addName}</Button> }
      </>
    );
  }

  export default  { Tile }