import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Form, Nav, FormGroup } from 'react-bootstrap/';
import HelpIcon from '@material-ui/icons/Help';

import FlashMessage from "common/FlashMessage";
import customStyle from 'common/Style';

import { useControl, useCurrentUserState } from "models/CurrentUser";

export const Login: React.FC<{}> = ({ children })=> {
 
  const { login } = useControl()
  const user = useCurrentUserState()

  const [name, setName]  = React.useState("");
  const [password, setPassword]  = React.useState("");

  function onSubmit(e:any) {
    e.preventDefault();
    var data:any = {}
    data.name = name
    data.password = password
    login(data)
  }

  return (
      <Container>
      <Form onSubmit={onSubmit}  autoComplete="on">
        <h1>Přihlášení</h1><hr/>

        {(user.error != undefined || user.isLogged) &&
          <FlashMessage theme={user.isLogged ? 'success' : 'danger'}
              text={user.isLogged ? 'Přihlášení proběhlo úspěšně.' : user.error}
          />}

        <Form.Label>Uživatelské jméno</Form.Label><br/>
        <input type="text" style={{borderRadius: '3px', width: '30rem'}} name="username" id="username" value={name} onChange={(e:any) => { setName(e.target.value); }}/>
        <br/><br/>
        <Form.Label>Heslo</Form.Label><br/>
        <input type="password" style={{borderRadius: '3px', width: '30rem'}} name="password" id="password" value={password} onChange={(e:any) => { setPassword(e.target.value); } }/>
        <br/>
        <br/>
        <FormGroup role="form">
          <Button size="lg" type="submit" id="login" variant="success">Přihlásit</Button>
        </FormGroup>

        <hr/>      
        <Link to="/user/help" style={{float: 'right'}}>
            <HelpIcon style={{float: 'right', margin:'0.5rem'}}/>
        </Link>     
        <Nav.Link href="/user/reset_password" style={{float: 'right', marginLeft:'1rem'}} >Zapomenuté heslo</Nav.Link>
        <Nav.Link href="/user/create" style={{float: 'right', marginLeft:'1rem'}} >Vytvořit uživatele</Nav.Link>
        </Form>
    </Container>);
  }