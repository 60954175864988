import React from 'react';
import { Types } from "mongoose";
import genericTile from "./GenericTile";
import HomeIcon from '@material-ui/icons/Home';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import { Card, Badge, Tooltip, OverlayTrigger} from 'react-bootstrap';

const ButtonEdit = (id:any) =>
{
  return genericTile.ButtonEdit("/location/edit/" + id, 'Uprav')
}

const OnClickActionDefault = (location:any) => {
  const refSure:string = "/location/show/" + location._id
  window.location.assign(refSure);
}

const ButtonHome = () =>
{
  return (
    <>
      <OverlayTrigger placement="top"
        overlay={
          <Tooltip id="button-tooltip">
            <strong>Domovské místo</strong>
          </Tooltip>
        }
      >
        <HomeIcon style={{float: "right", color: "green"}}></HomeIcon>
      </OverlayTrigger>
    </>
  )
}

const ButtonHomeSet = (onClick:any) =>
{
  return (
    <>
      <OverlayTrigger placement="top"
        overlay={
          <Tooltip id="button-tooltip">
            <strong>Nastav jako domovské místo</strong>
          </Tooltip>
        }
      >
        <ArrowRightIcon style={{float: "right", color: "green"}} onClick={onClick}></ArrowRightIcon>
      </OverlayTrigger>
    </>
  )
}

const Tile = (location:any, legend:string|undefined, onClickAction:any|undefined, buttonInPlace:((id: Types.ObjectId) => React.ReactNode)|undefined) => {
  const nameKey:string = "location.show." + location._id

  return (
    <a key={nameKey} style={{ cursor: 'pointer' }} onClick={() => {
      if (onClickAction != undefined)
        onClickAction()
      else
        OnClickActionDefault(location)
      } } >
      {genericTile.Tile(location, legend, 'location', location.city, buttonInPlace)}
    </a>
  )
}

export default { Tile , ButtonEdit, OnClickActionDefault, ButtonHome, ButtonHomeSet }