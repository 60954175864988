import React from "react";

import 'App.css';

import { Routes, Route, Link, Navigate } from "react-router-dom";

import {Navbar, Nav, Dropdown, NavDropdown, Container, Offcanvas, ListGroup} from 'react-bootstrap';
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import EventNoteIcon from '@material-ui/icons/EventNote';
import Search from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import HomeIcon from '@material-ui/icons/Home';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HelpIcon from '@material-ui/icons/Help';
import SwapHorizontalCircleIcon from '@material-ui/icons/SwapHorizontalCircle';
import LoopIcon from '@material-ui/icons/Loop';
import ThreeSixtyIcon from '@material-ui/icons/ThreeSixty';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { User } from 'components/User';
import { Login } from 'components/Login';
import log from "common/Log";
import reports from "components/Helpers/Reports"

import { LocationControl } from 'components/LocationControl';
import { ItemControl } from 'components/ItemControl';
import { ItemFind } from 'components/ItemFind';
import { ProposalControl } from 'components/ProposalControl';
import { DeliveryControl } from 'components/DeliveryControl';
import { MessageControl } from 'components/MessageControl';
import { MeetControl } from 'components/MeetControl';
import remoteUser from 'components/RemoteUser';
import { Setting } from "components/Setting";
import { Unsubscribe } from "components/Unsubscribe";
import help from "components/Help";
import { Letter } from 'components/Letter';
import { Intro } from 'components/Intro';
import { IntroJump } from 'components/IntroJump';
import { Bookmark } from 'components/Bookmark';
import { BookmarkBack } from 'components/BookmarkBack';

import { Faq } from "components/Faq";
import { Conditions } from "components/Conditions";
import { Activation } from "components/Activation";
import { ChangePassword } from "components/ChangePassword";
import { ResetPassword } from "components/ResetPassword";
import location from 'components/Location';

import Message from "middlewares/Message";
import { useControl, useCurrentUserState } from "models/CurrentUser";
import { useNavigate } from 'react-router-dom';

export default function App() : React.ReactNode {

  const { logout } = useControl()
  const user = useCurrentUserState()
  const [statistics, setStatistics] = React.useState<any|undefined>();

  const [showMenu, setShowMenu] = React.useState(false);

  const handleClose = () => setShowMenu(false);
  const handleShow = () => setShowMenu(true);

  const [feedback, setFeedback] = React.useState<string|undefined>(undefined);
  const navigate = useNavigate() 

  function errorService(functionId:string, errorArg:any) :void {
    setFeedback(log.decodeError('MainPage', functionId, errorArg)); 
  }

  function okService() : void {
      setFeedback(""); 
  }

  const refPerformanceOutput = React.useRef<any|undefined>();

  let dropDownLinkStyle = {
    color: 'white'
  };

  const divStyle = {
    color: 'white',
    fontSize: '200%',
    lineHeight: 0.8,
    fontWeight: 900
  };

  const divStyleDisabled = {
    fontSize: '200%',
    lineHeight: 0.8,
    fontWeight: 900
  };

  const divMaxStyle = {
    color: 'white',
    fontSize: '300%',
    lineHeight: 0,
    fontWeight: 500
  };

  const iconMenuStyle = {
    fontSize: '100%',
  };

  const iconPopupMenuStyle = {
    fontSize: '150%',
  };

  React.useEffect(() => {
    if (user.isLogged)
    {
      Message.get_statistics({'id' :user.user._id}).then(
        (response:any) => {
          setStatistics(response);
        },
        (error:any) => { errorService('item/get_class', error) }
      );
    }
  }, []);

  if (window.location.pathname.endsWith("blank_area"))
  {
    const urlPath = window.location.pathname.split("/");
    log.log("urlPath", urlPath)
    if (urlPath.length > 4 && urlPath[urlPath.length - 3] == 'letter')
    {
      const locationId:any = urlPath[urlPath.length - 2]
      return ( <Letter locationId={locationId}/> )
    }

    if (urlPath.length > 2 && urlPath[urlPath.length - 3] == 'bookmark')
    {
      const locationId:any = urlPath[urlPath.length - 2]
      return (
          <Bookmark locationId={locationId}/>
        )
    }

    if (urlPath.length > 2 && urlPath[urlPath.length - 3] == 'bookmarkback')
    {
      const locationId:any = urlPath[urlPath.length - 2]
      return (
          <BookmarkBack/>
        )
    }
  }

  const onSelectFn = (eventKey: any, event: Object) => {
    log.log("XXX", eventKey)
    //event.stopPropagation()
  };

  return (
    <>
      <meta name="viewport" content="width=1280"/> 

      <Offcanvas placement="end" show={showMenu} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {user.isLogged && <>Přihlášen: {user.user.name}</>}
            {!user.isLogged && <>Nepřihlášený uživatel</>}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {!user.isLogged && <Dropdown.Item variant="success" href="/user/login">Přihlášení</Dropdown.Item>}
          {!user.isLogged && <Dropdown.Item variant="success" href="/user/initial_conditions">Vytvoření uživatele</Dropdown.Item>}

          {user.isLogged &&
            <>
            <Dropdown.Item href="/location">Moje místa <HomeIcon style={iconMenuStyle}/> </Dropdown.Item>
            <NavDropdown.Divider />
            <Dropdown.Item href="/user/edit">Osobní údaje</Dropdown.Item>
            <Dropdown.Item href="/user/statistics">Statistika</Dropdown.Item>
            <Dropdown.Item href="/user/settings">Nastavení</Dropdown.Item>
            <NavDropdown.Divider />
            <Dropdown.Item onClick={logout}>Odhlášení <ExitToAppIcon style={iconMenuStyle}/> </Dropdown.Item>
            </> }
          <NavDropdown.Divider />
          <Dropdown.Item href="/user/help">Nápověda <HelpIcon style={iconMenuStyle}/></Dropdown.Item>
        </Offcanvas.Body>
      </Offcanvas>

      <Navbar style={{ paddingTop: '0.2rem', marginBottom: '0.4rem' }} expand='xl' className="mb-3">
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
        
          <Nav className="me-auto">
            <Nav.Link style={divStyle} as={Link} to="/" title="Hledej věci"><Search style={iconMenuStyle}/></Nav.Link>
            <Nav.Link style={user.isLogged ? divStyle : divStyleDisabled} as={Link} to="/proposal" title="Nabídka poptávka"> <LoopIcon style={iconMenuStyle}/> </Nav.Link>
            <Nav.Link style={user.isLogged ? divStyle : divStyleDisabled} as={Link} to="/delivery" title="Příští doručení"><SendIcon style={iconMenuStyle}/></Nav.Link> 
            <Nav.Link style={user.isLogged ? divStyle : divStyleDisabled} as={Link} title="Přehled věcí" active={user.isLogged} to="/item"><BusinessCenterIcon style={iconMenuStyle}/> </Nav.Link>
            <Nav.Link style={user.isLogged ? divStyle : divStyleDisabled} as={Link} title="Příští schůzky" to="/meet"><EventNoteIcon style={iconMenuStyle}/></Nav.Link>
            <Nav.Link style={user.isLogged ? divStyle : divStyleDisabled} as={Link} title="Zprávy" to="/message"><MailOutlineIcon style={iconMenuStyle}/></Nav.Link>
          </Nav>

          <form className="d-flex">
          <Nav.Link style={divMaxStyle} title="Účet" onClick={handleShow} ><PermIdentityOutlinedIcon style={iconMenuStyle}/></Nav.Link>
          </form>
        </Navbar.Collapse>
      </Navbar>

      <reports.ErrorTile feedback={feedback}/>

      <Routes>
        <Route path="/faq" element={<Faq />} />

        <Route path="/user/create" element={<User isAddMode={true} />}/>

        <Route path="/user/change_password" element={<ChangePassword/>} />

        <Route path="/user/reset_password" element={<ResetPassword/>} />

        <Route path="/user/activate/:userId" element={<Activation/>} />

        <Route path="/user/settings" element={<Setting />} />

        <Route path="/user/unsubscribe/:settingId" element={<Unsubscribe/>} />

        <Route path="/user/help" element={<help.Help />} />

        <Route path="/user/conditions" element={<Conditions agreement={false}/>} />

        <Route path="/user/initial_conditions" element={<Conditions agreement={true}/>} />

        <Route path="/location/show/:locationId" element={<location.LocationRouted isReadOnly={true} showModal={false}/>} />

        <Route path="/user/login" element={<Login/>} />

        <Route path="/item/borrow/find" element={<ItemFind/>} />

        <Route path="/item/*" element={<ItemControl/>} />

        <Route path="/intro" element={<Intro/>} />
        <Route path="/intro_jump" element={<IntroJump/>} />

        {user.isLogged && <Route path="/">

          <Route path="/location/*" element={<LocationControl/>} />

          <Route path="/proposal/*" element={<ProposalControl/>} />

          <Route path="/delivery/*" element={<DeliveryControl/>} />

          <Route path="/message/*" element={<MessageControl/>} />

          <Route path="/meet/*" element={<MeetControl/>} />

          <Route path="/user/edit" element={<User isAddMode={false}/>} />

          <Route path="/user/show/:userId" element={<remoteUser.UserRouted/>} />

          <Route path="/user/statistics" element={<remoteUser.User userId={user.user._id}/>} />

          <Route path="/" element={<ItemFind/>} />
        </Route> }

        <Route path="/" element={<ItemFind/>} />

        <Route
          path="*"
          element={<Navigate replace to="/intro_jump" />}
        />

      </Routes>
    </>
  )
}
