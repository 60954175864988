import React from 'react';
import { Container, Card } from 'react-bootstrap'

import log from "common/Log";
import FlashMessage from "common/FlashMessage";

import AuthService from "middlewares/User";

import { useParams } from "react-router-dom";

export const Activation: React.FC = ({ children })=> {
  const pageName = 'Aktivace'

  const { userId } = useParams();

  const [feedback, setFeedback] = React.useState<string|undefined>(undefined);

  function errorService(functionId:string, errorArg:any) :void {
    setFeedback(log.decodeError(pageName, functionId, errorArg)); 
  }

  React.useEffect(() => {
    AuthService.activate({'id': userId}).then(
      (response:any) => {
        setFeedback("")
      },
      (error:any) => { errorService('user/activate', error) }
    );
  }, []);

  if (feedback != undefined && feedback !== "")
  {
    return (
      <>      
        <h2>Aktivace selhala</h2>
        <FlashMessage theme={'danger'}
            text={feedback}/> 
      </>)  
  }

  return (
    <Container>
      <Card className="rootTile">
        <Card.Header><h2>Aktivace Vašeho účtu proběhla úspěšně.</h2></Card.Header>
        <Card.Body>
          <h2 style={{ color: 'DarkGreen' }}>Vítejte v platformě: prohod.cz</h2><br/>
          <br/>
          <h2>Pokračujte přihlášením.</h2><br/>
        </Card.Body>
      </Card>
    </Container>
  );
}
