import React from 'react';
import manual from './Manual';
import Form from 'react-bootstrap/Form'
import log from "common/Log";
import customStyle from 'common/Style';

export const Routing: React.FC<{initEnhancement:any, readOnly:Boolean, dataCallback:any}> = ({ initEnhancement, readOnly, children, dataCallback })=> {

  const initEnhancementSafe:any = initEnhancement != undefined ? initEnhancement : {}
  const components:any[] = [manual.getHandle()]
  const [enhancements, setEnhancements] = React.useState(initEnhancementSafe);

  React.useEffect(() => {
    dataCallback(enhancements)
  }, [enhancements]);

  return (<>
    {components.map( (i:any, index:number) => {
    return (
      <div key={i.getStaticKey()}>
        {!readOnly && 
          <Form.Group controlId={i.getStaticKey()} style={customStyle.groupStyle}>
            <Form.Check key={i.getStaticKey()} type="checkbox" defaultChecked={i.isContained(initEnhancementSafe)} name="textFilter" 
                onClick={(e: any) => {
                  setEnhancements(i.initInstance({ ...enhancements }, e.target.checked))
                } } label={i.getStaticName()} />
          </Form.Group> }

        {readOnly && i.isContained(initEnhancementSafe) &&
          <Form.Label key={i.getStaticKey()}>{i.getStaticName()}</Form.Label> }

        {i.isContained(enhancements) && i.decoder(enhancements, readOnly, (value:any) => {
              var ev = enhancements;
              if (ev[i.getStaticKey()] != undefined)
              {
                ev[i.getStaticKey()] = value
                setEnhancements(ev)
              }
            }) }
      </div>
    )})}
  </>)
}

export default { Routing }