import React from 'react';

import { Button, Modal, OverlayTrigger, Tooltip, Card, Container, Row, Col, ListGroup } from 'react-bootstrap'
import { Link} from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import customStyle from 'common/Style';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import help from "components/Help";
import ShortTextIcon from '@material-ui/icons/ShortText';
import HelpIcon from '@material-ui/icons/Help';
import "react-slideshow-image/dist/styles.css";

export const Intro: React.FC<{}> = ({ children })=> {
  const navigate = useNavigate()

  const zoomOutProperties = {
    transitionDuration: 100,
    infinite: true,
    indicators: true,
    scale: 2,
    arrows: true,
    autoplay: false
  };

  const [modalShow, setModalShow] = React.useState<any|undefined>();

  const handleKeyPress = (target:any) => {
    if (target.key === 'Escape') {
      setModalShow(undefined)
    }
  };

  React.useEffect(() => {
    window.addEventListener('keydown', handleKeyPress)
  }, []);

  return (
    <Container>
      {modalShow != undefined &&
        <Modal dialogClassName="meet-show" show={true} size="lg">
          <Modal.Body>
            {modalShow}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" size="lg" onClick={(e:any)=> {
                setModalShow(undefined)
                }}>
                  Zpět
            </Button>
          </Modal.Footer>
        </Modal> }

      <Slide {...zoomOutProperties}>

        <div className="each-slide-effect">
          <Container>
            <Card className="rootTile">
              <Card.Header>
                <h1>Krok 1 - Vítejte ve světě půjčování věcí online</h1>
              </Card.Header>

              <Card.Body>
                <Row>
                  <Col sm={8}>
                  <h2>Krátké shrnutí jak fungujeme</h2>
                  <h4>
                  <ul>
                      <li>Půjčení probíhá nejjednodušším způsobem, bez kreditu.</li>
                      <li>Můžete si zde půjčovat věci bez poplatků, aby to fungovalo tak zase vy současně půjčujete ostatním.</li>
                      <li>Dostupnost a rozmanitost věcí k půjčení zajišťují uživatelé platformy.</li>
                      <li>Věci si půjčujete kde, kdy a na jak dlouho se dohodnete s majitelem.</li>
                      <li>Půjčené  věci slouží jako zástava pro vypůjčení jiných věcí.</li>
                  </ul>
                  </h4>
                  </Col>

                  <Col sm={4}>
                    <Card.Img variant="top" style={{ objectFit: 'cover'}} src='https://prohodto-images.s3.eu-central-1.amazonaws.com/help/delivery.jpg' />
                  </Col>
                </Row>

                <Button variant="success" size="lg" active href="/user/create">Vytvoření uživatele</Button>
              </Card.Body>
            </Card>
          </Container>
        </div>

        <div className="each-slide-effect">
          <Container>
              <h1>Příklad půjčení (1)</h1>
              <h4>
              <div className="card-group" style={customStyle.panelWithTiles}>
                <Card className="rootTile">
                  <Card.Body>
                    <ul>
                      <li>Paní Alice dříve půjčila sekačku jinému uživateli sama si potřebuje půjčit vrtačku od Ondřeje.</li>
                      <li>Použije sekačku jako zástavu pro půjčení vrtačky.</li>
                    </ul>
                    <Card.Img variant="top" style={{ objectFit: 'cover'}} src='https://prohodto-images.s3.eu-central-1.amazonaws.com/help/cp1.jpg' />
                  </Card.Body>
                </Card>
                <Card className="rootTile">
                  <Card.Body>
                    Ondřej předal vrtačku Alici a nyní ji použije jako zástavu pro půjčení knihy od Pavla.
                    <Card.Img variant="top" style={{ objectFit: 'cover'}} src='https://prohodto-images.s3.eu-central-1.amazonaws.com/help/cp2.jpg' />
                  </Card.Body>
                </Card>
              </div>
              </h4>
          </Container>
        </div>

        <div className="each-slide-effect">
          <Container>
              <h1>Příklad půjčení (2)</h1>
              <h4>

              <div className="card-group" style={customStyle.panelWithTiles}>
                <Card className="rootTile">
                  <Card.Body>
                  <b>Výsledek:</b> Alice má půjčenou vrtačku, Ondřej má půjčenou knihu, Pavel má možnost si půjčit a jako zástavu uvést knihu půjčenou Ondřejovi.
                    <Card.Img variant="top" style={{ objectFit: 'cover'}} src='https://prohodto-images.s3.eu-central-1.amazonaws.com/help/cp3.jpg' />
                  </Card.Body>
                </Card>
              </div>
              </h4>
          </Container>
        </div>

        <div className="each-slide-effect">
          <Container>
            <Card className="rootTile">
              <Card.Header>
              <h1>Krok 2</h1>
              </Card.Header>
              <Card.Body>
                <h4>
                Nastavíte si místa, kde si přejete vyzvedávat věci. Pokud žádné vhodné  nenajdete vytvoříte si místo nové.<br/>
                <br/>
                </h4>
                <OverlayTrigger placement="top"
                    overlay={
                      <Tooltip id="button-tooltip">
                        <strong>Více informací</strong>
                      </Tooltip>
                    }
                  >
                    <HelpIcon onClick={(e:any) => setModalShow(help.showPlace)} fontSize="large"/>
                </OverlayTrigger>
                <br/>
                <br/>
                <Button variant="success" size="lg" active href="/location/attach">Připoj místo</Button>
              </Card.Body>
            </Card>
          </Container>
        </div>

        <div className="each-slide-effect">
          <Container>
            <Card className="rootTile">
              <Card.Header>
                <h1>Krok 3</h1>
              </Card.Header>
              <Card.Body>
                <h4>Nafotíte a online vystavíte věci, které plánujete půjčit ostatním.<br/>
                <br/></h4>
                <OverlayTrigger placement="top"
                    overlay={
                      <Tooltip id="button-tooltip">
                        <strong>Více informací</strong>
                      </Tooltip>
                    }
                  >
                    <HelpIcon onClick={(e:any) => setModalShow(help.itemInsert)} fontSize="large"/>
                </OverlayTrigger>
                <br/>
                <br/>
                <Button variant="success" size="lg" active href="/item/create">Vlož věc</Button>
              </Card.Body>
            </Card>
          </Container>
        </div>

        <div className="each-slide-effect">
          <Container>
            <Card className="rootTile">
              <Card.Header>
              <h1>Krok 4</h1>
              </Card.Header>

              <Card.Body>
                <h4>
                Můžete si půjčovat věci ostatních.<br/>
                <br/></h4>
                <OverlayTrigger placement="top"
                    overlay={
                      <Tooltip id="button-tooltip">
                        <strong>Více informací</strong>
                      </Tooltip>
                    }
                  >
                    <HelpIcon onClick={(e:any) => setModalShow(help.lendItem)} fontSize="large"/>
                </OverlayTrigger>
                <br/>
                <br/>
                <Button variant="success" size="lg" active href="/">Hledej věci</Button>
              </Card.Body>
            </Card>
          </Container>
        </div>

      </Slide>
    </Container>
  );
}
