
import React from 'react';

import { Card, Button, Form, Container, Row } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom';

import log from "common/Log";
import { RenderedCounter } from 'common/Perf';
import customStyle from 'common/Style';

import { useCurrentUserState } from "models/CurrentUser";

import MiddAsk from "middlewares/Ask";

import approveTile from "components/Helpers/ApproveRequestTile";
import userTile from "components/Helpers/UserTile";
import itemTile from "components/Helpers/ItemTile";
import locationTile from "components/Helpers/LocationTile";
import texts from "common/Texts";
import reports from "components/Helpers/Reports"

export const AskApproveRequest: React.FC<{isReadOnly:boolean}> = ({ isReadOnly, children })=> {
  const pageName = 'Žádost o zapůjčení'

  const { askId } = useParams();
  const navigate = useNavigate()

  const currentUserState = useCurrentUserState()
  const [ask, setAsk] = React.useState<any>(null);

  const [feedback, setFeedback] = React.useState<string|undefined>(undefined);

  function errorService(functionId:string, errorArg:any) :void {
    setFeedback(log.decodeError(pageName, functionId, errorArg)); 
  }

  function okService() : void {
      setFeedback("");
  }

  React.useEffect(() => {
    MiddAsk.get({'id' :askId}).then(
      (response:any) => {
        setAsk(response.ask)
      },
      (error:any) => { errorService('ask/get', error) }
    );
  }, []);

  if (ask == null)
  {
    return ( <reports.LoadingTile feedback={feedback} setFeedback={setFeedback}/> )
  }

  return (
    <Container>
      
      <h1>{pageName}</h1>
      <hr/>

      <Container>
        <Row style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
          <Card className="rootTile" style={{marginRight:'0.3rem', padding:'0', width: '18rem'}}>
            <Card.Header><h2>Věc k vypůjčení</h2></Card.Header>
            <Card.Body>
              {itemTile.Tile(ask.item, undefined, undefined, undefined, undefined)}
            </Card.Body>
          </Card>
          <Card className="rootTile"  style={{marginRight:'0.3rem', padding:'0', width: '18rem'}}>
            <Card.Header><h2>Pro uživatele</h2></Card.Header>
            <Card.Body>
              {(ask.receiver !== undefined) && userTile.Tile(ask.receiver, undefined, undefined, undefined)}
              {(ask.receiver === undefined) && <h2>Čeká na zpracování</h2>}
            </Card.Body>
          </Card>

          {ask.mortageItem != undefined && 
            <Card className="rootTile"  style={{marginRight:'0.3rem', padding:'0', width: '18rem'}}>
              <Card.Header><h2>Do zástavy nabízeno</h2></Card.Header>
              <Card.Body>
                {itemTile.Tile(ask.mortageItem, undefined, undefined, undefined, undefined)}
              </Card.Body>
            </Card>}
        </Row>
      </Container>

      <Card className="rootTile" style={{padding:'0'}}>
        <Card.Header><h2>Místa pro vyzvednutí</h2>
        </Card.Header>
        <Card.Body>
          <div className="card-group" style={customStyle.panelWithTiles}>
            {ask.locations.map( (location:any) => {
                return locationTile.Tile(location, undefined, undefined, undefined)
              })
            }
          </div>
        </Card.Body>
      </Card>

      <Card className="rootTile">
        <Card.Header><h2>Doba vypůjčení</h2></Card.Header>
        <Card.Body>
          <Form.Group controlId="howLong">
            <Form.Control type="input" disabled={isReadOnly}  defaultValue={ask.period} />
            <Form.Text className="text-muted">
              Počet dnů
            </Form.Text>
          </Form.Group>  
        </Card.Body>
        </Card>

      {(ask.receiver != undefined) &&
        <Card className="rootTile">
          <Card.Header><h2>Schválení majitele</h2></Card.Header>
          <Card.Body>
            <approveTile.Tile approveRequestItitial={ask.receiverStamp} askId={ask._id} user={ask.receiver} role=""/>
          </Card.Body>
        </Card>
      }

      {(ask.holder != undefined) &&
        <Card className="rootTile">
          <Card.Header><h2>Schválení držitele záruky</h2></Card.Header>
          <Card.Body>
            <approveTile.Tile approveRequestItitial={ask.holderStamp} askId={ask._id} role="" user={ask.holder}/>
          </Card.Body>
        </Card>
      }

      {(ask.stamp != undefined) &&
        <Card className="rootTile">
          <Card.Header><h2>Výsledek</h2></Card.Header>
          <Card.Body>
            <approveTile.Tile approveRequestItitial={ask.stamp} askId={ask._id} role="" user={ask.stamp.owner}/>
          </Card.Body>
        </Card>
      }
      <br/>

      <reports.ErrorModal feedback={feedback} setFeedback={setFeedback} />

      <Button size="lg" variant="secondary" style={customStyle.rigthSecondButtonStyle} onClick={(e) => {navigate(-1);} } >Zpět</Button>
      <RenderedCounter/>
    </Container>
  );
}
