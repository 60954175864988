import React from 'react';

import { Routes, Route } from "react-router-dom";

import location from 'components/Location';

import { LocationAttach } from 'components/LocationAttach';
import { LocationOverview } from 'components/LocationOverview';

export const LocationControl: React.FC<{}> = ({ children })=> {
   return (
    <div>
      <Routes>
        <Route path="/" element={<LocationOverview/>}/>

        <Route path="create" element={<location.LocationRouted isReadOnly={false} showModal={false}/>}/>

        <Route path="attach" element={<LocationAttach/>}/>

        <Route path="edit/:locationId" element={<location.LocationRouted isReadOnly={false} showModal={false}/>}/>
      </Routes>
    </div>
  );
}
