import log from "./Log";

const isValidRef = (elementRef:any) =>
{
  if (elementRef == undefined || elementRef == null)
    return false;

  if (elementRef.current == undefined || elementRef == null)
    return false;
  
  return true
}

const undefinedToNull = (elementRef:any) =>
{
  if (elementRef == undefined )
    return null;
 
  return elementRef
}

const isValid = (...args:any) =>
{
  var result = true
  args.forEach((arg:any) => {
    if (arg === undefined)
    {
      result = false
    }
  })
  return result
}

function inputErrorRedable(fieldId:string, message:string, value:any) :string {
  const result = "políčko '" + fieldId + "' " + message
  if (value !== "")
    return  result + " zadaná hodnota: " + value; 

  return result; 
}

export default {isValidRef, inputErrorRedable, isValid, undefinedToNull}

