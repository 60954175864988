import React from 'react';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Card, Button, Form, Container, Image } from 'react-bootstrap'
import { Link, useParams, useNavigate } from 'react-router-dom';

import image from "global/Image";
import FlashMessage from "common/FlashMessage";
import texts from "common/Texts";
import customStyle from 'common/Style';
import log from "common/Log";

import { useCurrentUserState } from "models/CurrentUser";
import locationTile from "components/Helpers/LocationTile";
import itemTile from "components/Helpers/ItemTile";
import reports from "components/Helpers/Reports"
import { SendMessageTile } from "components/Helpers/SendMessageTile";

import Middleware from "middlewares/User";
import MiddMessage from "middlewares/Message";

export const User: React.FC<{userId:any}> = ({ userId, children })=> {
    const currentUserState = useCurrentUserState()
    const pageName = 'Uživatel'

    const [user, setUser] = React.useState<any|undefined>();
    const [feedback, setFeedback] = React.useState<string|undefined>(undefined);

    const [imageBody, setImageBody] = React.useState<any|undefined>()
    const [message, setMessage] = React.useState("")

    const navigate = useNavigate()

    function errorService(functionId:string, errorArg:any) :void {
      setFeedback(log.decodeError(pageName, functionId, errorArg)); 
    }
  
    function okService() : void {
        setFeedback(""); 
    }

    React.useEffect(() => {
      Middleware.get_remote({'id' :userId}).then(
        (response:any) => {
          setImageBody(image.getUrlFromImageModel(response.user.profileImage))
          setUser(response.user)
        },
        (error:any) => { errorService('location/get', error) }
      );
      
    }, []);

    const sendMessage = () => {
      MiddMessage.add({'receiver': userId, 'sender': currentUserState.user._id, 'message':message}).then(
        (response:any) => {
          okService()
        },
        (error:any) => { errorService('message/add', error) }
      );
            
    }   

    if (user == null)
    {
      return ( <reports.LoadingTile feedback={feedback} setFeedback={setFeedback}/> )
    }

    return (
      <Container>
        <h1>{pageName}: {user.name}</h1>
        <hr/>

        <Container>
          <Row>
              <Col style={{float: "right"}} >
                <Image id="profileImage" src={imageBody} style={{ height: 150, width: 150, float: "right"}} />
              </Col>
          </Row>
        </Container>
        <hr/>
        {(feedback != undefined) &&
            <FlashMessage theme={feedback == "" ? 'success' : 'danger'}
                text={feedback == "" ? 'Proběhlo úspěšně.' : 'Chyba: ' + feedback}
        /> }

        <Form.Group>
          <Form.Label>Založen</Form.Label>
          <Form.Control type="input" disabled={true} value={texts.readableDateTime(user.createTimestamp)} />
        </Form.Group> 

        <Card className="rootTile">
          <Card.Header><h2>Statistika</h2>
          </Card.Header>
          <Card.Body>
            <p className="txt" >Půjčil ostatním {user.statistics.borowed} krát</p>
            <p className="txt" >Půjčil si {user.statistics.borowed} krát</p>
          </Card.Body>

          { user.delayedItems.length > 0 && 
          <>
            <Card.Footer><h2>Věci nevrácené včas</h2>
              <Link to="/item/create" style={{float: 'right'}}>
              </Link>
            </Card.Footer>
            <Card.Body>
              <div className="card-group" style={customStyle.panelWithTiles}>
                {user.delayedItems.map( (i:any, index:number) => {
                  const htmlText = () => {
                    return (<span className='txt' style={{color:'red'}}>Zpožděno {i.delayedFor} dnů</span>)
                  };

                  return itemTile.Tile({_id:i.item._id, name:i.item.name}, 'Věc:', undefined, htmlText(), undefined)
                })
                }
              </div>
            </Card.Body>
          </>
          }
        </Card>

        <Form.Group>
          <Form.Label>O uživateli</Form.Label>
          <Form.Control as="textarea" rows={6} disabled={true}  value={user.intro} />
        </Form.Group> 

        <Form.Group>
          <Form.Label>Věk {user.ageMin}-{user.ageMax}</Form.Label>
        </Form.Group> 

        <Card className="rootTile">
          <Card.Header><h2>Přípojená místa uživatele</h2>
          </Card.Header>
          <Card.Body>
            <div className="card-group" style={customStyle.panelWithTiles}>
              {user.sharedLocations.map( (location:any) => {
                  return locationTile.Tile(location, undefined, undefined, undefined)
                })
              }
            </div>
          </Card.Body>
        </Card>
        <hr/>
        <Button size="lg" variant="secondary" style={customStyle.rigthSecondButtonStyle} onClick={(e) => {navigate(-1);} } >Zpět</Button>
        { currentUserState.user._id != user._id && <SendMessageTile label='Zpráva uživateli' userId={user._id} initialText="" onErrorService={errorService}/> }
      </Container>
    );
  }

  const UserRouted: React.FC = ({ children })=> {
    const { userId } = useParams();

    return (<User userId={userId}/>)
  }

  export default  { User, UserRouted }