import React from 'react';

import { Card, Container} from 'react-bootstrap';

import log from "common/Log";
import { RenderedCounter } from 'common/Perf';
import customStyle from 'common/Style';

import { useCurrentUserState } from "models/CurrentUser";

import MidDelivery from 'middlewares/Delivery';

import itemTile from "components/Helpers/ItemTile";
import genericTile from "components/Helpers/GenericTile";
import reports from "components/Helpers/Reports"

export const DeliveryOverview: React.FC<{}> = ({ children })=> {
  const pageName = 'Doručení';

  const currentUserState = useCurrentUserState()

  const [deliveries, setDeliveries] = React.useState([] as any);

  const [feedback, setFeedback] = React.useState<string|undefined>(undefined);

  function errorService(functionId:string, errorArg:any) :void {
    setFeedback(log.decodeError(pageName, functionId, errorArg)); 
  }

  function okService() : void {
      setFeedback(""); 
  }

  React.useEffect(() => {
    MidDelivery.get_many({'id' :currentUserState.user._id}).then(
      (response:any) => {
        setDeliveries(response.deliveries)
      },
      (error:any) => { errorService('delivery/get_many', error) }
    );
  }, []);

  return (
    <Container fluid='xl'>
      <reports.ErrorTile feedback={feedback}/>

      <Card className="rootTile">
        <Card.Header><h1>Čekající doručení</h1></Card.Header>
        <Card.Body>
          <div className="card-group" style={customStyle.panelWithTiles}>
          {deliveries.map( (i:any, index:number) => {
              return itemTile.Tile(i.item, undefined, 
                () => {
                  const refSure:string = "/delivery/show/" + i._id
                  window.location.assign(refSure);
                },
                undefined,
                (id:any) =>
                {
                  return (
                    <>
                      {genericTile.ButtonDelete("/delivery/delete/" + i._id, 'Smaž')}
                    </>)
                })
              })
            }
          </div>
        </Card.Body>
      </Card>

      <RenderedCounter/>
    </Container>
  );
}
