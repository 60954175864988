import React from 'react';

import { Types } from "mongoose";
import 'react-dropdown-tree-select/dist/styles.css'
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Form, Container, Card } from 'react-bootstrap'
import DropdownTreeSelect from "react-dropdown-tree-select";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import FlashMessage from "common/FlashMessage";
import validator from "common/Validator";
import log from "common/Log";
import { RenderedCounter } from 'common/Perf';
import texts from "common/Texts";
import customStyle from 'common/Style';
import image from "global/Image";

import { useCurrentUserState } from "models/CurrentUser";

import Midd from "middlewares/Item";
import MiddCat from "middlewares/Category";

import { ProfileImage } from "./ProfileImage";
import { ManyImages } from "components/ManyImages"
import userTile from "components/Helpers/UserTile";
import itemTile from "components/Helpers/ItemTile";
import reports from "components/Helpers/Reports"
import kind from "components/Templates/Kind/Routing"
import enhancement from "components/Templates/Enhancement/Routing"

// isReadOnly:boolean, showModal:boolean
const Item: React.FC<{itemId:any|undefined, mode:string, showModal:boolean}> = ({ itemId, mode, showModal, children })=> {
  const currentUserState = useCurrentUserState()
  const currentUser = currentUserState.isLogged ? currentUserState.user : currentUserState.questUser;

  const addMode = itemId == undefined;
  const editMode = mode == 'edit';
  const deleteMode = mode == 'delete';
  const isReadOnly = mode == 'ro' || mode == 'delete';
  const navigate = useNavigate()

  const [item, setItem] = React.useState<any|undefined>(undefined);

  const [itemName,  setItemName] = React.useState("");
  const [worthless, setWorthless] = React.useState<boolean>(false);
  const [description, setDescription] = React.useState("");

  const [images, setImages] = React.useState([] as any);
  const [owner, setOwner] = React.useState<any|undefined>(undefined);
  const [borower, setBorower] = React.useState<any|undefined>(undefined);
  const [mortageItemFor, setMortageItemFor] = React.useState<any|undefined>(undefined);

  const [kindControl, setKindControl] = React.useState<any>({});
  const [enhancementsData, setEnhancementsData] = React.useState<any|undefined>(undefined);

  const pageName = texts.pageNamePrefixFromMode(mode) + 'věci'

  // catogory raw from database
  const [modelCategories, setModelCategories] = React.useState([] as any);
  
  const [selectedCategoriesIds, setSelectedCategoriesIds] = React.useState([] as any);
  const [visualCategories, setVisualCategories] = React.useState([] as any);

  const [feedback, setFeedback] = React.useState<string|undefined>(undefined);
  const [worthLessWarn, setWorthLessWarn] = React.useState(false);

  const [imageName, setImageName] = React.useState(addMode ? 'drill.png' : undefined)

  function errorService(functionId:string, errorArg:any) :void {
    setFeedback(log.decodeError(pageName, functionId, errorArg)); 
  }

  function errorInField(fieldId:string, message:string, value:any) :void {
    log.errorOnUserInput("Item", fieldId, message, value);
    setFeedback(validator.inputErrorRedable(fieldId, message, value)); 
  }

  function okService() : void {
      setFeedback(""); 
  }

  function propagateCategories(selectedCategoriesIds:any [])
  {
    var categoriesNew = modelCategories.map((category:any) => {
      var tagLabel = ""
      var categoryOwner:any = category
      while (categoryOwner != undefined)
      {
        if (tagLabel.length != 0)
          tagLabel = '\\' + tagLabel

        tagLabel = categoryOwner.name + tagLabel;
        categoryOwner = categoryOwner.owner
      }
      return {label: category.name, tagLabel:tagLabel, checked:selectedCategoriesIds.includes(category._id, 0), key:category}
    })

    categoriesNew.forEach((category:any) => 
    {
      category.children = categoriesNew.filter((categoryInner:any) => 
        {
          const result = categoryInner.key.owner != undefined && categoryInner.key.owner._id == category.key._id 
          return result
        } )
    })
    setVisualCategories(categoriesNew.filter((category:any) => { return (category.key.owner == undefined) } ))
  }

  function getEntireCategoriesIds(selectedCategoriesIds:Types.ObjectId[]):Types.ObjectId[]
  {
    var selectedCategoriesFinal:Types.ObjectId[] = selectedCategoriesIds
    var selectedCategoriesLocal = selectedCategoriesIds

    do
    {
      var selectedCategoriesNext = modelCategories.filter((category:any) => {
        return category.owner != undefined && selectedCategoriesLocal.includes(category._id)}).map((categoryLoc:any) => {
            return categoryLoc.owner})
      selectedCategoriesLocal = selectedCategoriesNext
      selectedCategoriesFinal = selectedCategoriesFinal.concat(selectedCategoriesNext)
    }
    while(selectedCategoriesLocal.length != 0);
    
    const res = selectedCategoriesFinal.filter((v:any, i:number, a:Types.ObjectId[]) => a.indexOf(v) === i)
    log.log("Final selected categories", res)

    return res; 
  }

  const modelChange = (model:any) => {
    setWorthLessWarn(model.worthless != undefined && model.worthless)
  }

  React.useEffect(() => {
    if (editMode || deleteMode || isReadOnly)
    {
      Midd.get({'id' :itemId}).then(
        (response) => {
          const remote = response.item.owner._id != currentUser._id

          setImages(response.item.images)
          setItem(response.item)
          
          setItemName(response.item.name);
          setWorthless(response.item.worthless);
          setDescription(response.item.description)

          setKindControl({'instance': response.item.kind })
          setEnhancementsData(response.item.enhancements != undefined ? response.item.enhancements : {})
          setSelectedCategoriesIds(response.item.categories)
          setImageName(response.item.profileImage)
          setOwner(remote ? response.item.owner : null)
          setBorower(response.item.bound != undefined ? (response.item.bound.owner._id != currentUser._id ? response.item.bound.owner : null ) : null)
          if (!remote)
            setMortageItemFor(response.item.mortage != undefined ? response.item.mortage.itemBounded : null)
          else
            setMortageItemFor(null)

          modelChange(response.item)
        },
        (error:any) => { errorService('item/get', error) }
      );
    }

    MiddCat.get_all().then(
      (response:any) => {
        setModelCategories(response.categories)
      },
      (error:any) => { errorService('categories/get_all_spec', error) }
    );
  }, [itemId]);

  React.useEffect(() => {
    propagateCategories(selectedCategoriesIds)
  }, [modelCategories, selectedCategoriesIds]);

  const onChangeCategory = (currentNode:any, selectedNodes:any) => {
    log.log("Category:", selectedNodes.map((category:any) => { return category.key} ))
    setSelectedCategoriesIds(selectedNodes.map((category:any) => { return category.key._id}))
  }

  const addImage = (data: any) => {
    setImages([ ...images, data]);
    okService()
  };

  const removeImage = (idx:number) => {
    const temp = [...images];
    // removing the element using splice
    temp.splice(idx, 1);
    setImages(temp)
  };

  // sets kind decoder
  const kindDataCallback = (value:any) =>
  {
    setKindControl(value)
  }

  const onSubmit = (): void => {
    var data:any = {}

    if (itemName == "")
    {
      errorInField('Jméno věci', 'Nevyplněno', '')
      return
    }

    if (selectedCategoriesIds.length == 0)
    {
      errorInField('Kategorie', 'Není vybraná alespoň jedna kategorie', '')
      return
    }

    if (editMode)
      data._id = item._id

    data.name    = itemName
    data.worthless   = worthless
    data.description = description

    data.profileImage = image.getImageModelFromUrl(imageName);
    data.images = images
    data.categories = selectedCategoriesIds
    data.entireCategories = getEntireCategoriesIds(selectedCategoriesIds)
    data.owner = currentUser._id

    if (kindControl.handle != undefined)
    {
      var kindValue = kindControl.handle.getData(kindControl.instance)
      if (kindValue.error != undefined)
      {
        errorService('item/add', kindValue.error)
        return
      }
      kindValue.classId = kindControl.handle.getStaticClassId()
      data.kind = kindValue
    }
    else
    {
      data.kind = undefined
    }

    data.enhancements = enhancementsData
    if (addMode)
    {
      Midd.add(data).then((response)=>{okService(); navigate(-1);} , (errorArg:any) => {errorService('item/add', errorArg)});
    }
    else
    { // edit
      Midd.update(data).then((response)=>{okService(); navigate(-1);} , (errorArg:any) => {errorService('item/update', errorArg)});
    }
  }

  const onDeleteFn = (data:any): void => {   
    
    Midd.del({id:item._id, userId:currentUser._id}).then(
      (response)=>{okService();
        navigate(-1);} ,
        (errorArg:any) => {errorService('item/del', errorArg)});
  }

  var qualifiedName = pageName
  if (item != undefined && isReadOnly)
  {
    qualifiedName = 'Věc: ' + item.name
    if (item.bound != undefined)
    {
      if (item.bound.owner._id == currentUser._id)
      {
        qualifiedName += "  zapůjčena mnou do: "
      }
      else
      {
        qualifiedName += " zapůjčena u " + item.bound.owner.name + " do: "
      }
      qualifiedName += texts.readableDateTime(item.bound.boundToTimestampPermission)
    }
  }

  if (!addMode && !validator.isValid(images, item, imageName, owner, borower, mortageItemFor))
  {
    return ( <reports.LoadingTile feedback={feedback} setFeedback={setFeedback}/> )
  }

  const descriptionRows = showModal ? 4 : 8

  return (
    <Container fluid={showModal}>
      <reports.ErrorModal feedback={feedback} setFeedback={setFeedback}/>

      <h1>{qualifiedName}</h1>
      <hr/>

        <Row>
          <Col sm={8}>
            <div className="card-group" style={customStyle.panelWithTiles}>
              {owner && 
                <Card className="rootTile">
                  <Card.Header><h2>Vlastník</h2>
                  </Card.Header>
                  <Card.Body>
                    {userTile.Tile(owner, undefined, undefined, undefined)}
                  </Card.Body>
                </Card>
              }
              {borower && 
                <Card className="rootTile">
                  <Card.Header><h2>Zapůjčeno u uživatele</h2>
                  </Card.Header>
                  <Card.Body>
                    {userTile.Tile(borower, undefined, undefined, undefined)}
                  </Card.Body>
                </Card>
                }
              {mortageItemFor &&
                <Card className="rootTile">
                  <Card.Header><h2>Zástava pro zapůjčení</h2>
                  </Card.Header>
                  <Card.Body>
                    {itemTile.Tile(mortageItemFor, undefined, undefined, undefined, undefined)}
                  </Card.Body>
                </Card> }
            </div>
          </Col>
          <Col sm={4} style={{float: "right"}} >
            <ProfileImage imageParent={imageName} onUpdateParent={ (imageBodyArg:any) => {setImageName(imageBodyArg);okService()} }  onError={errorService} userId={currentUser._id} isReadOnly={isReadOnly}/>
          </Col>
        </Row>

      { !deleteMode && 
        <>
          <Card className="rootTile">
            <Card.Body>
              {!isReadOnly && <Form.Group controlId="itemNameX">
                  <Form.Label>Jméno věci</Form.Label>
                  <Form.Control type="input" value={itemName} onChange={(e:any) => { setItemName(e.target.value); } } />
                </Form.Group> }

              <kind.Routing initKind={item != undefined ? item.kind : undefined} readOnly={isReadOnly} dataCallback={kindDataCallback} />
              <Form.Label>Kategorie</Form.Label>
              <DropdownTreeSelect texts={{ placeholder: 'Vyber' }} data={visualCategories} onChange={onChangeCategory} readOnly={isReadOnly}/>
              {(selectedCategoriesIds.length == 0 && !isReadOnly) &&
                <FlashMessage theme='danger' text='Vyberte alespoň jednu kategorii.'/>
              }

              {!isReadOnly &&
                <Form.Group controlId="worthlessX">
                  <Form.Check type="checkbox" defaultChecked={worthless} name="textFilter" 
                      onClick={(e: any) => setWorthless(e.target.checked) } label="Pro Vás věc bezvýznamné ceny" />
                </Form.Group> }
              { worthLessWarn && <span className='txt' style={{color:'orange'}}>Věc podléhá automatickému schvalování poptávky a není chráněna!</span> }

              <Form.Group controlId="descriptionX">
                <Form.Label>Popis</Form.Label>
                <Form.Control as="textarea" rows={12} value={description} readOnly={isReadOnly} onChange={(e:any) => { setDescription(e.target.value); } } />
              </Form.Group>

              <enhancement.Routing initEnhancement={item != undefined ? item.enhancements : undefined} readOnly={isReadOnly} dataCallback={setEnhancementsData}/>

              {item != undefined && 
                <Card className="rootTile">
                  <Card.Header><h2>Statistika</h2>
                  </Card.Header>
                  <Card.Body>
                    <p className="txt" >Celkem půjčeno {item.statistics.borowed} krát</p>
                  </Card.Body>
                </Card> }
            </Card.Body>
          </Card>
          <br/>
          <ManyImages imagesParent={images} onRemoveImage={removeImage} onAddImage={addImage} onError={errorService} userId={currentUser._id}  isReadOnly = {isReadOnly} />
          { !showModal && <Button size="lg" variant="secondary" style={customStyle.rigthSecondButtonStyle} onClick={(e) => {navigate(-1);} } >Zpět</Button> }
          { !isReadOnly && <Button size="lg" variant="success" style={customStyle.rigthButtonStyle}  onClick={onSubmit}>{texts.actionName(addMode)}</Button> }
        </>
      }
      { deleteMode && 
        <>
          {item != undefined && 
            <Card className="rootTile">
              <Card.Header><h2>Statistika</h2>
              </Card.Header>
              <Card.Body>
                <p className="txt" >Celkem půjčeno {item.statistics.borowed} krát</p>
              </Card.Body>
            </Card> }
            {borower &&
              <Card className="rootTile">
                <br/>
                <span className='txt' style={{color:'red'}}>Pokud smažete věc ze systému ruší se veškeré závazky na tuto věc navázané.</span><br/>
                <span className='txt' style={{color:'red'}}>Smazáním tedy uvolnítě zástavu uživatele, který si věc půjčil a věc zmizí ze systému.</span>
                <br/>
              </Card> }
          <hr/>
          <Button  size="lg" variant="secondary" style={customStyle.rigthSecondButtonStyle}  onClick={(e) => {navigate(-1);} } >Zpět</Button>
          <Button size="lg" variant="warning" style={customStyle.rigthButtonStyle} onClick={onDeleteFn}>Smazat věc</Button>
        </>
      }
      
      <RenderedCounter/>
    </Container>)
}

const ItemRouted: React.FC<{mode:string, showModal:boolean}> = ({ mode, showModal, children })=> {
  const currentUserState = useCurrentUserState()
  const currentUser = currentUserState.isLogged ? currentUserState.user : currentUserState.questUser;

  const { itemId } = useParams();
 
  return ( <Item itemId={itemId} mode={mode} showModal={showModal}/>)
}

export default { Item, ItemRouted }