import React from 'react';
import s3 from "common/s3";

const getUrlFromImageModel = (image:any):any =>
{
  if (image == undefined)
    return undefined

  return s3.getUrlFromImageName(image);
}

const getImageModelFromUrl = (image:any):any =>
{
  // ## todo
  return s3.getImageNameFromUrl(image);
}

const getImageModel = (bodyData:any, id:any):any =>
{
  return { 'data':Buffer.from(bodyData), '_id':id};
}

const readFilePromise = async (file:any) => {
  const reader = new FileReader();
  reader.readAsText(file);
  const result = await new Promise((resolve, reject) => {
    reader.onload = function(event) {
    resolve(reader.result)
    }
  })
  return result
}


export default { getUrlFromImageModel: getUrlFromImageModel, getImageModelFromUrl, getImageModel, readFilePromise }