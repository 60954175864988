import React from 'react';
import Form from 'react-bootstrap/Form'
import { useKindDecoder } from "models/KindDecoder";
import log from "common/Log";

/*
getStaticName
getInitInstance
*/
const Routing: React.FC<{initKind:any, readOnly:Boolean, dataCallback:any}> = ({ initKind, readOnly, children, dataCallback })=> {
  const initKindSafe:any = initKind != undefined ? initKind : {}

  const kindDecoder:any = useKindDecoder()
  const othersKey = "others"
  const othersName = "Nespecifikován"

  const [kind, setKind] = React.useState<any>({});

  React.useEffect(() => {
    var result = undefined
    for (const cmp of kindDecoder.components) {
      const handleResult:any = cmp.getHandleIfMatching(initKindSafe);
      if (handleResult != undefined)
      {
        result = handleResult
        setKind({'instance': initKindSafe, 'handle':handleResult})
        break;
      }
    }
  }, []);

  const getDefaultKindName = ():string => {
    if (kind.instance != undefined)
      return kind.handle.getStaticName()

    return ""
  }

  const itemSelected = (val:any):any => {
    const sel = kindDecoder.itemSelected(val)
    if (sel == undefined)
      setKind({})
    else
    {
      setKind({ 'instance': sel.getInitInstance(), 'handle':sel})
    }
  }

  // sets kind decoder
  const kindDataCallback = (key:any, value:any) =>
  {
    var kindLoc = kind;
    if (kindLoc.instance != undefined && kindLoc.instance[kindLoc.handle.getStaticKey()] != undefined)
    {
      kindLoc.instance[kindLoc.handle.getStaticKey()][key] = value
      setKind(kindLoc)
    }
  }

  React.useEffect(() => {
    dataCallback(kind)
  }, [kind]);

  return (<>
    {!readOnly && 
      <>
        <Form.Label>Typ věci</Form.Label>
        <Form.Control as="select" className="w-50" value={getDefaultKindName()} onChange={(e:any) => itemSelected(e.target.value)} >
            <option key={othersKey}>{othersName}</option>
            {kindDecoder.components.map( (i:any, index:number) => {
              return (
                <option key={i.getStaticKey()}>{i.getStaticName()}</option>
              )})}
        </Form.Control>
        <br/>
      </>
    }
    {readOnly && getDefaultKindName() != "" && <><h4>Typ věci: {getDefaultKindName()}</h4><br/></>}
    {kind.instance != undefined && kind.handle.decoder(kind.instance, readOnly, kindDataCallback)}
  </>)
}

export default { Routing }