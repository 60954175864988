import React from 'react';
import { Card,  Tooltip, OverlayTrigger} from 'react-bootstrap';
import { Types } from "mongoose";
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import log from "common/Log";

import image from "global/Image";
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import PostAddIcon from '@material-ui/icons/PostAdd';
import DragHandleOutlinedIcon from '@material-ui/icons/DragHandleOutlined';

const ButtonDelete = (linkId:any, text:any) =>
{
  const mainActionOnClick = (e:any) => {
    e.stopPropagation()
    window.location.assign(linkId);
  }

  return (
    <OverlayTrigger placement="top"
      overlay={
        <Tooltip id={linkId}>
          <strong>{text}</strong>
        </Tooltip>
      }
    >
      <CancelOutlinedIcon style={{float: "right", color: "red"}} onClick={mainActionOnClick}></CancelOutlinedIcon>
    </OverlayTrigger>
  )
}

const ButtonDeleteClick = (text:any, onClick:any) =>
{
  return (
    <>
      <OverlayTrigger placement="top"
        overlay={
          <Tooltip id="button-tooltip">
            <strong>{text}</strong>
          </Tooltip>
        }
      >
        <CancelOutlinedIcon style={{float: "right", color: "red"}} onClick={onClick}></CancelOutlinedIcon>
      </OverlayTrigger>
    </>
  )
}

const ButtonDetachClick = (text:any, onClick:any) =>
{
  return (
    <>
      <OverlayTrigger placement="top"
        overlay={
          <Tooltip id="button-tooltip">
            <strong>{text}</strong>
          </Tooltip>
        }
      >
        <CancelPresentationIcon style={{float: "right", color: "orange"}} onClick={onClick}></CancelPresentationIcon>
      </OverlayTrigger>
    </>
  )
}

const ButtonAddClick = (text:any, onClick:any) =>
{
  return (
    <>
      <OverlayTrigger placement="top"
        overlay={
          <Tooltip id="button-tooltip">
            <strong>{text}</strong>
          </Tooltip>
        }
      >
        <PostAddIcon style={{float: "right", color: "green"}} onClick={onClick}></PostAddIcon>
      </OverlayTrigger>
    </>
  )
}

const ButtonSourceClick = (text:any, onClick:any) =>
{
  return (
    <>
      <OverlayTrigger placement="top"
        overlay={
          <Tooltip id="button-tooltip">
            <strong>{text}</strong>
          </Tooltip>
        }
      >
        <DragHandleOutlinedIcon style={{float: "right", color: "green"}} onClick={onClick}></DragHandleOutlinedIcon>
      </OverlayTrigger>
    </>
  )
}

const ButtonEdit = (linkId:any, text:any) =>
{
  const mainActionOnClick = (e:any) => {
    e.stopPropagation()
    window.location.assign(linkId);
  }
  return (
    <OverlayTrigger placement="top"
        overlay={
          <Tooltip id={linkId}>
            <strong>{text}</strong>
          </Tooltip>
        }
      >
      <MenuIcon style={{float: "right", color: "gray"}} onClick={mainActionOnClick}></MenuIcon>
    </OverlayTrigger>
  )
}

const Tile = (anyWithProfileImage:any, legend:String|undefined, keyPrefix:String, suffix:any|undefined, buttonInPlace:((id: Types.ObjectId) => React.ReactNode)|undefined) => {
  const imageBody = image.getUrlFromImageModel(anyWithProfileImage.profileImage)
  const uniqueNameCard = keyPrefix + ".card" + anyWithProfileImage._id;
  var entireLegend:string
  if (legend != undefined && legend != "")
    entireLegend = legend + ": " + anyWithProfileImage.name;
  else
    entireLegend = anyWithProfileImage.name;
  return (
    <Card style={{ width: '15rem', margin:'0.5rem', backgroundColor: 'rgb(245, 245, 245)' }} key={uniqueNameCard}>
      <Card.Body style={{padding: '0.5rem'}} >
        <Card.Title style={{marginBottom: '.3rem'}}> 
          {buttonInPlace && buttonInPlace(anyWithProfileImage._id) }
          {entireLegend}
        </Card.Title>
        {image != undefined && <Card.Img style={{marginBottom:'.3rem'}} src={imageBody} /> }
        {suffix != undefined && <>{suffix}</>}
      </Card.Body>
    </Card> )
}

const noButtons = (id: Types.ObjectId):React.ReactNode => {
  return (<></>)
}

export default  { Tile, noButtons, ButtonDelete, ButtonEdit, ButtonDeleteClick, ButtonDetachClick, ButtonAddClick, ButtonSourceClick }