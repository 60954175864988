import React from 'react';

import { Routes, Route } from "react-router-dom";

import { MeetOverview } from 'components/MeetOverview';

export const MeetControl: React.FC<{}> = ({ children })=> {

  return (
    <>
      <Routes>
        <Route path="/"  element={<MeetOverview/>}/>
      </Routes>
    </>
  );
}
