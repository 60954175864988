import React from 'react';

import { Button, Modal, Alert, Container, Card } from 'react-bootstrap'
import texts from "common/Texts";
import customStyle from 'common/Style';
import { useCurrentUserState, useControl } from "models/CurrentUser";

const ReallyConfirm: React.FC<{whatLabel:any, setWhatLabel:any, continueWorkflow:any}> = ({ whatLabel, setWhatLabel, continueWorkflow, children })=> {
  return (
    <>
      <Modal show={whatLabel != ""}>
        <Modal.Header className="p-3 mb-2 bg-danger text-white">
          <Modal.Title>Varování</Modal.Title>
        </Modal.Header>

        <Modal.Body >
          <h2>{whatLabel}</h2>
        </Modal.Body>

        <Modal.Footer style={{ background: 'light' }}>
          <Button size="lg" variant="success" style={customStyle.buttonStyle} onClick={() => {setWhatLabel(""); continueWorkflow()}}>
            Ano, pokračovat
          </Button>
          <Button size="lg" variant="secondary" style={customStyle.buttonSecondStyle} onClick={() => {setWhatLabel(""); }}>
            {texts.back}
          </Button>
        </Modal.Footer>
      </Modal>
    </> )
}

const errorDecode = (errText:any) =>
{
  if (errText == undefined)
    return undefined

  if (errText == '$$Unauthorized!')
    return {button: 'logout', text:'Neplatný token, pravděpodobně jste přihlášení více než 4 dny. Je třeba znovu se přihlásit.'}

  return {button: '', text:errText}
}

const ErrorModal: React.FC<{feedback:any|undefined, setFeedback:any}> = ({ feedback, setFeedback, children })=> {

  const currentUserState = useCurrentUserState()
  const { logout } = useControl()

  const showHide = feedback != undefined && feedback !== "";
  const decoded = errorDecode(feedback)
  
  if (!showHide || decoded == undefined)
    return (<div></div>)

  return (
    <>
      <Modal show={showHide}>
        <Modal.Header className="p-3 mb-2 bg-danger text-white">
          <Modal.Title>Chyba</Modal.Title>
        </Modal.Header>

        <Modal.Body >
            <h2>{decoded.text}</h2>
        </Modal.Body>

        <Modal.Footer style={{ background: 'light' }}>
          {decoded.button == 'logout' && 
            <Button size="lg" variant="warning" style={customStyle.buttonSecondStyle} onClick={() => { logout(); setFeedback(undefined); }}>
              Odhlásit
            </Button> }
          <Button size="lg" variant="secondary" style={customStyle.buttonSecondStyle} onClick={() => {setFeedback(undefined); }}>
            {texts.back}
          </Button>
        </Modal.Footer>
      </Modal>
    </> )
}

const ErrorTile: React.FC<{feedback:any|undefined}> = ({ feedback, children })=> {
  if (feedback != undefined && feedback != "")
    return (<Alert style={{fontSize:'150%'}} variant={'danger'}>
      Chyba serveru: {feedback}
    </Alert>)

  return (<> </>)
}

const GlobalTile: React.FC<{message:any|undefined}> = ({ message, children })=> {
  return (
    <Container>
      <Card className="rootTile">
        <Card.Body style={{fontSize:'110%'}}>
          <h2>{message}</h2><br/>
        </Card.Body>
      </Card>
    </Container>
  );
}

const LoadingTile: React.FC<{feedback:any|undefined, setFeedback:any}> = ({ feedback, setFeedback, children })=> {
return ( <>
  <ErrorModal feedback={feedback} setFeedback={setFeedback}/>
  Loading ...</> )
}

export default  { ErrorModal, ErrorTile, LoadingTile, ReallyConfirm, GlobalTile }