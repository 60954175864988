import React from 'react';

import { Button, Card, Container, Tooltip, OverlayTrigger} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import log from "common/Log";
import { RenderedCounter } from 'common/Perf';
import customStyle from 'common/Style';

import LockIcon from '@material-ui/icons/Lock';

import { useCurrentUserState } from "models/CurrentUser";
import texts from "common/Texts";
import reports from "components/Helpers/Reports"
import MidBound from 'middlewares/Bound';
import Mid from 'middlewares/Item';

import itemTile from "components/Helpers/ItemTile";

export const ItemOverview: React.FC<{}> = ({ children })=> {
  const pageName = 'Věci';

  const currentUserState = useCurrentUserState()

  const [free, setFree] = React.useState([]);
  const [borowed, setBorowed] = React.useState([]);
  const [lended, setLended] = React.useState([]);

  const [feedback, setFeedback] = React.useState<string|undefined>(undefined);

  function errorService(functionId:string, errorArg:any) :void {
    setFeedback(log.decodeError(pageName, functionId, errorArg)); 
  }

  function okService() : void {
      setFeedback(""); 
  }

  const removeItem = (id:any) => {
    Mid.del({id}).then(
      () => {},
      (error:any) => { errorService('item/del', error) }
    );
    window.location.reload();
  };

  React.useEffect(() => {
    MidBound.get_class({'id' :currentUserState.user._id}).then(
      (response:any) => {
        setFree(response.free);
        setBorowed(response.borowed);
        setLended(response.lended);
      },
      (error:any) => { errorService('item/get_class', error) }
    );
  }, []);

   return (
    <Container >
      <reports.ErrorModal feedback={feedback} setFeedback={setFeedback}/>

      <Card className="rootTile">
        <Card.Header><h1>Mé věci volné</h1>
          <Link to="/item/create" style={{float: 'right'}}>
            <Button size="lg" variant="success" style={customStyle.rigthButtonStyle} >Nová věc</Button>
          </Link>
        </Card.Header>
        <div className="card-group" style={customStyle.panelWithTiles}>
            {free.map( (i:any, index:number) => {
              return itemTile.Tile(i, undefined, undefined, undefined,
                (id:any) =>
                {
                  return (
                    <>
                      {itemTile.ButtonDelete(id)}
                      {itemTile.ButtonEdit(id)}
                    </>)
                })
            })
          }
        </div>
      </Card>
      
      <Card className="rootTile">
        <Card.Header><h1>Mé věci půjčené ostatním</h1>
        </Card.Header>
        <Card.Body className="card-body">
          <div className="card-group" style={customStyle.panelWithTiles}>
            {lended.map( (i:any, index:number) => {
                return itemTile.Tile(i, undefined, undefined, 'Do: ' + texts.readableDateTime(i.bound.boundToTimestampPermission),
                      (id:any) =>
                      {
                        return (
                          <>
                            {i.mortage != undefined && 
                                <OverlayTrigger placement="top"
                                    overlay={
                                      <Tooltip id={i._id}>
                                        <strong>Použito jako záruka</strong>
                                      </Tooltip>
                                    }
                                  >
                                  <LockIcon style={{float: "right", color: "gray"}}/>
                                </OverlayTrigger>
                             }
                            {itemTile.ButtonDelete(id)}
                          </>)
                      })
              })
            }
          </div>
        </Card.Body>
      </Card>

      <Card className="rootTile">
        <Card.Header><h1>Cizí věci které jsem si vypůjčil</h1>
        </Card.Header>
        <Card.Body>
          <div className="card-group" style={customStyle.panelWithTiles}>
            {borowed.map( (b:any, index:number) => {
              return itemTile.Tile(b.itemBounded, undefined, undefined, 'Do: ' + texts.readableDateTime(b.boundToTimestampPermission), undefined)
              })
            }
          </div>
        </Card.Body>
      </Card>

      <RenderedCounter/>
    </Container>
  );
}
