import axios from "axios";
import log from "common/Log";
import security from "common/Security";

import * as server from "config/server.config";

const API_URL = server.SERVER_POINT + "/api/location/";

const add = (data:any) =>
{
  return axios.post(API_URL + "add", data, { headers: security.authHeader()})
    .then((response) => {
      return response.data;
    });
};

const update = (data:any) =>
{
  return axios.post(API_URL + "update", data, { headers: security.authHeader()})
    .then((response) => {
      return response.data;
    });
};

const set_home = (data:any) =>
{
  return axios.post(API_URL + "set_home", data, { headers: security.authHeader()})
    .then((response) => {
      return response.data;
    });
};

const get = (data:any) => {
  return axios.post(API_URL + "get", data)
    .then((response) => {
      return response.data;
    });
};

const get_many = (data:any) => {
  return axios
    .post(API_URL + "get_many", data)
    .then((response) => {
      return response.data;
    });
};

const get_in_range = (data:any) => {
  return axios
    .post(API_URL + "get_in_range", data)
    .then((response) => {
      return response.data;
    });
};

const del = (data:any) => {
  return axios.post(API_URL + "del", data, { headers: security.authHeader()})
};

const get_self = (data:any) => {
  return axios
    .post(API_URL + "get_self", data)
    .then((response) => {
      return response.data;
    });
};

const attach = (data:any) => {
  return axios.post(API_URL + "attach", data, { headers: security.authHeader()}).then((response) => {
    return response.data;
  });
};

const detach = (data:any) => {
  return axios.post(API_URL + "detach", data, { headers: security.authHeader()}).then((response) => {
    return response.data;
  });
};

export default {
  add,
  set_home,
  get,
  get_in_range,
  get_many,
  update,
  del,
  get_self,
  attach,
  detach,
};
