import React from 'react';
import { Button, Modal } from 'react-bootstrap'
import { Types } from "mongoose";

import customStyle from 'common/Style';

import itemTile from "components/Helpers/ItemTile";
import genericTile from "components/Helpers/GenericTile";

export const ItemSubsetSelector: React.FC<{items:[], itemSelector:any, isReadOnly:boolean}> = ({ isReadOnly, items, itemSelector, children })=> {
  const [showHide, setShowHide] = React.useState(false);

  return (
    <>
      {!isReadOnly && 
        <Button size="lg" variant="success" style={customStyle.rigthButtonStyle} onClick={(e:any)=> { setShowHide(true) }}>Vybrat věc</Button> }

      <Modal size="lg" show={showHide}>
        <Modal.Header>
          <Modal.Title>Výběr z následujících volných věcí</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="card-body">
          <div className="card-group" style={customStyle.panelWithTiles}> 
            {items.map( (item:any) => {
              return itemTile.Tile(item, undefined,
                () => {
                  itemSelector(item);
                  setShowHide(false);
                },
                undefined,
                (id:any) =>
                    {
                      return genericTile.ButtonSourceClick('Detaily', (e:any) => { e.stopPropagation(); itemTile.OnClickActionDefault(item); setShowHide(false) } )
                    }
              )})
            }
          </div>
        </div>
        </Modal.Body>
        <Modal.Footer>
          <Button size="lg" variant="secondary" style={customStyle.rigthSecondButtonStyle} onClick={(e:any)=> {
            setShowHide(false)
            }}>
              Zruš
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
