
const panelWithTiles = {
  paddingLeft: '0rem', paddingRight: '0rem', paddingTop: '0rem', paddingBottom: '0rem'
}

const groupStyle = {
  margin:'0rem'
}

const backgroundSecondaryColor:any = '#115f32';
const backgroundColor:any = '#2E8B57';

const buttonStyle = {
  marginLeft: '.3rem',
}

const buttonSecondStyle = {
  marginLeft: '.3rem',
}

const buttonWarnStyle = {
  marginLeft: '.3rem',
}

const rigthButtonStyle = {
  marginLeft: '.3rem',
  float: 'right',
} as React.CSSProperties;

/*const rigthSecondButtonStyle = {
  color: "white",
  background: backgroundSecondaryColor,
  marginLeft: '.3rem',
  float: 'right',
  borderWidth: "2px",
  borderColor: "#006b51",
  fontSize: "1.5rem",
  width: 200,
  height: 50,
} as React.CSSProperties;*/

const rigthSecondButtonStyle = {
  marginLeft: '.3rem',
  float: 'right',
} as React.CSSProperties;

const buttonFullWidthStyle = 
{
  color: "white",
  background: backgroundColor,
  borderWidth: "2px",
  borderColor: "#006b51",
  fontSize: "1.5rem",
  width: '100%',
}


export default { panelWithTiles, buttonSecondStyle, groupStyle, buttonStyle, rigthButtonStyle, buttonFullWidthStyle, rigthSecondButtonStyle, buttonWarnStyle }