import React from 'react';
import log from "common/Log";

export const RenderedCounter :React.FC<{}> = ({ children })=> {
  const refreshCounter = React.useRef(0);
  const [feedback, setFeedback] = React.useState<string|undefined>(undefined);

  log.logN("Rendering done");

  const sendFeedback = (data: any) => {
    setFeedback('Renderer:' + refreshCounter.current);
  };

  React.useEffect(() => {
    refreshCounter.current = refreshCounter.current + 1
  }, []);
 
  refreshCounter.current = refreshCounter.current + 1
  return (<></>)

  return (
    <>
    Renderer: + {refreshCounter.current}
    </>
  );
};