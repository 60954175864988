import { format } from "date-fns";

const replacer = (key:any,value:any) =>
{
    if (key=="profileImage") return "*substitute*";
    else if (key=="data") return "*substitute*";
    else return value;
}

const dump = (variable:any) =>
{
  return JSON.stringify(variable, replacer, 2);
}

const logN = (message:string) =>
{
  let newDate = new Date(Date.now());
  console.log(format(newDate, "HH:mm:ss") + "| " + message);
}

const log = (message:string, variable:any) =>
{
  logN(message + " " + dump(variable));
}

const errorOnUserInput = (screen:string,fieldId:string, message:string, value:any) =>
{
  log('ERROR User input: screen:' + screen + ' at field:' + fieldId + ' message:'+  + ', value: ', value);
}

const enter = (functionId:string, variable:any) =>
{
  log("Enter:" + functionId, variable);
}

const exitOk = (functionId:string, variable:any) =>
{
  log("OK Exit:" + functionId, variable);
}

const checkpoint = (functionId:string, message:string, variable:any) =>
{
  log("Checkpint:" + functionId + ", message:" + message, variable);
}

const checkpointN = (functionId:string, message:string) =>
{
  logN("Checkpint:" + functionId + ", message:" + message);
}

/**
 * N = no params
 */
const exitOkN = (functionId:string) =>
{
  logN("OK Exit:" + functionId);
}

const exitError = (functionId:string, message:string, variable:any) =>
{
  log("ERROR Exit:" + functionId + ", message:" + message, variable);
}

const exitErrorN = (functionId:string, message:string) =>
{
  logN("ERROR Exit:" + functionId + ", message:" + message);
}

const decodeError = (screen:string, functionId:string, errorArg:any) : string => {
  if (errorArg == undefined)
    errorArg = "Neznámá"

  logN("ERROR: screen: " + screen + ", function: " + functionId + ", message:" + dump(errorArg));

  if (errorArg.response != undefined &&
      errorArg.response.data != undefined &&
      errorArg.response.data.message != undefined)
  {
    errorArg = errorArg.response.data.message
  }
  else if (errorArg.message != undefined)
  {
    errorArg = errorArg.message
  }
  return errorArg.toString();
}

export default {dump, log, logN, enter, exitOk, exitOkN, exitError, exitErrorN, checkpointN, checkpoint, errorOnUserInput, decodeError}