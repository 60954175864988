import React from 'react';
import { Button, Container,Form } from 'react-bootstrap/';
import { useNavigate } from 'react-router-dom';

import validator from "common/Validator";
import log from "common/Log";
import FlashMessage from "../common/FlashMessage";
import { useCurrentUserState } from "models/CurrentUser";
import reports from "components/Helpers/Reports"

import customStyle from 'common/Style';
import AuthService from "middlewares/User";

export const ChangePassword: React.FC<{}> = ({ children })=> {

  const pageName = 'Změna hesla'

  const [feedback, setFeedback] = React.useState<string|undefined>(undefined);
  const navigate = useNavigate()
  const currentUserState = useCurrentUserState()

  const [oldPassword, setOldPassword]  = React.useState("");
  const [password,    setPassword]  = React.useState("");
  
  function errorInField(fieldId:string, message:string, value:any) :void {
    log.errorOnUserInput("ChangePassword", fieldId, message, value);
    setFeedback(validator.inputErrorRedable(fieldId, message, value)); 
  }

  function errorService(functionId:string, errorArg:any) :void {
    setFeedback(log.decodeError(pageName, functionId, errorArg)); 
  }

  function okService() : void {
    setFeedback(""); 
}
  function onSubmit() {
    if (password == "" || password.length < 6)
    {
      errorInField('Nové heslo', 'délka alespoň 6 znaků', '')
      return
    }
    if (oldPassword == "")
    {
      errorInField('Staré heslo', 'nevyplněno', '')
      return
    }

    var wholeData:any = {};
    wholeData.oldPassword = oldPassword
    wholeData.password = password
    wholeData.userId = currentUserState.user._id
    AuthService.change_password(wholeData).then((response:any)=>{ okService() } , (errorArg:any) => {errorService('user/change_password', errorArg)});
  }

  return (
    <Container>
      <h1>Změna hesla</h1><hr/>

      {feedback == "" &&
        <>
          <FlashMessage theme='success' text='Změna hesla proběhla úspěšně.'/> 
          <Button size="lg" variant="secondary" style={customStyle.rigthSecondButtonStyle}  onClick={(e) => {navigate(-1);} } >Zpět</Button>
        </>
      }

      <reports.ErrorModal feedback={feedback} setFeedback={setFeedback}/>

      {feedback === undefined && <>
        <Form.Group controlId="oldPasswordX">
          <Form.Label>Současné heslo</Form.Label>
          <Form.Control type="password" value={oldPassword} onChange={(e:any) => { setOldPassword(e.target.value); } } />
        </Form.Group>
        <Form.Group controlId="passwordX">
          <Form.Label>Nové heslo</Form.Label><br/>
          <input type="password" style={{borderRadius: '3px', width: '30rem'}} name="password" id="password" value={password} onChange={(e:any) => { setPassword(e.target.value); } }/>
        </Form.Group>
        <br/>
        <Button size="lg" variant="secondary" style={customStyle.rigthButtonStyle} onClick={(e) => {navigate(-1);} } >Zpět</Button>
        <Button size="lg" variant="success" style={customStyle.rigthButtonStyle}  onClick={onSubmit}>Změň</Button>
      </>}

    </Container>
  );
}
