import React from 'react';

import { Form, Container, Button, Card } from 'react-bootstrap'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useNavigate, useParams } from 'react-router-dom';

import validator from "common/Validator";
import log from "common/Log";
import texts from "common/Texts";
import customStyle from 'common/Style';
import { RenderedCounter } from 'common/Perf';

import { useCurrentUserState, useControl } from "models/CurrentUser";

import Middleware from "middlewares/Location";

import { ProfileImage } from "./ProfileImage";
import { initialPosition, geoOptions, IPosition } from "common/Position";
import { LocationMap } from "components/LocationMap"
import { ManyImages } from "components/ManyImages"
import reports from "components/Helpers/Reports"
import userTile from "components/Helpers/UserTile";

export const Location: React.FC<{locationId:any|undefined, isReadOnly:boolean, showModal:boolean}> = ({ locationId, isReadOnly, showModal, children })=> {
  const addMode = locationId == undefined;
  const editMode = !addMode;

  const currentUserState = useCurrentUserState()
  const currentUser = currentUserState.isLogged ? currentUserState.user : currentUserState.questUser;

  const { updateLocations } = useControl()
  const navigate = useNavigate()

  const [validContent, setValidContent] = React.useState<boolean>(locationId == undefined);
  const [location, setLocation] = React.useState<any|undefined>();
  const [position, setPosition] = React.useState<IPosition>(initialPosition);
  
  const [placeName, setPlaceName] = React.useState("");
  const [city, setCity] = React.useState("");
  const [description, setDescription] = React.useState("");
  

  const pageName = isReadOnly ? 'Místo: ' + location?.name: texts.pageNamePrefix(addMode) + 'místa'

  const [images, setImages] = React.useState([] as any);  
  const [imageName, setImageName] = React.useState(`park.png`)

  const [feedback, setFeedback] = React.useState<string|undefined>(undefined);

  function errorService(functionId:string, errorArg:any) :void {
    setFeedback(log.decodeError(pageName, functionId, errorArg)); 
  }

  function errorInField(fieldId:string, message:string, value:any) :void {
    log.errorOnUserInput("Location", fieldId, message, value);
    setFeedback(validator.inputErrorRedable(fieldId, message, value)); 
  }

  function okService() : void {
      setFeedback(""); 
  }

  React.useEffect(() => {
    if (editMode || isReadOnly)
    {
      Middleware.get({'id' :locationId}).then(
        (response) => {
          setPosition({latitude:response.location.geoLatitude, longitude: response.location.geoLongitude})
          setImages(response.location.images)
          setLocation(response.location)
          
          setPlaceName(response.location.name)
          setCity(response.location.city)
          setDescription(response.location.description)

          setImageName(response.location.profileImage)
          setValidContent(true)
        },
        (error:any) => { errorService('location/get', error) }
      );
    }
  }, [locationId]);

  React.useEffect(() => {
    initialize();
  }, []);

  const onGeoResponse = (position:any) => {
    const { latitude, longitude } = position.coords;
    log.log("GEO RESPONSE", latitude);
    setPosition({latitude: latitude, longitude: longitude})
  }

  const initialize = () => {
    // called once at initial setup
    if (addMode)
    {
      if ("geolocation" in navigator) {
        log.logN("GEO  Available");
      } else {
        log.logN("GEO Not Available");
      }

      if (navigator != null)
        navigator.geolocation.getCurrentPosition(onGeoResponse, (error:any) => {errorService('geolocation.getCurrentPosition', error)}, geoOptions);
    }
  }

  const onSubmit = (): void => {

    if (placeName == undefined || placeName == "")
    {
      errorInField('Jméno místa', 'prázdná hodnota', '')
      return
    }

    if (city == undefined || city == "")
    {
      errorInField('Jméno města', 'prázdná hodnota', '')
      return
    }

    if (description == undefined || description == "")
    {
      errorInField('Popis', 'prázdná hodnota', '')
      return
    }

    var data:any = {}
    data.profileImage = imageName;
    data.images = images
    data.geoLatitude = position.latitude
    data.geoLongitude = position.longitude
    
    data.name = placeName
    data.description = description
    data.city = city
    data.owner = currentUserState.user._id

    if (addMode)
    {
      Middleware.add(data).then((response:any)=>{
        updateLocations(currentUserState.user.sharedLocations.concat(response.location))
        okService()
        navigate(-1);
      } , (errorArg:any) => {errorService('location/add', errorArg)});
    }
    else
    { // edit
      data._id = locationId
      Middleware.update(data).then((response)=>{
        log.log(">>", currentUserState)
        updateLocations(currentUserState.user.sharedLocations.map((locationLoc:any) => {
          if (locationLoc._id == response.location._id)
            return response.location
          else
            return locationLoc
        } ))
        okService()
        navigate(-1);
      } , (errorArg:any) => {errorService('location/update', errorArg)});
    }
  }

  const addImage = (data: any) => {
    setImages([ ...images, data ]);
    okService()
  };

  const updatePosition = (data: any) => {
    setPosition(data);
  };

  const removeImage = (idx:number) => {
    const temp = [...images];
    // removing the element using splice
    temp.splice(idx, 1);
    setImages(temp)
  };

  if (!validContent)
  {
    return ( <reports.LoadingTile feedback={feedback} setFeedback={setFeedback}/> )
  }

  const refAdverbShow = location != undefined ? ("/location/bookmark/" + location._id + "/blank_area") : ""
  const refAdverbShowBack = location != undefined ? ("/location/bookmarkback/" + location._id + "/blank_area") : ""
  const refAdverbShow2 = location != undefined ? ("/location/letter/" + location._id + "/blank_area") : ""
  
  return (
    <Container>
      <reports.ErrorModal feedback={feedback} setFeedback={setFeedback} />

      {validContent &&
        <>
          <h1>{pageName}</h1>
          <hr/>
          <Row>
            <Col sm={8}>
            <div className="card-group" style={customStyle.panelWithTiles}>
              { editMode && location.owner._id != currentUser._id &&
                <Card className="rootTile">
                  <Card.Header><h2>Vlastník</h2>
                  </Card.Header>
                  <Card.Body>
                    {userTile.Tile(location.owner, undefined, undefined, undefined)}
                  </Card.Body>
                </Card>
              }
            </div>
            </Col>
            <Col sm={4} style={{float: "right"}} >
              <ProfileImage imageParent={imageName} onUpdateParent={ (imageBodyArg:any) => {setImageName(imageBodyArg); okService()} } onError={errorService} userId={currentUser._id} isReadOnly={isReadOnly}/>              
            </Col>
          </Row>

          <Card className="rootTile">
            <Card.Body>
              {!isReadOnly &&
                <Form.Group controlId="placeX">
                  <Form.Label>Jméno místa</Form.Label>
                  <Form.Control type="input" disabled={isReadOnly} value={placeName} onChange={(e:any) => { setPlaceName(e.target.value); } } />
                </Form.Group> }

              <Form.Group controlId="cityX">
                <Form.Label>Jméno obece / vesnice / město + číslo městské části</Form.Label>
                <Form.Control type="input" disabled={isReadOnly} value={city} onChange={(e:any) => { setCity(e.target.value); } } />
              </Form.Group>

              <Form.Group controlId="descriptionX">
                <Form.Label>Popis</Form.Label>
                <Form.Control type="input" disabled={isReadOnly} value={description} onChange={(e:any) => { setDescription(e.target.value); } } />
              </Form.Group>

              <LocationMap positionParent={position} onUpdatePosition={updatePosition} isReadOnly = {isReadOnly}/>

              {!isReadOnly && <h3 style={customStyle.rigthButtonStyle}>
                Kliknutím na mapu vyberte přesnou pozici místa.
              </h3> }
            </Card.Body>

            {isReadOnly &&  <a href={refAdverbShow}>Reklamní letáček 1</a> }
            {isReadOnly &&  <a href={refAdverbShowBack}>Reklamní letáček 1, zadní strana</a> }

            {isReadOnly &&  <a href={refAdverbShow2}>Reklamní letáček 2</a> }
          </Card>
          <br/>
          <ManyImages imagesParent={images} onRemoveImage={removeImage} onAddImage={addImage} onError={errorService} userId={currentUser._id} isReadOnly = {isReadOnly} />

          {!showModal && <Button size="lg" variant="secondary" style={customStyle.rigthSecondButtonStyle} onClick={(e) => {navigate(-1);} } >Zpět</Button> }
          {!isReadOnly && <Button size="lg" variant="success" style={customStyle.rigthButtonStyle}  onClick={onSubmit}>{texts.actionName(addMode)}</Button> }
        </>
        }
      <RenderedCounter/>
    </Container>
  );
}

const LocationRouted: React.FC<{isReadOnly:boolean, showModal:boolean}> = ({ isReadOnly, showModal, children })=> {
  const { locationId } = useParams();

  return ( <Location locationId={locationId} isReadOnly={isReadOnly} showModal={showModal}/>)
}

export default { Location, LocationRouted }