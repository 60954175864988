
import 'react-dropdown-tree-select/dist/styles.css'

import React from 'react';
import { Button,  Modal, Card, Container, Form, Pagination, Image} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DropdownTreeSelect from "react-dropdown-tree-select";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import BootstrapTable from 'react-bootstrap-table-next';  

import log from "common/Log";
import validator from "common/Validator";
import { RenderedCounter } from 'common/Perf';
import customStyle from 'common/Style';

import image from "global/Image";

import { useControl, useCurrentUserState } from "models/CurrentUser";
import { useKindDecoder } from "models/KindDecoder";

import Midd from 'middlewares/Item';
import MiddCat from "middlewares/Category";

import item from 'components/Item';
import reports from "components/Helpers/Reports"

export enum FindType {
  ITEM_WIDE = "itemWide",
  ITEM_NAME = "itemName",
  ITEM_DESCRIPTION = "itemDescription",
  USER_NAME = "userName",
}

export const ItemFind: React.FC<{}> = ({ children })=> {
  const pageName = 'Hledání věcí';

  const [activeItems, setActiveItems] = React.useState<any[]>([]);
  const [allItemsIds, setAllItemsIds] = React.useState([]);
  const [selectedPage, setSelectedPage] = React.useState<any|undefined>();
  const [dataForPage, setDataForPage] = React.useState(0);
  const [pageNextAllowed, setPageNextAllowed] = React.useState(false);
  const [pagePrevAllowed, setPagePrevAllowed] = React.useState(false);

  const [itemsLegend, setItemsLegend] = React.useState("");

  const [modelCategories, setModelCategories] = React.useState([] as any);
  
  const [selectedCategoriesIds, setSelectedCategoriesIds] = React.useState([] as any);
  const [visualCategories, setVisualCategories] = React.useState([] as any);

  const [distanceFilter, setDistanceFilter] = React.useState(false);
  const [distanceMux, setDistanceMux] = React.useState(1000);

  const [textFilter, setTextFilter] = React.useState(true);
  const [categoryFilter, setCategoryFilter] = React.useState(false);

  const [feedback, setFeedback] = React.useState<string|undefined>(undefined);

  const [distance, setDistance] = React.useState<any|undefined>();
  const [freeOnly, setFreeOnly] = React.useState(false);
  const [findType, setFindType] = React.useState<FindType>(FindType.ITEM_WIDE);
  const [text, setText] = React.useState("");

  const currentUserState = useCurrentUserState()
  const c2 = useControl()
  const kindDecoder:any = useKindDecoder()

  function errorService(functionId:string, errorArg:any) :void {
    setFeedback(log.decodeError(pageName, functionId, errorArg)); 
  }

  function errorInField(fieldId:string, message:string, value:any) :void {
    log.errorOnUserInput("Item find", fieldId, message, value);
    setFeedback(validator.inputErrorRedable(fieldId, message, value)); 
  }

  function okService() : void {
      setFeedback("");
  }

  function propagateCategories(selectedCategoriesIds:any [])
  {
    var categoriesNew = modelCategories.map((category:any) => {
      var tagLabel = ""
      var categoryOwner:any = category
      while (categoryOwner != undefined)
      {
        if (tagLabel.length != 0)
          tagLabel = '\\' + tagLabel

        tagLabel = categoryOwner.name + tagLabel;
        categoryOwner = categoryOwner.owner
      }
      return {label: category.name, tagLabel:tagLabel, checked:selectedCategoriesIds.includes(category._id, 0), key:category}
    })

    categoriesNew.forEach((category:any) => 
    {
      category.children = categoriesNew.filter((categoryInner:any) => 
        {
          const result = categoryInner.key.owner != undefined && categoryInner.key.owner._id == category.key._id 
          return result
        } )
    })
    setVisualCategories(categoriesNew.filter((category:any) => { return (category.key.owner == undefined) } ))
  }

  const onChangeCategory = (currentNode:any, selectedNodes:any) => {
    setSelectedCategoriesIds(selectedNodes.map((category:any) => { return category.key._id}))
  }

  const onFilterChange = (e:any) => {
    switch(e.target.name) {
      case 'textFilter': { setTextFilter(e.target.checked); break; }
      case 'distanceFilter': { setDistanceFilter(e.target.checked); break; }
      case 'categoryFilter': { setCategoryFilter(e.target.checked); break; }
    }
  }

  const findItem = (e:any) => {
    setDataForPage(0);
    // validation section
    if (distanceFilter)
    {
      if (!currentUserState.isLogged)
      {
        errorInField('filtr vzdálenosti', 'Nelze použít filtr podle lokace, neboť nejste přihlášený', '')
        return
      }

      if (distance == undefined || distance === "")
      {
        errorInField('maximální vzdálenost', 'nevyplněna', '')
        return
      }

      const distanceInt = parseInt(distance)
      if (isNaN(distanceInt) || distanceInt < 0 )
      {
        errorInField('maximální vzdálenost', 'hodnota musí obsahovat nezáporné číslo', distance)
        return
      }
    }

    const textFilterActive = textFilter && text != undefined && text !== ""

    const filters = {
      ...(distanceFilter && { distanceFilter: { 'distance': parseInt(distance) * distanceMux, 'selectedLocationsIds' : currentUserState.user.sharedLocations} }),
      ...(freeOnly && { statusFilter: 'free' }),
      ...(categoryFilter && { categoryFilter: { 'categories': selectedCategoriesIds}}),
      ...(currentUserState.isLogged && {currentUserId : currentUserState.user._id}),
      ...(textFilterActive && { textFilter: {
         'text': text,
         'findType':findType,
        }})}

    Midd.get_borrow_candidates(filters).then(
      (response:any) => {
        setActiveItems(response.items.map((item:any) => ({ ...item, fullName: kindDecoder.getName(item), fullDescription: kindDecoder.getDescription(item) })))
        setAllItemsIds(response.allItemIds != undefined ? response.allItemIds : [])
        setSelectedPage(0);
        okService()
      },
      (error:any) => { errorService("item/get_borrow_candidates", error) })
  }

  React.useEffect(() => {  
    MiddCat.get_all().then(
      (response:any) => {
        setModelCategories(response.categories)
        findItem(false)
      },
      (error:any) => { errorService("categories/get_all", error) }
    );
    window.addEventListener('keydown', handleKeyPress)
  }, []);

  React.useEffect(() => {
    propagateCategories(selectedCategoriesIds)
  }, [modelCategories, selectedCategoriesIds]);

  React.useEffect(() => {
    if (allItemsIds.length > 20 && selectedPage!= undefined)
    {
      const maxSelectedPageValue = Math.floor(allItemsIds.length / 20)

      if (selectedPage >= maxSelectedPageValue)
      {
        setPageNextAllowed(false)
        setPagePrevAllowed(true)
      }
      else if (selectedPage <= 0)
      {
        setPageNextAllowed(true)
        setPagePrevAllowed(false)
      }
      else
      {
        setPageNextAllowed(true)
        setPagePrevAllowed(true)
      }

      const fromIdx = selectedPage * 20
      const toIdx = Math.min((selectedPage + 1) * 20, allItemsIds.length)
      const ids = allItemsIds.slice(fromIdx, toIdx)

      setItemsLegend(fromIdx + "-" + toIdx + " z " + allItemsIds.length)

      if (dataForPage != selectedPage)
      {
        Midd.get_many({'ids': ids} ).then(
          (response:any) => {
            //setActiveItems(response.items)
            setActiveItems(response.items.map((item:any) => ({ ...item, fullName: kindDecoder.getName(item), fullDescription: kindDecoder.getDescription(item) })))
            setDataForPage(selectedPage)
            okService()
          },
          (error:any) => { errorService('item/get_many', error) })
      }
    }
  }, [selectedPage]);

  const pageBack = (e:any) => { setSelectedPage(selectedPage - 1) }
  const pageNext = (e:any) => { setSelectedPage(selectedPage + 1) }
  const [modalItem, setModalItem] = React.useState<any|undefined>();

  const imageFormatter = (cell:any, row:any) =>
  { 
    const imageBody = image.getUrlFromImageModel(row.profileImage)
    return (<Image src={imageBody} width='80' height='80' />)
  }

  const actionsFormatter = (cell:any, row:any) =>
  { 
    const refOrder = "/proposal/ask/create/" + row._id;

    return ( <>
        <Link to={refOrder}>
            <Button size="lg" variant="success" style={customStyle.buttonStyle}>Objednej</Button>
        </Link>
      </>) 
  }

  const columns = [
    { dataField: '_id',
      text: 'Ikona',
      csvExport: false,
      formatter: imageFormatter,
      headerStyle: { minWidth: '2rem', width: '2rem' },
      events: { onClick }
    },
    { dataField: 'fullName', text: 'Jméno věci', sort: true, headerStyle: { minWidth: '7rem', width: '15rem' },
        events: { onClick }
      },
    { dataField: 'fullDescription', text: 'Popis', sort: true,
        headerStyle: { minWidth: '10rem', width: '50rem' },
        formatter: (cell:any, row:any) => { return(
          <div>
              {cell.head != undefined && <>{cell.head}<br/></> }
              {cell.plain}
          </div>
        )},
        events: { onClick }
      },
    { dataField: 'createTimestamp',
      text: 'Akce',
      csvExport: false,
      formatter: actionsFormatter,
      headerStyle: { minWidth: '10rem', width: '10rem' },
    },
  ]

  const handleKeyPress = (target:any) => {
    if (target.key === 'Escape') {
        setModalItem(undefined)
    }
  };

  function onClick(e:any, column:any, columnIndex:any, row:any, rowIndex:any)
  {
    setModalItem(activeItems[rowIndex]._id)
  }

  return (
    <>
      <reports.ErrorModal feedback={feedback} setFeedback={setFeedback} />

      {modalItem != undefined &&
        <Modal dialogClassName="item-find" show={true} >
          <Modal.Body>
            <item.Item itemId={modalItem} mode='ro' showModal={true}/>
          </Modal.Body>
          <Modal.Footer>
            <Button size="lg" variant="secondary" style={customStyle.buttonSecondStyle} onClick={(e:any)=> {
                setModalItem(undefined)
                }}>
                  Zpět
            </Button>
          </Modal.Footer>
        </Modal> }

      <Container style={{marginLeft:'0'}} fluid>
        <Row >
          <Col lg={3} >
            <Card style={{ borderRadius: '0' }} >
              <Card.Header><h3>{pageName}</h3></Card.Header>
              <Card.Body>
                <Card bg='light' style={{ marginBottom: '.3rem' }}>
                  <Card.Header>
                    <Form.Group controlId="textHeader" style={customStyle.groupStyle}>
                      <Form.Check type="checkbox" defaultChecked={true} name="textFilter" onChange={onFilterChange} label="Podle textu" />
                    </Form.Group>
                  </Card.Header>
                  {textFilter &&
                    <Card.Body>
                      <Form.Group controlId="textNameDetails" style={customStyle.groupStyle}>
                        <Form.Check name='seachSelect' type="radio" defaultChecked={findType == FindType.ITEM_WIDE} label="V názvu / popisu věci" onClick={(e:any) => { setFindType(FindType.ITEM_WIDE) }} />
                      </Form.Group>

                      { false && <><Form.Group controlId="textNameDetails" style={customStyle.groupStyle}>
                        <Form.Check name='seachSelect' type="radio" defaultChecked={findType == FindType.ITEM_NAME} label="V názvu věci" onClick={(e:any) => { setFindType(FindType.ITEM_NAME) }} />
                        <Form.Text className="text-muted">
                          Hledá text v názvu věci, diakritiku identicky
                        </Form.Text>
                      </Form.Group>
                      <Form.Group controlId="textDescDetails" style={customStyle.groupStyle}>
                        <Form.Check name='seachSelect' type="radio" defaultChecked={findType == FindType.ITEM_DESCRIPTION} label="V popisu" onClick={(e:any) => { setFindType(FindType.ITEM_DESCRIPTION) }} />
                        <Form.Text className="text-muted">
                          Hledá text v popisu, diakritiku identicky 
                        </Form.Text>
                      </Form.Group> </>}

                      <Form.Group controlId="textUserDetails" style={customStyle.groupStyle}>
                        <Form.Check name='seachSelect' type="radio" defaultChecked={findType == FindType.USER_NAME} label="V názvu uživatele" onClick={(e:any) => { setFindType(FindType.USER_NAME) }} />
                      </Form.Group>
                      <Form.Group controlId="textDetails" style={customStyle.groupStyle}>
                        <Form.Label>Hledaný text</Form.Label>
                        <Form.Control type="input" onChange={(e:any) => setText(e.target.value)} />
                        <Form.Text className="text-muted">
                          Velikost písmen i diakritika ignorována, hledájí se celá slova
                        </Form.Text>
                      </Form.Group>
                    </Card.Body> }
                </Card>

                <Card bg='light' style={{ marginBottom: '.3rem' }} >
                  <Card.Header>
                    <Form.Group controlId="distanceHeader" style={customStyle.groupStyle}>
                      <Form.Check type="checkbox" name="distanceFilter" onClick={onFilterChange} label="Podle místa" />
                    </Form.Group>
                  </Card.Header>
                  {distanceFilter &&
                    <Card.Body>
                      <Form.Label>Maximální vzdálenost od dostupných míst</Form.Label>
                      <Form.Control type="input" onChange={(e:any) => setDistance(e.target.value)} />
                      { distanceMux == 1000 && <Form.Text className="text-muted">Vzdálenost v kilometrech od vybraných lokací</Form.Text> }
                      { distanceMux == 1 && <Form.Text className="text-muted">Vzdálenost v metrech od vybraných lokací</Form.Text> }

                      <Form.Group controlId="destanceMeterDetails" style={customStyle.groupStyle}>
                        <Form.Check type="radio" name="formHorizontalRadios" defaultChecked={distanceMux == 1000} label="V kilometrech" onClick={(e:any) => setDistanceMux(1000)} />
                      </Form.Group>
                      
                      <Form.Group controlId="destanceKmDetails" style={customStyle.groupStyle}>
                        <Form.Check type="radio" name="formHorizontalRadios" defaultChecked={distanceMux == 1} label="V metrech" onClick={(e:any) => setDistanceMux(1)} />
                      </Form.Group>
                    </Card.Body> }
                </Card>
                <Card bg='light' style={{ marginBottom: '.3rem' }}>
                  <Card.Header>
                    <Form.Group controlId="categoryHeader" style={customStyle.groupStyle}>
                      <Form.Check type="checkbox" name='categoryFilter' onClick={onFilterChange} label="Podle kategorie" />
                    </Form.Group>
                  </Card.Header>
                  {categoryFilter && 
                    <Card.Body>
                      <DropdownTreeSelect texts={{ placeholder: 'Vyber' }} data={visualCategories} onChange={onChangeCategory}/>
                    </Card.Body> }
                </Card>
                <Card bg='light' style={{ marginBottom: '.3rem' }}>
                  <Card.Header>
                    <Form.Group controlId="freeOnlyX" style={customStyle.groupStyle}>
                      <Form.Check type='checkbox' name='freeOnlyXX'  onClick={(e:any) => setFreeOnly(e.target.checked)} label='Pouze volné'/>
                    </Form.Group>
                  </Card.Header>
                </Card>
                <Button style={customStyle.buttonFullWidthStyle} onClick={findItem} >Hledej</Button>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={9}>
            <BootstrapTable
              bootstrap4
              hover
              striped={false}
              keyField="_id"
              columns={columns}
              data= {activeItems}
            />
          
            {(allItemsIds != undefined && allItemsIds.length > 0) &&
              <div style={{backgroundColor:'#90c390', width:'100%', paddingLeft:'0.2rem', paddingTop:'0.2rem', paddingBottom:'0' }}>
                <Pagination>
                  <Pagination.Prev disabled={!pagePrevAllowed} onClick={pageBack} />
                  <h3 style={{marginRight: '1rem', marginLeft: '1rem'}}>{itemsLegend}</h3>
                  <Pagination.Next disabled={!pageNextAllowed} onClick={pageNext} />
                </Pagination>
              </div> }
          </Col>
        </Row>
      </Container>

      <br/>
      <RenderedCounter/>
    </>
    );
}
