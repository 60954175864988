import cs from "date-fns/locale/cs";
import FlashMessage from "./FlashMessage";

const pageNamePrefix = (addMode:boolean) =>
{
  return addMode ? 'Registrace ' : 'Editace '
}

const pageNamePrefixFromMode = (mode:string) =>
{
  switch(mode) {
    case 'edit':
      return pageNamePrefix(false);

    case 'add':
      return pageNamePrefix(true);

    case 'delete':
      return 'Smazání ';

  } 

  return ''
}

const actionName = (addMode:boolean) =>
{
  return addMode ? 'Vlož' : 'Ulož'
}

const readableDateTime = (timastamp:any):string => {
  const result = new Date(timastamp).toLocaleString('cs', {
    timeZone: 'Europe/Prague', hour12:false})

  return result;
}

const back = "Zpět";
const addName = 'Vlož';
const domainName = "prohod.cz";

const populateFeedback = (feed: string|undefined):React.ReactNode =>  {
  if (feed == undefined || feed === "")
    return (<></>)

  return (
    <FlashMessage theme={'danger'}
        text={feed}/> )
}

export default { pageNamePrefix, actionName, readableDateTime, pageNamePrefixFromMode, back, domainName, addName, populateFeedback }