import React from 'react';

import { Button, Card, Container, ListGroup, ListGroupItem, Table} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import reports from "components/Helpers/Reports"
import log from "common/Log";
import { RenderedCounter } from 'common/Perf';
import customStyle from 'common/Style';

import { useCurrentUserState } from "models/CurrentUser";

import MidBid from 'middlewares/Bid';
import MidAsk from 'middlewares/Ask';

import itemTile from "components/Helpers/ItemTile";
import remoteAskTile from "components/Helpers/RemoteAskTile";
import genericTile from "components/Helpers/GenericTile";

export const ProposalOverview: React.FC<{}> = ({ children })=> {
  const pageName = 'Prohození';

  const currentUserState = useCurrentUserState()

  const [bids, setBids] = React.useState([] as any);
  const [asks, setAsks] = React.useState([] as any);
  const [asksPending, setAsksPending] = React.useState([] as any);
  const [asksHolderPending, setAsksHolderPending] = React.useState([] as any);

  const [feedback, setFeedback] = React.useState<string|undefined>(undefined);

  function errorService(functionId:string, errorArg:any) :void {
    setFeedback(log.decodeError(pageName, functionId, errorArg)); 
  }

  function okService() : void {
      setFeedback(""); 
  }

  React.useEffect(() => {
    
    MidBid.get_own({'id' :currentUserState.user._id}).then(
      (response:any) => {
        setBids(response.bids)
      },
      (error:any) => { errorService('transaction/get_class', error) }
    );
    MidAsk.get_own({'id' :currentUserState.user._id}).then(
      (response:any) => {
        setAsks(response.asks)
      },
      (error:any) => { errorService('transaction/get_class', error) }
    );
    MidAsk.get_pending_owner({'id' :currentUserState.user._id}).then(
      (response:any) => {
        setAsksPending(response.asks)
      },
      (error:any) => { errorService('transaction/get_class', error) }
    );
    MidAsk.get_pending_holder({'id' :currentUserState.user._id}).then(
      (response:any) => {
        setAsksHolderPending(response.asks)
      },
      (error:any) => { errorService('transaction/get_class', error) }
    );
  }, []);

  const removeBid = (id:any) => {
    MidBid.del({id:id, userId:currentUserState.user._id}).then(
      () => { window.location.reload(); },
      (error:any) => { errorService('bid/del', error) }
    );
  };
  const removeAsk = (id:any) => {
    MidAsk.del({id, userId:currentUserState.user._id}).then(
      () => { window.location.reload(); },
      (error:any) => { errorService('ask/del', error) }
    );
  };

   return (
    <Container fluid='xl'>
      <reports.ErrorTile feedback={feedback}/>

      <Card className="rootTile">
        <Card.Header>
          <h1>Poptávky ke schválení</h1>
        </Card.Header>
        <Card.Body>
          <div className="card-group" style={customStyle.panelWithTiles}>
            {asksPending.concat(asksHolderPending).map( (ask:any, index:number) => {
              return remoteAskTile.Tile(ask._id, ask.receiver, ask.item, ask.mortageItem);
              })
            }
          </div>
        </Card.Body>
      </Card>

      <Card className="rootTile">
        <Card.Header>
          <Row>
            <Col>
              <h1>Chci si půjčit</h1>
            </Col>
            <Col>
              <Link to="/item/borrow/find">
                <Button size="lg" variant="success" style={customStyle.rigthButtonStyle}>Nová poptávka</Button>
              </Link>              
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <div className="card-group" style={customStyle.panelWithTiles}>
            {asks.map( (i:any, index:number) => {
              return itemTile.Tile(i.item, undefined, 
                () => {
                  const refSure:string = "/proposal/ask/show/" + i._id;
                  window.location.assign(refSure);
                },
                undefined,
                (id:any) =>
                {
                  return genericTile.ButtonDeleteClick('Zruš poptávku', (e:any) => { e.stopPropagation(); removeAsk(i._id)} )
                })
              })
            }
          </div>
        </Card.Body>
      </Card>

      <Card className="rootTile">
        <Card.Header>
          <h1>Nabízím</h1>
          <Link to="/proposal/bid/create">
            <Button size="lg" variant="success" style={customStyle.rigthButtonStyle}>Nová nabídka</Button>
          </Link>
          </Card.Header>
        <Card.Body>       
          <div className="card-group" style={customStyle.panelWithTiles}>
            {bids.map( (i:any, index:number) => {
              return itemTile.Tile(i.item, undefined, 
                () => {
                  const refSure:string = "/proposal/bid/show/" + i.bid._id;
                  window.location.assign(refSure);
                },                
                undefined,
                (id:any) =>
                {
                  return genericTile.ButtonDeleteClick('Zruš nabídka', (e:any) => { e.stopPropagation(); removeBid(i.bid._id)} )
                })
            })
          }
          </div>
        </Card.Body>
      </Card>

      <RenderedCounter/>
    </Container>
  );
}
