import axios from "axios";
import log from "common/Log";

import * as server from "config/server.config";

const API_URL = server.SERVER_POINT + "/api/message/";

const get_many = (data:any) =>
{
  return axios.post(API_URL + "get_many", data).then((response) => {
    return response.data;
  });
};

const get = (data:any) =>
{
  return axios.post(API_URL + "get", data).then((response) => {
    return response.data;
  });
};

const add = (data:any) =>
{
  return axios.post(API_URL + "add", data).then((response) => {
    return response.data;
  });
};

const del = (data:any) =>
{
  return axios.post(API_URL + "del", data).then((response) => {
    return response.data;
  });
};

const get_statistics = (data:any) =>
{
  return axios.post(API_URL + "get_statistics", data).then((response) => {
    return response.data;
  });
};

export default {
  get_many, add, get, del, get_statistics
};
