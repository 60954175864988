
import React from 'react';

import { Button, Modal } from 'react-bootstrap'

import { Types } from "mongoose";

import locationTile from "components/Helpers/LocationTile";
import genericTile from "components/Helpers/GenericTile";
import customStyle from 'common/Style';
import location from 'components/Location';

const SubsetSelector: React.FC<{locations:any, selectedIds:Types.ObjectId[], locationsSelector:any, setModalLocation:any}> = ({ locations, selectedIds, locationsSelector, setModalLocation, children })=> {

  return (
    <>
      <div className="card-body">
        <div className="card-group" style={customStyle.panelWithTiles}>
          {locations.filter((loc:any) => {return !selectedIds.includes(loc._id)} ).map( (location:any) => {
              return locationTile.Tile(location, undefined, 
                () => {
                  locationsSelector(location._id);
                },
                (id:any) =>
                {
                  return (
                    <>
                      {genericTile.ButtonSourceClick('Detaily', (e:any) => {
                          e.stopPropagation();
                          setModalLocation(location._id)
                        } )}
                      {location.isHome != undefined && locationTile.ButtonHome() }
                    </>)
                })
            })
          }
        </div>
      </div>
    </>
  );
}


const SubsetSelectorModal: React.FC<{locations:any, selectedIds:Types.ObjectId[], locationsSelector:any, isReadOnly:boolean}> = ({ locations, selectedIds, locationsSelector, isReadOnly, children })=> {
  const [showHide, setShowHide] = React.useState(false);
  const [modalLocation, setModalLocation] = React.useState<any|undefined>();

  const locationSelectLocal = (ids:any) => {
    locationsSelector(ids)
    setShowHide(false);
  }

  return (
    <>
      {modalLocation != undefined &&
        <Modal dialogClassName="item-find" show={true} >
          <Modal.Body>
            <location.Location locationId={modalLocation} isReadOnly={true} showModal={true}/>
          </Modal.Body>
          <Modal.Footer>
            <Button size="lg" variant="secondary" style={customStyle.buttonSecondStyle} onClick={(e:any)=> {
                setModalLocation(undefined)
                }}>
                  Zpět
            </Button>
          </Modal.Footer>
        </Modal> }

      {!isReadOnly && 
        <Button size="lg" variant="success" style={customStyle.rigthButtonStyle} onClick={(e:any)=> { setShowHide(true) }}>Vybrat místo vyzvednutí</Button> }
      <Modal show={showHide} size="lg">
        <Modal.Header>
          <Modal.Title>Výběr z následujících míst</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h2>Dostupné</h2>
          <SubsetSelector locations={locations} selectedIds={selectedIds} locationsSelector={locationSelectLocal} setModalLocation={setModalLocation}/>
        </Modal.Body>
        <Modal.Footer>
          <Button size="lg" variant="secondary" style={customStyle.rigthSecondButtonStyle} onClick={(e:any)=> {
            setShowHide(false)
            }}>
              Zpět
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default  { SubsetSelector, SubsetSelectorModal  }