import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import itemTile from "./ItemTile";
import Person from '@material-ui/icons/Person';
import AttachFile from '@material-ui/icons/AttachFile';
import log from "common/Log";

const ButtonUser = (requestor:any) =>
{
  const linkId:string = "/user/show/" + requestor._id

  const mainActionOnClick = (e:any) => {
    e.stopPropagation()
    window.location.assign(linkId);
  }

  return (
    <OverlayTrigger placement="top"
      overlay={
        <Tooltip id={linkId}>
          <strong>Půjčit pro: {requestor.name}</strong>
        </Tooltip>
      }
    >
      <Person style={{float: "right", color: "green"}} onClick={mainActionOnClick}></Person>
    </OverlayTrigger>
  )
}

const ButtonMortage = (item:any) =>
{
  const linkId:string = "/item/show/" + item._id

  const mainActionOnClick = (e:any) => {
    e.stopPropagation()
    window.location.assign(linkId);
  }

  return (
    <OverlayTrigger placement="top"
      overlay={
        <Tooltip id={linkId}>
          <strong>Záruka: {item.name}</strong>
        </Tooltip>
      }
    >
      <AttachFile style={{float: "right", color: "purple"}} onClick={mainActionOnClick}></AttachFile>
    </OverlayTrigger>
  )
}

const Tile = (askId:any|undefined, requestor:any, item:any, mortageItem:any) => {
  const refAsk:string = "/proposal/ask/show/" + askId

  return itemTile.Tile(item, undefined,
      (e:any) => {
        log.log("----------", refAsk)
        e.stopPropagation()
        window.location.assign(refAsk);
      },
      undefined,
      () => {
        return (
          <>
            {ButtonUser(requestor)}
            {mortageItem !== undefined && <>{ButtonMortage(mortageItem)}</> }
          </> ) 
      })
}

export default  { Tile }