import React from 'react';

import { Link } from 'react-router-dom';
import { Button, Card, Container} from 'react-bootstrap';

import log from "common/Log";
import { RenderedCounter } from 'common/Perf';
import customStyle from 'common/Style';

import { useCurrentUserState, useControl } from "models/CurrentUser";

import Mid from 'middlewares/Location';

import reports from "components/Helpers/Reports"
import locationTile from "components/Helpers/LocationTile";
import genericTile from "components/Helpers/GenericTile";

export const LocationOverview: React.FC<{}> = ({ children })=> {

  const pageName = 'Dostupná místa';

  const currentUserState = useCurrentUserState()
  const { updateLocations, updateHomeLocation } = useControl()

  const [locations, setLocations] = React.useState<any[]|undefined>(undefined);
  const [homeLocation, setHomeLocation] = React.useState<any[]|undefined>(undefined);

  const [feedback, setFeedback] = React.useState<string|undefined>(undefined);

  function errorService(functionId:string, errorArg:any) :void {
    setFeedback(log.decodeError(pageName, functionId, errorArg)); 
  }

  function okService() : void {
      setFeedback(""); 
  }

  const setHome = (id:any) => {
    Mid.set_home({homeLocationId:id, owner:currentUserState.user._id}).then(
      () => {
        setHomeLocation(id)
        updateHomeLocation(id)
        okService()
      },
      (error:any) => { errorService('location/set_home', error) }
    );
  };

  const detachLocation = (id:any) => {
    Mid.detach({id:currentUserState.user._id, idLocation:id}).then(
      (e:any) => {
        if (locations != undefined)
        {
          const updatedLocations = locations.filter((locationLoc:any) => {
            return locationLoc._id != id
          })

          updateLocations(updatedLocations)
          setLocations(updatedLocations)
          okService()
        }
      },
      (error:any) => { errorService('location/detach', error) }
    );
  };

  React.useEffect(() => {
    Mid.get_many({'ids':currentUserState.user.sharedLocations}).then(
      (response:any) => {
        setLocations(response.locations)
        setHomeLocation(currentUserState.user.homeLocation)
      },
      (error:any) => { errorService('location/get_self', error) }
    );
  }, []);

  if (locations == null)
  {
    return ( <reports.LoadingTile feedback={feedback} setFeedback={setFeedback}/> )
  }

  return (
    <Container>
      {(locations != undefined) &&
      <>
        <Card className="rootTile">
          <Card.Header><h1>Dostupná místa</h1>
            <Link to="/location/create" style={{float: 'right'}}>
              <Button size="lg" variant="secondary" style={customStyle.rigthSecondButtonStyle}>Založ místo</Button>
            </Link>
            <Link to="/location/attach" style={{float: 'right'}}>
              <Button size="lg" variant="success" style={customStyle.rigthButtonStyle}>Připoj místo</Button>
            </Link>
          </Card.Header>
          <Card.Body>
            <div className="card-group" style={customStyle.panelWithTiles}>
              {locations.map( (location:any) => {
                  return locationTile.Tile(location, undefined, undefined,
                    (id:any) =>
                    {
                      return (
                        <>
                          {location.owner == currentUserState.user._id && locationTile.ButtonEdit(id)}
                          {location.owner != currentUserState.user._id && genericTile.ButtonDetachClick('Odpoj', (e:any) => { detachLocation(location._id); e.stopPropagation(); } )}
                          {homeLocation != undefined && location._id == homeLocation && locationTile.ButtonHome() }
                          {(homeLocation == undefined || location._id != homeLocation) && locationTile.ButtonHomeSet((e:any) => { e.stopPropagation(); setHome(location._id)}) }
                        </>)
                    })
                })
              }
            </div>
          </Card.Body>
        </Card>
      </>
      }
      <RenderedCounter/>
    </Container>
    );
}
