import React from 'react';

import { Button, Card, Container } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom';

import log from "common/Log";
import customStyle from 'common/Style';

import Midd from "middlewares/Setting";

import reports from "components/Helpers/Reports"

export const Unsubscribe: React.FC = ({ children })=> {
  const pageName = 'Ukončení přeposílání zpráv'

  const { settingId } = useParams();

  const [feedback, setFeedback] = React.useState<string|undefined>(undefined);
  const navigate = useNavigate()

  const [resendMessages, setResendMessages] = React.useState(true);

  function errorService(functionId:string, errorArg:any) :void {
    setFeedback(log.decodeError(pageName, functionId, errorArg)); 
  }

  function okService() : void {
    setFeedback("");
  }

  React.useEffect(() => {
    Midd.update({'id': settingId, 'resendMessages': false}).then(
      (response:any) => {
        setResendMessages(response.setting.resendMessages)
      },
      (error:any) => { errorService('setting/get', error) })
    }, []);

  return (
    <Container>
      <reports.ErrorModal feedback={feedback} setFeedback={setFeedback} />

      <Card className="rootTile">
        <Card.Header><h2>{pageName}</h2></Card.Header>
        <Card.Body>
          Přeposílat zprávy na osobní email vypnuto.<br/>
          V nastavení můžetete přeposílání ovět zapnout.<br/>
        </Card.Body>
      </Card>
      <br/>

      <Button size="lg" variant="secondary" style={customStyle.rigthSecondButtonStyle} onClick={(e) => {navigate(-1);} } >Zpět</Button>
    </Container>
  );
}
