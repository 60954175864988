import axios from "axios";
import log from "common/Log";
import security from "common/Security";

import * as server from "config/server.config";

const API_URL = server.SERVER_POINT + "/api/delivery/";

const get = (data:any) =>
{
  return axios.post(API_URL + "get", data, { headers: security.authHeader()}).then((response) => {
    return response.data;
  });
};

const del = (data:any) =>
{
  return axios.post(API_URL + "del", data, { headers: security.authHeader()}).then((response) => {
    return response.data;
  });
};

const get_many = (data:any) =>
{
  return axios.post(API_URL + "get_many", data).then((response) => {
    return response.data;
  });
};

const submit_meets = (data:any) =>
{
  return axios.post(API_URL + "submit_meets", data, { headers: security.authHeader()}).then((response) => {
    return response.data;
  });
};

const accept_meet = (data:any) =>
{
  return axios.post(API_URL + "accept_meet", data, { headers: security.authHeader()}).then((response) => {
    return response.data;
  });
};

const enter_sender_key = (data:any) =>
{
  return axios.post(API_URL + "enter_sender_key", data, { headers: security.authHeader()}).then((response) => {
    return response.data;
  });
};

const get_meet = (data:any) =>
{
  return axios.post(API_URL + "get_meet", data).then((response) => {
    return response.data;
  });
};

export default {
  get, get_many, submit_meets, accept_meet, enter_sender_key, get_meet, del
};
