import React from 'react';

import { Card, Button, Modal, Container } from 'react-bootstrap'
import log from "common/Log";
import customStyle from 'common/Style';
import imageLib from "global/Image";
import Midd from "middlewares/Clowd";

import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

export const ManyImages: React.FC<{imagesParent:any|undefined, onRemoveImage:any, onAddImage:any, onError:any, userId:any, isReadOnly:boolean}> = ({ imagesParent, onRemoveImage, onAddImage, onError, userId, isReadOnly, children })=> {
    const [images, setImages] = React.useState(imagesParent);
    const [showHide, setShowHide] = React.useState(false);
    const [imageDetail, setImageDetail] = React.useState();

    const handleKeyPress = (target:any) => {
      if (target.key === 'Escape') {
        setShowHide(false)
      }
    };

    React.useEffect(() => {
      setImages(imagesParent)

      window.addEventListener('keydown', handleKeyPress)
    }, [imagesParent]);

    React.useEffect(() => {
    }, []);

    const onPickImage = (e: any) => {
      e.preventDefault();

      log.log("images.legth", images.length)
      if (images.length > 4)
      {
        onError('upload_image', "Dovolený počet připojených připojených obrázků: maximálně 5")
        return
      }

      let files;
      if (e.dataTransfer) {
        files = e.dataTransfer.files;
      } else if (e.target) {
        files = e.target.files;
      }
      Midd.upload_image(files[0], userId, 1).then(
        (response:any) => {
          if (response.error != undefined)
          {
            onError('upload_image', response.error)
          }
          else
          {
            onAddImage(response.imageName)
          }
        },
        (error:any) => { onError('upload_image', error) }
      );
    };
  
    return (
      <Container fluid>
        <div className="card-group" style={customStyle.panelWithTiles}>

          <Modal show={showHide} dialogClassName="large-image-modal-style">
            
            <Cropper
              style={{ height: '800px', width: '800px' }}
              src={imageDetail}
              dragMode="move"
              autoCrop={false}
              cropBoxMovable={false}
              />

            <Modal.Footer>
              <Button size="lg" variant="secondary" style={customStyle.rigthSecondButtonStyle} onClick={() => { setShowHide(!showHide)} }>
                  Zpět
              </Button>
            </Modal.Footer>
          </Modal>

          {!isReadOnly && 
            <div>
              <label htmlFor="file-input" className="modal-label">Nahraj foto</label>
              <input
                accept="image/*"
                id="file-input"
                onChange={onPickImage}
                style={{ display: 'none' }}
                type="file"
              />
            </div>}
          <br/>
          {images.map( (i:any, index:number) => {
            const image = imageLib.getUrlFromImageModel(i);
            const uniqueNameCardA = "ImageA" + i + index.toString();

            return (
              <a key={uniqueNameCardA} style={{ cursor: 'pointer', marginRight: '0.2rem' }} onClick={() => { setImageDetail(image); setShowHide(!showHide)}}>
                <Card style={{ width: '15rem' }}>
                  <Card.Img variant="top" style={{ objectFit: 'cover', width: '10rem'}} src={image} />
                  <Card.Body>
                  {!isReadOnly && <Button size="lg" variant="secondary" style={customStyle.buttonSecondStyle} onClick={(e:any) => { e.stopPropagation(); onRemoveImage(index)}}>Zruš</Button> }
                  </Card.Body>
                </Card>
              </a> )})
            }
          </div>
          {(!isReadOnly || images.length > 0) &&
            <hr/>
          }
        </Container>
    );
  }
