import React from 'react';

import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import BootstrapTable from 'react-bootstrap-table-next';  

import DeleteIcon from '@material-ui/icons/Delete';

import reports from "components/Helpers/Reports"
import log from "common/Log";
import texts from "common/Texts";
import { RenderedCounter } from 'common/Perf';
import customStyle from 'common/Style';

import { useCurrentUserState } from "models/CurrentUser";

import Mid from 'middlewares/Message';

export const MessageOverview: React.FC<{}> = ({ children })=> {
  const pageName = 'Zprávy';

  const currentUserState = useCurrentUserState()
  const navigate = useNavigate()

  const [messages, setMessages] = React.useState<any[]>([]);
  const [feedback, setFeedback] = React.useState<string|undefined>(undefined);
  const [selectedIds, setSelectedIds] = React.useState<any[]>([]);

  const noPointer = {cursor: 'pointer'};

  function errorService(functionId:string, errorArg:any) :void {
    setFeedback(log.decodeError(pageName, functionId, errorArg)); 
  }

  React.useEffect(() => {
    Mid.get_many({'id' :currentUserState.user._id}).then(
      (response:any) => {
        const messages = response.messages.map((message:any) => {
          var editTile = false;
          var solved = "";

          switch(message.messageType) { 
            case 'ASK_APPROVE_REQUEST': { 
              message.messageTypeReadable = 'Nabídka'
              editTile = true;
              break
            } 
            case 'ASK_OVERALL': { 
              message.messageTypeReadable = 'Výsledek nabídky'
              break; 
            } 

            case 'DELIVERY_PROPOSE_REQUEST': { 
              message.messageTypeReadable = 'Výzva k doručení'
              editTile = true;
              break; 
            } 
            case 'DELIVERY_REQUEST': { 
              message.messageTypeReadable = 'Žádost o doručení'
              editTile = true;
              break; 
            } 
            case 'DELIVERY_OVERALL': { 
              message.messageTypeReadable = 'Výsledek doručení'
              break; 
            } 
            case 'USER': { 
              message.messageTypeReadable = 'Od uživatele'
              break; 
            } 
            default: { 
              message.messageTypeReadable = ''
              break; 
            } 
          }

          message.createTimestampReadable = texts.readableDateTime(message.createTimestamp)
          message.actions = { 'editTile': editTile }
          return message
        })

        setMessages(messages);
      },
      (error:any) => { errorService('message/get_many', error) }
    );
  }, []);

  function onClick(e:any, column:any, columnIndex:any, row:any, rowIndex:any) {
    const ref = "/message/show/" + messages[rowIndex]._id;
    window.location.assign(ref);
  }

  const columns = [
    { dataField: 'createTimestampReadable', text: 'Čas odeslání', sort: true,
        onSort: (field:any, order:any) => { setSelectedIds([])},
        sortFunc: (valueA:any, valueB:any, order:any, dataField:any, rowA:any, rowB:any) => {
        if (order === 'asc')
          return (rowA.createTimestamp > rowB.createTimestamp) ? 1 : -1;
        
        return (rowB.createTimestamp > rowA.createTimestamp) ? 1 : -1;
        },
        events: { onClick }
      },
    { dataField: 'message', text: 'Zpráva', sort: true,
        onSort: (field:any, order:any) => { setSelectedIds([])},
        events: { onClick }
      },
    { dataField: 'messageTypeReadable', text: 'Typ', sort: true,
        onSort: (field:any, order:any) => { setSelectedIds([])},
        events: { onClick }
      },
  
  ]

  const selectRowFn = (row:any, isSelect:any, rowIndex:any, e:any) => {
    e.preventDefault();
    if (isSelect)
    {
      var selectedLoc = selectedIds
      if (selectedIds.find((elem:any) => elem == row._id) == undefined)
         setSelectedIds(selectedIds.concat(row._id))
    }
    else
    {
      setSelectedIds(selectedIds.filter((elem:any) => elem != row._id))
    }
  };

  const onSelectAllFn = (isSelect: boolean, rows: any[]) => {
    if (isSelect)
      setSelectedIds(rows.map((elem:any) => { return elem._id }))
    else
      setSelectedIds([])
  };

  const removeMessages = (e:any) => {
    log.log("removed", selectedIds)
    Mid.del({ids:selectedIds}).then(
      () => {
        window.location.reload();
      },
      (error:any) => { errorService('message/del', error) }
    );
  };

  return (
    <div style={{ padding: '0.5rem' }}>
      <reports.ErrorModal feedback={feedback} setFeedback={setFeedback} />

      <h1>{pageName}</h1>

      <Button size="lg" variant="secondary" style={{ marginBottom: '0.5rem', float: 'right' }} onClick={removeMessages} > Smaž vybrané <DeleteIcon style={noPointer} /></Button>
      <br/>
      <BootstrapTable
        striped
        bootstrap4
        hover 
        keyField="_id"
        columns={columns}
        data= {messages}
        selectRow={{
          mode: "checkbox",
          clickToSelect: false,
          selected: selectedIds,
          bgColor: "#99ccff",
          onSelect: selectRowFn,
          onSelectAll: onSelectAllFn,
        }}
      />

      <RenderedCounter/>

      <Button size="lg" variant="secondary" style={customStyle.rigthSecondButtonStyle} onClick={(e) => {navigate(-1);} } >Zpět</Button>
    </div>
    );
}
