import React from 'react';

import { Routes, Route, Navigate } from "react-router-dom";

import item from 'components/Item';
import { ItemOverview } from 'components/ItemOverview';
import { ItemFind } from 'components/ItemFind';
import { Login } from 'components/Login';

import { useCurrentUserState } from "models/CurrentUser";

export const ItemControl: React.FC<{}> = ({ children })=> {

  const user = useCurrentUserState()

   return (
    <>
      <Routes>
        <Route path="show/:itemId" element={<item.ItemRouted mode='ro' showModal={false}/>}/>

        {user.isLogged && <Route path="/">
          <Route path="/"  element={<ItemOverview/>}/>

          <Route path="create" element={<item.ItemRouted mode='add' showModal={false}/>}/>

          <Route path="edit/:itemId" element={<item.ItemRouted mode='edit' showModal={false}/>}/>

          <Route path="delete/:itemId" element={<item.ItemRouted mode='delete' showModal={false}/>}/>
          <Route path="borrow/find" element={<ItemFind/>}/>
        </Route> }

        <Route
          path="*"
          element={<Navigate replace to="/intro_jump" />}
        />
      </Routes>
    </>
  );
}
