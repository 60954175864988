import React from "react";
import AuthService from "middlewares/User";
import axios from "axios";
import log from "common/Log";
import security from "common/Security";

enum UserActionTypes {
    INITIAL = "initial",
    FETCHING = "fetching",
    SUCCESS = "success",
    UPDATE = "update",
    UPDATE_LOCATIONS = "update_locations",
    UPDATE_HOME_LOCATION = "update_home_location",
    ERROR = "error",
    QUEST_INITIALIZED = "quest_initialized"
  }

interface CurrentUserState {
  isLoading: boolean;
  isLogged: boolean;
  error: any;
  token: any;
  user: any;
  questUser: any;
}

type CurrentUserAction =
  | { type: UserActionTypes.INITIAL }
  | { type: UserActionTypes.FETCHING }
  | { type: UserActionTypes.UPDATE; payload: any }
  | { type: UserActionTypes.UPDATE_LOCATIONS; payload: any }
  | { type: UserActionTypes.UPDATE_HOME_LOCATION; payload: any }
  | { type: UserActionTypes.SUCCESS; payload: any }
  | { type: UserActionTypes.QUEST_INITIALIZED; payload: any }
  | { type: UserActionTypes.ERROR; error: Error };

const initialState: CurrentUserState = {
  isLoading: false,
  isLogged: false,
  error: undefined,
  token: undefined,
  user: undefined,
  questUser: undefined
};

const currentUserReducer = (state: CurrentUserState, action: CurrentUserAction) => {
  switch (action.type) {
    case UserActionTypes.INITIAL: {
        return { ...state, isLoading: false, error: undefined, isLogged:false, token:undefined, user: undefined };
    } 
    case UserActionTypes.FETCHING: {
      return { ...state, isLoading: true, isLogged:false, token:undefined, user: undefined };
    }
    case UserActionTypes.SUCCESS: {
      return { ...state, isLoading: false, token: action.payload.accessToken, user: action.payload.user, error:undefined, isLogged:true  };
    }
    case UserActionTypes.UPDATE: {
      return { ...state, user: action.payload.user };
    }
    case UserActionTypes.QUEST_INITIALIZED: {
      return { ...state, questUser: action.payload.user };
    }
    
    case UserActionTypes.UPDATE_LOCATIONS: {
      if (!state.isLogged)
        return { ...state, isLoading: false, error: 'Can not update location when not logged', token:undefined, user: undefined};

      return { ...state, user: {...state.user, sharedLocations: action.payload.locations} };
    }
    case UserActionTypes.UPDATE_HOME_LOCATION: {
      if (!state.isLogged)
        return { ...state, isLoading: false, error: 'Can not update location when not logged', token:undefined, user: undefined};

      return { ...state, user: {...state.user, homeLocation: action.payload.homeLocation} };
    }
    case UserActionTypes.ERROR: {
      return { ...state, isLoading: false, error: action.error, token:undefined, user: undefined};
    }
    default: {
      return state;
    }
  }
};

interface Control {
    login: (data:any) => void;
    logout: () => void;
    update: (data:any) => void;
    updateLocations: (data:any) => void;
    updateHomeLocation: (data:any) => void;
}

const ControlContext = React.createContext<Control | undefined>(undefined);

const localState = JSON.parse(localStorage.getItem("userLogin") || JSON.stringify(initialState));
const CurrentUserStateContext = React.createContext<CurrentUserState | undefined>(undefined);

export const CurrentUserProvider: React.FC<{}> = ({children}) => {
  const [state, dispatch] = React.useReducer(currentUserReducer, localState);

  function login(data:any) {
    dispatch({ type: UserActionTypes.FETCHING });
    
    AuthService.login(data).then(
          (response) => {
            // ok
            dispatch({ type: UserActionTypes.SUCCESS, payload: response });
            //navigate(`/message`);
          },
          (error:any) => {
              let issue =
                  (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                  error.message ||
                  error.toString();
          log.log("Login issue:", issue)
          dispatch({ type: UserActionTypes.ERROR, error:issue });
        }
      );
  }

  function update(data:any) {
    dispatch({ type: UserActionTypes.UPDATE, payload: { 'user' : data }});
  }

  function updateLocations(locations:any[]) {
    dispatch({ type: UserActionTypes.UPDATE_LOCATIONS, payload: { 'locations' : locations }});
  }

  function updateHomeLocation(locId:any) {
    dispatch({ type: UserActionTypes.UPDATE_HOME_LOCATION, payload: { 'homeLocation' : locId }});
  }

  function logout() {
    AuthService.logout()
    localStorage.setItem("userLogin", "");
    localStorage.setItem(security.tokenInLocalName(), "");
    dispatch({ type: UserActionTypes.INITIAL });
  }

  React.useEffect(() => {
    localStorage.setItem("userLogin", JSON.stringify(state));
    if (state.token != undefined)
    {
      localStorage.setItem(security.tokenInLocalName(), JSON.stringify(state.token));
    }
  }, [state]);

  React.useEffect(() => {
    axios.defaults.maxContentLength = 100 * 1024 * 1024; // 100 MB
    axios.defaults.maxBodyLength = 100 * 1024 * 1024; // 100 MB

    if (state.questUser == undefined)
    {
      AuthService.quest_user({}).then(
          (response) => {
            // ok
            dispatch({ type: UserActionTypes.QUEST_INITIALIZED, payload: response });
          },
          (error:any) => {
              let issue =
                  (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                  error.message ||
                  error.toString();
          log.log("Quest issue:", issue)
          dispatch({ type: UserActionTypes.ERROR, error:issue });
        }
      );
    }
  }, []);

  return (
    <ControlContext.Provider value={ {login, logout, update, updateLocations, updateHomeLocation} }>
      <CurrentUserStateContext.Provider value={state}>
          {children}
      </CurrentUserStateContext.Provider>
    </ControlContext.Provider>
  );
};

export const useCurrentUserState = () => {
  const userStateContext = React.useContext(CurrentUserStateContext);
  if (userStateContext === undefined) {
    throw new Error("useCurrentUserState must be used within a UserProvider");
  }
  return userStateContext as CurrentUserState;
};

export const useControl = () => {
  const controlContext = React.useContext(ControlContext);
  if (controlContext === undefined) {
    throw new Error("useLogin must be used within a UserProvider");
  }
  return controlContext as Control;
};
