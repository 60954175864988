import React from 'react';

import { Card, Button, Modal, Container, Image, Form } from 'react-bootstrap'

import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

import log from "common/Log";
import s3 from "common/s3";
import Midd from "middlewares/Clowd";
import customStyle from 'common/Style';

export const ProfileImage: React.FC<{imageParent:any|undefined, onUpdateParent:any, onError:any, userId:any, isReadOnly:boolean}> = ({ imageParent, userId, onError, isReadOnly, onUpdateParent, children })=> {

    // crop area
    const [crop, setCrop] = React.useState({ x: 0, y: 0 });
    const [zoom, setZoom] = React.useState(1);

    const [showHide, setShowHide] = React.useState(false);
    const [cropper, setCropper] = React.useState<any>();
    const [image, setImage] = React.useState(s3.getUrlFromImageName(imageParent));
    const [imageLocal, setImageLocal] = React.useState(s3.getUrlFromImageName(imageParent));

    const onPickImage = (e: any) => {
      e.preventDefault();
      let files;
      if (e.dataTransfer) {
        files = e.dataTransfer.files;
      } else if (e.target) {
        files = e.target.files;
      }

      const reader = new FileReader();
      reader.onload = () => {
        setImageLocal(reader.result as any);
      };
      reader.readAsDataURL(files[0]);
    };

    const onImagePickInvertVisible = () => {
      setShowHide(!showHide)
    };

    const downscaleDimension = (height:any, width:any, limit:any) => {
      if (height > width && height > limit) {
        return { height: limit, width: width * (limit / height) };
      } else if (width > height && width > limit) {
        return { height: height * (limit / width), width: limit };
      } else {
        return { height, width };
      }
    };

    const onImageModalSave = () => {
      onImagePickInvertVisible()
      if (typeof cropper !== "undefined") {

        cropper.getCroppedCanvas({fillColor: '#ecfcf3'}).toBlob((blob:any) => {
          const asFile = Midd.blob_to_file(blob, 'Profile.jpg')
          log.log("File storage common JPG reduced size:", asFile.size)

          Midd.upload_image(asFile, userId, 0).then(
            (response:any) => {
              if (response.error != undefined)
              {
                onError('upload_image', response.error)
              }
              else
              {
                log.log("Finished", asFile)
                onUpdateParent(response.imageName);
                setImageLocal(s3.getUrlFromImageName(response.imageName));
                setImage(s3.getUrlFromImageName(response.imageName));
              }
            },
            (error:any) => { onError('upload_image', error) }
          );
        }, 'image/jpeg', 0.9)
      }
    };

    return (
      <div>
        {!isReadOnly && 
        <Modal show={showHide}>
          <Modal.Header closeButton onClick={onImagePickInvertVisible}>
          <Modal.Title>Výběr/úprava obrázku do profilu</Modal.Title>
          </Modal.Header>
          <Modal.Body>

          <div className="d-flex justify-content-center">          
          <label htmlFor="profile-file-input" className="modal-label">Nahraj obrázek</label>
          <input 
            accept="image/*"
            id="profile-file-input"
            onChange={onPickImage}
            style={{ display: 'none', float: "right" }}
            type="file"
          />
          </div>
          <div className="d-flex justify-content-center">     
          <Cropper
            style={{ height: '15rem', width: '15rem', float: 'none' }}
            aspectRatio={1}
            preview=".img-preview"
            cropBoxResizable={false}
            guides={false}
            src={imageLocal}
            viewMode={3}
            minCropBoxHeight={200}
            minCropBoxWidth={200}
            dragMode="move"
            autoCropArea={1}
            cropBoxMovable={false}
            onInitialized={(instance) => {
              setCropper(instance);
            }}/>
          </div>

          </Modal.Body>

          <Modal.Footer>
            <Button variant="primary" onClick={onImageModalSave}>
                Ulož změny
            </Button>

            <Button variant="secondary" onClick={() => {
              onImagePickInvertVisible();} }>
                Zruš
            </Button>
          </Modal.Footer>
        </Modal>}

      <Card style={{ float: 'right', padding: '0.2rem'}}>
        {!isReadOnly && <Card.Title>
          <Button style={customStyle.buttonFullWidthStyle} onClick={onImagePickInvertVisible}>Vyber foto profilu</Button>
        </Card.Title>}
        <Image id="profileImage" src={image} style={{ height: '15rem', width: '15rem', float: "right"}} />
      </Card>

      </div>
    );
  }
