import axios from "axios";
import log from "common/Log";
import security from "common/Security";

import * as server from "config/server.config";

const API_URL = server.SERVER_POINT + "/api/user/";

const register = (data:any) =>
{
  return axios.post(API_URL + "signup", data);
};

const change_password = (data:any) =>
{
  return axios.post(API_URL + "change_password", data, { headers: security.authHeader()}).then((response) => {
    return response.data;
  });
};

const reset_password = (data:any) =>
{
  return axios.post(API_URL + "reset_password", data, { headers: security.authHeader()}).then((response) => {
    return response.data;
  });
};

const update = (data:any) =>
{
  return axios.post(API_URL + "update", data, { headers: security.authHeader()}).then((response) => {
    response.data.user.password = ""
    return response.data;
  });
  
};

const quest_user = (data:any) =>
{
  return axios.post(API_URL + "quest_user", data).then((response) => {
    return response.data;
  });
  
};

const activate = (data:any) =>
{
  return axios.post(API_URL + "activate", data).then((response) => {
    return response.data;
  }); 
};

const login = (data:any) => {
  return axios
    .post(API_URL + "signin", data)
    .then((response) => {
      if (response.data.accessToken) {
        log.log("Token provided for user=", response.data.name)
      }

      return response.data;
    })
};

const logout = () =>
{
  log.logN("Logout")
};

const get_remote = (data:any) => {
  return axios
    .post(API_URL + "get_remote", data)
    .then((response) => {
      return response.data;
    });
};

export default {
  register,
  login,
  logout,
  update,
  reset_password,
  change_password,
  activate,
  get_remote,
  quest_user
};
