import React from 'react';


import { useNavigate} from 'react-router-dom';
import { Button, Container, Image, Form, Modal } from 'react-bootstrap'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { useMapEvents, MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { LatLng } from 'leaflet'

import FlashMessage from "common/FlashMessage";
import log from "common/Log";
import customStyle from 'common/Style';
import { initialPosition, geoOptions} from "common/Position";
import image from "global/Image";

import { useCurrentUserState, useControl } from "models/CurrentUser";
import location from 'components/Location';
import reports from "components/Helpers/Reports"

import Midd from "middlewares/Location";

export const LocationAttach: React.FC<{}> = ({ children })=> {
  const pageName = 'Připojení místa';

  const currentUserState = useCurrentUserState()
  const { updateLocations } = useControl()

  const [feedback, setFeedback] = React.useState<string|undefined>(undefined);
  const navigate = useNavigate();

  const [position, setPosition] = React.useState<LatLng>(new LatLng(initialPosition.latitude, initialPosition.longitude));
  const [locations, setLocations] = React.useState([] as any);
  const [selectedLocation, setSelectedLocation] = React.useState<any|undefined>(undefined);
  const [profileImageData, setProfileImageData] = React.useState("") 
  const [modalLocation, setModalLocation] = React.useState<any|undefined>();

  function errorService(functionId:string, errorArg:any) :void {
    setFeedback(log.decodeError(pageName, functionId, errorArg)); 
  }

  function okService() : void {
      setFeedback(""); 
  }

  React.useEffect(() => {
    if (selectedLocation != undefined)
      setProfileImageData(image.getUrlFromImageModel(selectedLocation.profileImage));
  }, [selectedLocation]);

  React.useEffect(() => {
    initialize();

    window.addEventListener('keydown', handleKeyPress)

    console.log('Initial location:', position)
  }, []);

  const initialize = () => {
    if ("geolocation" in navigator) {
      log.logN("GEO  Available");
    } else {
      log.logN("GEO Not Available");
    }

    if (navigator != null)
      navigator.geolocation.getCurrentPosition(onGeoResponse, (error:any) => {errorService('geolocation.getCurrentPosition', error)}, geoOptions);
  }

  const onGeoResponse = (arg:any) => {
    log.log("Coordinates get:", arg);
    if (arg.hasOwnProperty('coords'))
    {
      log.logN("GEO RESPONSE");
      setPosition(arg.coords)
    }
  }

  const submitFn = (data:any): void => {
    Midd.attach({id:currentUserState.user._id, idLocation:selectedLocation._id}).then(
      (response:any)=> {
        okService(); 
        updateLocations(currentUserState.user.sharedLocations.concat(selectedLocation))
        navigate("/location");
      },
      (errorArg:any) => {errorService('location.relation.attach', errorArg)});
  }

  const updatePosition = (data: any) => {
    setPosition(data);
  };

  const mapGetLocationsOnEvent = (event:any) => {

    const bound = event.target.getBounds();
    mapGetLocations(bound)
  }

  const mapGetLocations = (bound:any) => {
    console.log('mapGetLocations called:', bound)


    Midd.get_in_range({'southWest' :bound.getSouthWest(), 'northEast':bound.getNorthEast()}).then(
      (response) => {
        var result = response.locations.map((location:any) => {
          if (currentUserState.user.sharedLocations.find((obj:any) => {return obj == location._id} ) != undefined)
          {
            location["issue"] = "Toto místo je již připojeno, nebo je vlastní"
            console.log('issue:', location)
          }
          return location
        })

        setLocations(result)
      },
      (error:any) => { errorService('location.relation/get_in_range', error) });
  }

  const selectLocation = (idx:number) => {
    log.log("select location:", idx)
    setSelectedLocation(locations[idx])
  }

  function MapObserver() {
    const map = useMapEvents({
      moveend(e:any) { mapGetLocationsOnEvent(e); },
    })  

    return null
  }

  const boxStyle = {
    border: '.2rem solid #ececec',
    borderRadius: '8px',
    padding: '1rem',
    borderTopWidth: '0.2rem',
    borderRightWidth: '0.2rem',
    borderBottomWidth: '0.2rem',
    borderLeftWidth: '0.2rem'
  };

  const handleKeyPress = (target:any) => {
    if (target.key === 'Escape') {
        setModalLocation(undefined)
    }
  };

  return (
    <Container>

      <reports.ErrorModal feedback={feedback} setFeedback={setFeedback} />

      <h1>{pageName}</h1>
      <hr/>

      {modalLocation != undefined &&
        <Modal dialogClassName="item-find" show={true} >
          <Modal.Body>
            <location.Location locationId={modalLocation} isReadOnly={true} showModal={true}/>
          </Modal.Body>
          <Modal.Footer>
            <Button size="lg" variant="secondary" style={customStyle.buttonSecondStyle} onClick={(e:any)=> {
                setModalLocation(undefined)
                }}>
                  Zpět
            </Button>
          </Modal.Footer>
        </Modal> }

      <div style={boxStyle}>
        <Container>
          <Row>
            <Col>
              {selectedLocation != undefined &&
              <div>
                <Image id="profileImage" src={profileImageData} style={{ height: 150, width: 150, float: "right"}} />

                <Form.Group controlId="latitude">
                  <Form.Label>Jméno místa</Form.Label><br/>
                  <input readOnly value={selectedLocation.name}  />
                </Form.Group>
                <Form.Group controlId="latitude">
                  <Form.Label>Latitude</Form.Label><br/>
                  <input readOnly value={selectedLocation.geoLatitude} style={{ color: 'green' }} />
                </Form.Group>
                <Form.Group controlId="longitude">
                  <Form.Label>Longitude</Form.Label><br/>
                  <input readOnly value={selectedLocation.geoLongitude} style={{ color: 'green' }} />
                </Form.Group>

                <Form.Group controlId="latitude">
                    <Form.Label>Popis místa</Form.Label><br/>
                    <textarea readOnly value={selectedLocation.description} style={{ width:'100%' }} rows={8} />
                </Form.Group>
                <Button size="lg" variant="success" style={customStyle.rigthButtonStyle} onClick={(e:any)=> { setModalLocation(selectedLocation._id) }}>Detaily</Button>
              </div>
              }
            </Col>
            <Col>
              <MapContainer center={position} id="mapId" zoom={10} style={{ height: 600 }} whenCreated={(map) => {
                  mapGetLocations(map.getBounds())
                }}
              >
                <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />

                <MapObserver/>
                  {
                    locations.map( (i:any, index:number) => {
                      log.log("Location:", i)
                      const imageBody = image.getUrlFromImageModel(i.profileImage)
                      var latlng = new LatLng(i.geoLatitude, i.geoLongitude);
                      return (
                        <Marker key={i._id} position={latlng} eventHandlers={{
                            click: (e) => {
                              console.log('marker clicked', e)
                              selectLocation(index)
                            },
                          }}>
                          <Popup minWidth={90}>
                            {i.name}
                          </Popup>
                        </Marker>)
                    })
                  }
              </MapContainer> 
              <span className='txt' style={{color:'green'}}>Existující místa jsou na mapě označena ikonou.</span>
            </Col>
          </Row>
        </Container>
      </div>

      {selectedLocation != undefined && selectedLocation.issue != undefined && 
        <div>
          <FlashMessage theme='danger' text={selectedLocation.issue}/>
        </div>}
      <br/>
      <Button size="lg" variant="secondary" style={customStyle.rigthSecondButtonStyle} onClick={(e) => {navigate(-1);} } >Zpět</Button>
      <Button size="lg" variant="success" style={customStyle.rigthButtonStyle} disabled={selectedLocation === undefined || selectedLocation.issue != undefined} onClick={submitFn}>Připoj</Button>
    </Container>
  );
}
