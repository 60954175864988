import axios from "axios";
import security from "common/Security";
import log from "common/Log";

import * as server from "config/server.config";

const API_URL = server.SERVER_POINT + "/api/bid/";

const add = (data:any) =>
{
  return axios.post(API_URL + "add", data, { headers: security.authHeader() });
};

const get = (data:any) => {
  return axios
    .post(API_URL + "get", data)
    .then((response) => {
      return response.data;
    });
};

const get_own = (data:any) => {
  return axios
    .post(API_URL + "get_own", data)
    .then((response) => {
      return response.data;
    });
};

const del = (data:any) => {
  return axios.post(API_URL + "del", data, { headers: security.authHeader() })
};

export default {
  add,
  get,
  get_own,
  del
};
